import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user/user.service';
import { getMilliseconds } from '../utils/common';

@Injectable()
export class InactivityInterceptor implements HttpInterceptor {
	private lastRequestTime = Date.now();
	private inactivityTimeoutMillis: number = getMilliseconds({ hours: 4 });
	private isUserAuthenticated = false;

	constructor(
		private authService: AuthService,
		userService: UserService,
	) {
		userService.isLoggedIn$.subscribe((isUserAuthenticated) => {
			this.isUserAuthenticated = isUserAuthenticated;
		});
	}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.isUserAuthenticated) {
			return next.handle(req);
		}

		const currentTimeInMillis = Date.now();
		const timeElapsedSinceLastRequest = currentTimeInMillis - (this.lastRequestTime || 0);

		if (timeElapsedSinceLastRequest >= this.inactivityTimeoutMillis) {
			this.authService.logout({ forceLogout: true });
			return EMPTY;
		}

		this.lastRequestTime = currentTimeInMillis;

		return next.handle(req);
	}
}
