<div class="no-available-customers-container" *ngIf="viewData$ | async as viewData">
	<cramo-language-button />

	<img src="/assets/images/cramo-logo.svg" alt="Cramo" width="200" height="34" class="cramo-logo" />

	<h2>{{ viewData.translations?.header }}</h2>

	<p>{{ viewData.translations?.description }}</p>

	<p *ngIf="viewData.user.CustomerInfo?.MarketId">{{ viewData.translations?.defaultCustomerDescription }}</p>

	<ng-container *ngIf="!viewData.user.CustomerInfo?.MarketId && marketOptions$ | async as marketOptions">
		<p>{{ viewData.translations.selectMarketDescription }}</p>

		<div class="market-selector">
			<button
				mat-stroked-button
				style="height: 55px"
				*ngFor="let marketOption of marketOptions"
				(click)="goToMarket(marketOption.id)"
			>
				<div class="market-option">
					<img class="market-flag" [src]="marketOption.flagImageUrl" alt="" />
					<div>{{ marketOption.label }}</div>
				</div>
			</button>
		</div>
	</ng-container>

	<div class="buttons">
		<cramo-button text="{{ viewData.translations?.signOutText }}" (onclick)="logout()" />
		<cramo-button
			*ngIf="viewData.user.CustomerInfo?.MarketId"
			[text]="viewData.translations?.switchMarketButtonText"
			(onclick)="goToMarket(viewData.user.CustomerInfo?.MarketId)"
			buttonColor="primary"
		/>
	</div>
</div>
