import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { EpiService } from './epi/epi.service';

@Injectable({ providedIn: 'root' })
export class LoggingService {
	private applicationInsights: ApplicationInsights;

	constructor(private epiService: EpiService) {}

	public init(): void {
		const appInsightInstrumentationKey = this.epiService.configurationData.applicationInsightsInstrumentationKey;
		if (appInsightInstrumentationKey !== undefined) {
			this.applicationInsights = new ApplicationInsights({
				config: {
					instrumentationKey: appInsightInstrumentationKey,
					enableAutoRouteTracking: true, // option to log all route changes
				},
			});
			this.applicationInsights.loadAppInsights();
		}
	}

	public log = {
		event: (name: string, properties?: Record<string, string>) => {
			this.applicationInsights.trackEvent({ name }, properties);
		},
		metric: (name: string, average: number, properties?: Record<string, string>) => {
			this.applicationInsights.trackMetric({ name, average }, properties);
		},
		exception: (exception: Error, severityLevel?: number) => {
			this.applicationInsights.trackException({ exception, severityLevel });
		},
		trace: (message: string, properties?: Record<string, string>) => {
			this.applicationInsights.trackTrace({ message }, properties);
		},
	};
}
