import { AfterViewInit, Component, OnInit, Signal, ViewChild, computed } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
	FormControl,
	FormGroup,
	FormsModule,
	NonNullableFormBuilder,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router, RouterModule } from '@angular/router';
import { filter, finalize, switchMap } from 'rxjs';

import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ButtonComponent } from '../../components/button/button.component';
import { CheckboxComponent } from '../../components/checkbox/checkbox.component';
import { InputComponent } from '../../components/input/input.component';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { PageTitleComponent } from '../../components/page-title/page-title.component';
import { PageComponent } from '../../components/page/page.component';
import { AuthService } from '../../services/auth.service';
import { CompanyIdValidationService } from '../../services/company-id-validation.service';
import { ConfigurationService } from '../../services/configuration.service';
import { DigitalAccountApplicationService } from '../../services/digital-account-application.service';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { VerificationResult } from '../../types/auth.types';
import { FeatureToggles } from '../../types/configuration.types';
import { AppData } from '../../types/translations.types';
import { InternationalPhoneRegExp, MailRegExp } from '../../utils/constants';
import { glnOrIbanValidator } from '../../utils/validate-iban';
import { noWhitespaceValidator } from '../../utils/validation/whitespace-validator';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { CustomerFormComponent } from './components/customer-form/customer-form.component';
import { DirectionFormComponent } from './components/direction-form/direction-form.component';
import { PaymentMethodFormComponent } from './components/payment-method-form/payment-method-form.component';
import { SignatoryFormComponent } from './components/signatory-form/signatory-form.component';
import { SummaryFormComponent } from './components/summary-form/summary-form.component';
import { DigitalAccountApplicationForm, FormPart } from './types/digital-account-application.types';

@Component({
	templateUrl: './digital-account-application-page.component.html',
	styleUrls: ['./digital-account-application-page.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		PageComponent,
		ButtonComponent,
		FormsModule,
		ReactiveFormsModule,
		MatStepperModule,
		MatIconModule,
		CustomerFormComponent,
		ContactFormComponent,
		DirectionFormComponent,
		InputComponent,
		CheckboxComponent,
		PaymentMethodFormComponent,
		SignatoryFormComponent,
		SummaryFormComponent,
		RouterModule,
		PageTitleComponent,
		MessageBoxComponent,
	],
})
export class DigitalAccountApplicationPageComponent implements OnInit, AfterViewInit {
	@ViewChild(MatStepper) public stepper: MatStepper;

	public verificationResult: Signal<VerificationResult> | null = null;
	public form: FormGroup<DigitalAccountApplicationForm>;
	public applyForLoginLink: string;
	public appData: Signal<AppData>;
	public companyIsLoading: Signal<boolean>;
	public submitIsLoading = false;
	public hasSignatureCombinations: Signal<boolean>;
	public isCompanyLegalStatusValid: Signal<boolean>;
	public featureToggles: Signal<FeatureToggles>;

	constructor(
		private epiService: EpiService,
		private authService: AuthService,
		private formBuilder: NonNullableFormBuilder,
		private marketService: MarketService,
		private router: Router,
		private companyIdValidationService: CompanyIdValidationService,
		private digitalAccountApplicationService: DigitalAccountApplicationService,
		private configurationService: ConfigurationService,
	) {
		this.appData = toSignal(this.epiService.appData$);
		this.applyForLoginLink = this.configurationService.configurations.applyForLoginLinkUrl;
		this.verificationResult = toSignal(this.authService.verificationResult$);
		this.companyIsLoading = this.digitalAccountApplicationService.companyIsLoading;
		this.featureToggles = toSignal(this.configurationService.featureToggles$);

		this.form = this.formBuilder.group({
			currentStep: new FormControl(0),
			type: ['', [Validators.required]],
			priceAgreement: [false],
			salesContractContact: ['', [Validators.required]],
			payment: this.formBuilder.group({
				method: [this.featureToggles().digitalAccount.isCardPaymentEnabled ? null : 'Invoice', [Validators.required]],
				selectedInvoiceDistribution: [null, [Validators.required]],
				invoiceAddress: this.formBuilder.group({
					streetAddress: ['', [Validators.required]],
					postalCode: ['', [Validators.required, Validators.maxLength(5)]],
					city: ['', [Validators.required]],
				}),
				invoiceEmail: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(MailRegExp)]],
				sameEmail: [true],
				// sameAddress: [true],
				eInvoiceAddress: ['', [Validators.required, glnOrIbanValidator()]],
				eInvoiceOperator: ['', [Validators.required, noWhitespaceValidator()]],
			}),
			contact: this.formBuilder.group({
				email: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(MailRegExp)]],
				phone: ['', [Validators.required, Validators.maxLength(35), Validators.pattern(InternationalPhoneRegExp)]],
			}),
			company: this.formBuilder.group({
				name: [''],
				id: ['', [Validators.required, this.companyIdValidationService.companyIdValidator()]],
				vatId: [''],
				shouldEnterAddressManually: [false],
				address: this.formBuilder.group({
					streetAddress: ['', [Validators.required]],
					postalCode: ['', [Validators.required, Validators.maxLength(5)]],
					city: ['', [Validators.required]],
				}),
				isCustomer: [false],
				direction: ['', [Validators.required]],
				membership: [''],
				hasSignatoryCombinations: [true],
				selectedSignatoryGroup: [null, [Validators.required]],
				signatories: this.formBuilder.array([]),
				legalStatus: ['unknown'],
			}),
			individual: this.formBuilder.group({
				givenName: [''],
				familyName: [''],
				id: [''],
				isCustomer: [false],
				address: this.formBuilder.group({
					streetAddress: ['', [Validators.required]],
					postalCode: ['', [Validators.required, Validators.maxLength(5)]],
					city: ['', [Validators.required]],
				}),
			}),
		}) as unknown as FormGroup<DigitalAccountApplicationForm>;

		this.form
			.get('payment.method')
			.valueChanges.pipe(takeUntilDestroyed())
			.subscribe((method) => {
				if (method === 'Card') {
					this.form.get('payment.selectedInvoiceDistribution').disable();
				} else {
					this.form.get('payment.selectedInvoiceDistribution').enable();
				}
			});

		this.form
			.get('type')
			.valueChanges.pipe(takeUntilDestroyed())
			.subscribe((selectedType) => {
				if (selectedType === 'Company') {
					this.form.get('company').enable();
					this.form.get('payment.eInvoiceAddress').enable();
					this.form.get('payment.eInvoiceOperator').enable();
					this.form.get('individual.address').disable();
				} else {
					this.form.get('company').disable();
					this.form.get('payment.eInvoiceAddress').disable();
					this.form.get('payment.eInvoiceOperator').disable();
					this.form.get('individual.address').enable();
				}
			});

		this.form
			.get('payment.selectedInvoiceDistribution')
			.valueChanges.pipe(takeUntilDestroyed())
			.subscribe((selectedInvoiceOption) => {
				if (selectedInvoiceOption === null) return;

				if (selectedInvoiceOption === 'Email') {
					this.form.get('payment.invoiceAddress').disable();
					this.form.get('payment.invoiceEmail').enable();
				} else {
					this.form.get('payment.invoiceAddress').enable();
					this.form.get('payment.invoiceEmail').disable();
				}
			});

		this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
			sessionStorage.setItem('formState', JSON.stringify(value));
		});

		if (this.featureToggles().digitalAccount.isMainDirectionStepEnabled) {
			this.form.get('company.direction').setValidators([Validators.required]);
		} else {
			this.form.get('company.direction').clearValidators();
		}

		const companyInfo = toSignal(
			this.form.get('company.id').valueChanges.pipe(
				filter(() => this.form.get('company.id').valid),
				switchMap((companyId) => this.digitalAccountApplicationService.companyLookup(companyId)),
			),
		);

		this.hasSignatureCombinations = computed(() => (companyInfo()?.SignatureCombinations ?? []).length > 0);
		this.isCompanyLegalStatusValid = computed(() =>
			this.digitalAccountApplicationService.isCompanyLegalStatusValid(companyInfo()?.LegalStatus),
		);
	}

	ngOnInit(): void {
		const savedFormState = sessionStorage.getItem('formState');
		if (savedFormState) {
			// TODO: here we must create company.signatories in the form before we can populate the data
			this.form.patchValue(JSON.parse(savedFormState));
		}

		if (this.verificationResult()) {
			this.form.patchValue({
				individual: {
					id: this.verificationResult().nationalId,
					givenName: this.verificationResult().givenName,
					familyName: this.verificationResult().familyName,
				},
			});
		}

		if (!this.featureToggles().digitalAccount.isCardPaymentEnabled) {
			this.form.patchValue({ payment: { method: 'Invoice' } });
		}
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			const currentStep = this.form.get('currentStep').value;
			// make sure saved step is not higher than available, this is possible since amount of steps vary
			if (currentStep <= this.stepper?.steps.length) {
				this.stepper.selectedIndex = this.form.get('currentStep').value;
			}
		});
	}

	public verify() {
		this.authService.verify();
	}

	public clearVerificationResult() {
		this.authService.clearVerificationResult();
		this.resetForm();
	}

	public proceed(formPart?: FormPart) {
		this.stepper.next();
		this.form.patchValue({ currentStep: this.stepper.selectedIndex });

		if (formPart === 'priceAgreement') {
			this.form.get('salesContractContact').markAsTouched();
			return;
		}
		if (formPart === 'payment') {
			if (!this.form.get('payment.method').value) {
				this.form.get('payment.method').markAsTouched();
				return;
			}
			if (!this.form.get('payment.selectedInvoiceDistribution').value) {
				this.form.get('payment.selectedInvoiceDistribution').markAsTouched();
				return;
			}
		}
		if (formPart === 'signatories') {
			this.form.get('company.selectedSignatoryGroup').markAsTouched();
		}
	}

	public resetForm() {
		this.stepper.selectedIndex = 0;
		sessionStorage.removeItem('formState');
		this.form.get('payment').reset();
		this.form.reset();
	}

	public getCustomerTypeIsValid(): boolean {
		if (!this.form.get('type').value) return false;

		if (this.form.get('type').value === 'Company') {
			return (
				this.form.get('company.id').valid &&
				!this.form.get('company.isCustomer').value &&
				this.form.get('company.name').value?.length > 0 &&
				this.digitalAccountApplicationService.companyError() === false
			);
		}
		if (this.form.get('type').value === 'Individual') {
			return this.form.get('individual').valid && !this.form.get('individual.isCustomer').value;
		}
	}

	public isCustomerStepCompleted(): boolean {
		if (this.companyIsLoading()) {
			return false;
		}
		if (!this.getCustomerTypeIsValid()) {
			return false;
		}
		if (this.form.get('type').value === 'Company') {
			if (!this.isCompanyLegalStatusValid()) {
				return false;
			}
			if (!this.form.get('company.address').valid) {
				return false;
			}
		}
		if (this.form.get('type').value === 'Individual') {
			if (!this.form.get('individual.address').valid) {
				return false;
			}
		}

		return true;
	}

	public getPriceAgreementIsValid(): boolean {
		if (this.form.get('currentStep').value < 3) {
			return false;
		}
		if (!this.form.get('priceAgreement').value) {
			return true;
		}
		if (!this.form.get('salesContractContact').value) {
			return false;
		}
		return true;
	}

	public getPaymentIsValid(): boolean {
		// payment method must be set (this is set by default in all coutries but FI)
		if (!this.form.get('payment.method').value) return false;
		// if card we need nothing more
		if (this.form.get('payment.method').value === 'Card') return true;
		// if invoice we need to know how to send the invoice
		if (
			this.form.get('payment.method').value === 'Invoice' &&
			!this.form.get('payment.selectedInvoiceDistribution').value
		) {
			return false;
		}
		// with electronic invoice an GLN/PEPPOL/IBAN is needed for FI, not NO
		if (
			this.form.get('payment.selectedInvoiceDistribution').value === 'EInvoice' &&
			this.featureToggles().digitalAccount.isEinvoicePaymentOptionEnabled
		) {
			return this.form.get('payment.eInvoiceOperator').valid;
		}
		// email should be validated if separetly added
		if (
			this.form.get('payment.selectedInvoiceDistribution').value === 'Email' &&
			!this.form.get('payment.sameEmail').value
		) {
			return this.form.get('payment.invoiceEmail').valid;
		}
		// Commented 2024-09-06 Cause of new requirements from Finland that rental does not have the ability to handle different invoice addresses
		// address should be validated if separetly added
		// if (
		// this.form.get('payment.selectedInvoiceDistribution').value === 'Paper' &&
		// !this.form.get('payment.sameAddress').value
		// ) {
		// 	return this.form.get('payment.invoiceAddress').valid;
		// }
		return true;
	}

	public submitForm() {
		this.submitIsLoading = true;
		this.digitalAccountApplicationService
			.submitApplication(this.form)
			.pipe(
				finalize(() => {
					this.submitIsLoading = false;
				}),
			)
			.subscribe({
				next: (data) => {
					this.resetForm();
					const invitesFlow = true;
					if (data?.linkToSign) {
						window.location.href = data.linkToSign;
					} else {
						this.router.navigateByUrl(`/${this.marketService.currentLanguage}/digital-account-application/success`, {
							state: {
								invitesFlow,
							},
						});
					}
				},
			});
	}

	public getcustomerFormHeaderDisabledText(): string {
		if (!this.isCompanyLegalStatusValid()) return this.appData().digitalAccount.companyStateIsInvalidInfoText;
		if (!this.getCustomerTypeIsValid()) return this.appData().digitalAccount.customerTypeIsInvalidInfoText;
		return '';
	}
}
