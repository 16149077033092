import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { DepotService } from '../services/depot/depot.service';

/**
 * Fetch all depots before proceeding
 */

@Injectable()
export class DepotGuard {
	constructor(private depotService: DepotService) {}

	canActivate(): Observable<boolean> {
		return this.depotService.depots$.pipe(
			map((data) => {
				if (data) {
					return true;
				} else {
					return false;
				}
			}),
		);
	}
}
