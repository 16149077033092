import { Location } from '@angular/common';
import { Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { CartOldService } from '../../services/cart/cart-old.service';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { AppData } from '../../types/translations.types';
import { DeliveryType } from '../../utils/constants';

@Component({
	selector: 'cramo-complete-page',
	templateUrl: './complete-page.component.html',
	styleUrls: ['./complete-page.component.scss'],
})
export class CompletePageComponent {
	public appData: Signal<AppData>;
	public deliveryType = DeliveryType;
	public hasTransportItem: boolean;
	public currentLanguage: string;
	constructor(
		public cartService: CartOldService,
		private epiService: EpiService,
		private marketService: MarketService,
		private location: Location,
	) {
		const locationState = this.location.getState() as { hasTransportItem?: boolean };

		this.appData = toSignal(this.epiService.appData$);
		this.currentLanguage = this.marketService.currentLanguage;
		this.hasTransportItem = locationState.hasTransportItem ?? false;
	}
}
