import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from '../../../../components/select/select.component';
import { DigitalAccountApplicationService } from '../../../../services/digital-account-application.service';
import { EpiService } from '../../../../services/epi/epi.service';
import { AppData } from '../../../../types/translations.types';
import {
	DigitalAccountApplicationForm,
	MainDirection,
	MainDirectionType,
} from '../../types/digital-account-application.types';

@Component({
	selector: 'cramo-direction-form',
	standalone: true,
	imports: [CommonModule, SelectComponent, ReactiveFormsModule],
	templateUrl: './direction-form.component.html',
	styleUrls: ['./direction-form.component.scss'],
})
export class DirectionFormComponent implements OnInit {
	@Input() public form: FormGroup<DigitalAccountApplicationForm>;

	public appData: Signal<AppData>;
	public mainDirectionOptions: { value: MainDirectionType; label: string }[] = [];

	constructor(
		private epiService: EpiService,
		private digitalAccountApplicationService: DigitalAccountApplicationService,
	) {
		this.appData = toSignal(this.epiService.appData$);
	}

	ngOnInit(): void {
		const mainDirections: MainDirection[] = this.digitalAccountApplicationService.getMainDirectionOptions();

		this.mainDirectionOptions = mainDirections.map((direction) => {
			return {
				value: direction.value,
				label: this.appData().accountApplication[direction.labelKey],
			};
		});
	}
}
