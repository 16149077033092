import { OverlayModule } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LiveChatWidgetModule } from '@livechat/widget-angular';
import { tap } from 'rxjs';
import { AppComponent } from './app.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CommonElementsModule } from './components/common-elements.module';
import { CreateJobsiteFormComponent } from './components/create-jobsite-form/create-jobsite-form.component';
import { ErrorPopupComponent } from './components/error-popup/error-popup.component';
import { FlipPanelComponent } from './components/flip-panel/flip-panel.component';
import { ForceLogoutPopupComponent } from './components/force-logout-popup/force-logout-popup.component';
import { MenuDesktopComponent } from './components/header/components/menu-desktop/menu-desktop.component';
import { MenuMobileComponent } from './components/header/components/menu-mobile/menu-mobile.component';
import { HeaderComponent } from './components/header/header.component';
import { IconComponent } from './components/icon/icon.component';
import { InputTelComponent } from './components/input-tel/input-tel.component';
import { InputComponent } from './components/input/input.component';
import { LanguageButtonComponent } from './components/language-button/language-button.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { PageComponent } from './components/page/page.component';
import { ProductElementsModule } from './components/product-elements.module';
import { SearchHeaderInputComponent } from './components/search-header-input/search-header-input.component';
import { SelectMarketComponent } from './components/select-market/select-market.component';
import { SelectComponent } from './components/select/select.component';
import { UserSideNavComponent } from './components/user-side-nav/user-side-nav.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { DepotGuard } from './guards/depot.guard';
import { LoggedOutAuthGuard } from './guards/logged-out-auth.guard';
import { RegistrationGuard } from './guards/registration.guard';
import { RoutePathGuard } from './guards/route-path.guard';
import { AuthInterceptor, ErrorInceptor, InactivityInterceptor, ReqFormatInterceptor } from './interceptors';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { AngularMaterialModule } from './modules/angular-material.module';
import { AppRoutingModule } from './modules/app-routing.module';
import { OCCPreloadingStrategy } from './occ-preloading-strategy';
import { AccountChangePasswordPageComponent } from './pages/account-change-password-page/account-change-password-page.component';
import { AccountChangePhonePageComponent } from './pages/account-change-phone-page/account-change-phone-page.component';
import { AccountNotFoundPageComponent } from './pages/account-not-found-page/account-not-found-page.component';
import { CompletePageComponent } from './pages/complete-page/complete-page.component';
import { ConfirmMarketChangePageComponent } from './pages/confirm-market-change-page/confirm-market-change-page.component';
import { CookieDeclarationPageComponent } from './pages/cookie-declaration-page/cookie-declaration-page.component';
import { DigitalAccountApplicationSuccessPageComponent } from './pages/digital-account-application-page/components/digital-account-application-success-page/digital-account-application-success-page.component';
import { DigitalAccountApplicationPageComponent } from './pages/digital-account-application-page/digital-account-application-page.component';
import { NoAvailableCustomersPageComponent } from './pages/no-available-customers-page/no-available-customers-page.component';
import { NotfoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { OnboardingPageComponent } from './pages/onboarding-page/onboarding-page.component';
import { TemporaryProductPageRedirectComponent } from './pages/product-page/components/temporary-product-page-redirect/temporary-product-page-redirect.component';
import { PunchoutPageComponent } from './pages/punchout-page/punchout-page.component';
import { SearchPageComponent } from './pages/search-page/search-page.component';
import { AvailabilityUtilsService } from './services/availability-utils.service';
import { ConfigurationService } from './services/configuration.service';
import { DepotService } from './services/depot/depot.service';
import { EpiUtilsService } from './services/epi-utils.service';
import { EpiService } from './services/epi/epi.service';
import { EventBusService } from './services/event-bus.service';
import { GtmService } from './services/gtm.service';
import { InvoiceService } from './services/invoice.service';
import { JobsiteService } from './services/jobsite/jobsite.service';
import { MarketService } from './services/market.service';
import { MetaService } from './services/meta.service';
import { NotificationService } from './services/notification.service';
import { OrderReturnUtilsService } from './services/order-return-utils.service';
import { OrderService } from './services/order.service';
import { PriceUtilsService } from './services/price-utils.service';
import { ProductUtilsService } from './services/product-utils.service';
import { ProductService } from './services/product.service';
import { PunchoutService } from './services/punchout.service';
import { RegistrationService } from './services/registration.service';
import { SearchService } from './services/search.service';
import { StatisticsService } from './services/statistics.service';
import { UserService } from './services/user/user.service';
import { ViewPickerService } from './services/view-picker.service';
import { Configurations } from './types/configuration.types';
import { DateFormatUtilsService } from './utils/date-format-utils.service';

function initializeConfig(
	epiService: EpiService,
	marketService: MarketService,
	httpClient: HttpClient,
	configurationService: ConfigurationService,
) {
	return () => {
		const marketId = marketService.getMarketFromUrl();
		return httpClient.get<Configurations>(`/webapi/${marketId}/configurations`).pipe(
			tap((data) => {
				// eslint-disable-next-line no-param-reassign
				epiService.configurationData = data;
				configurationService.initializeConfiguration(data);
			}),
		);
	};
}

function getLocaleId(marketService: MarketService) {
	return marketService.getLocaleId();
}

@NgModule({
	declarations: [
		AccountChangePasswordPageComponent,
		AccountChangePhonePageComponent,
		AccountNotFoundPageComponent,
		AppComponent,
		CompletePageComponent,
		ConfirmMarketChangePageComponent,
		CookieDeclarationPageComponent,
		ForceLogoutPopupComponent,
		NoAvailableCustomersPageComponent,
		NotfoundPageComponent,
		PunchoutPageComponent,
		SearchPageComponent,
		SelectMarketComponent,
		TemporaryProductPageRedirectComponent,
		UserSideNavComponent,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeConfig,
			deps: [EpiService, MarketService, HttpClient, ConfigurationService],
			multi: true,
		},
		{ provide: LOCALE_ID, deps: [MarketService], useFactory: getLocaleId },
		{ provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ReqFormatInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: InactivityInterceptor, multi: true },
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: { autoFocus: false }, // Globally disables autofocus for all dialogs
		},
		AvailabilityUtilsService,
		DateFormatUtilsService,
		DatePipe,
		DepotGuard,
		DepotService,
		EpiService,
		EpiUtilsService,
		EventBusService,
		GtmService,
		InvoiceService,
		JobsiteService,
		LoggedOutAuthGuard,
		MarketService,
		MetaService,
		NotificationService,
		OCCPreloadingStrategy,
		OrderReturnUtilsService,
		OrderService,
		PriceUtilsService,
		ProductService,
		ProductUtilsService,
		PunchoutService,
		RegistrationGuard,
		RegistrationService,
		RoutePathGuard,
		SearchService,
		StatisticsService,
		UserService,
		ViewPickerService,
	],
	bootstrap: [AppComponent],
	imports: [
		AppRoutingModule,
		AngularMaterialModule,
		AutocompleteComponent,
		BrowserAnimationsModule,
		BrowserModule,
		ButtonComponent,
		CheckboxComponent,
		CommonElementsModule,
		CreateJobsiteFormComponent,
		DigitalAccountApplicationPageComponent,
		DigitalAccountApplicationSuccessPageComponent,
		ErrorPopupComponent,
		FlipPanelComponent,
		FormsModule,
		HasPermissionDirective,
		HeaderComponent,
		HttpClientModule,
		IconComponent,
		InputComponent,
		InputTelComponent,
		LanguageButtonComponent,
		LiveChatWidgetModule,
		MenuDesktopComponent,
		MenuMobileComponent,
		MessageBoxComponent,
		OnboardingPageComponent,
		OverlayModule,
		PageComponent,
		ProductElementsModule,
		ReactiveFormsModule,
		ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }),
		SelectComponent,
		SearchHeaderInputComponent,
	],
})
export class AppModule {}
