import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { EpiService } from '../../services/epi/epi.service';
import { LoginTranslationData } from '../../types/translations.types';

@Component({
	selector: 'cramo-account-not-found-page',
	templateUrl: './account-not-found-page.component.html',
	styleUrls: ['./account-not-found-page.component.scss'],
})
export class AccountNotFoundPageComponent {
	public epiTexts: LoginTranslationData;
	public registrationLink: string;

	constructor(
		private authService: AuthService,
		private epiService: EpiService,
	) {
		this.epiTexts = this.epiService?.appData?.login;
		this.registrationLink = this.epiService.getRegistrationLinkUrl();
	}

	public async login() {
		this.authService.login();
	}
}
