import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnDestroy, Signal, computed } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CRAMO_CONFIRM_CONFIG } from '../../components/confirm-dialog/confirm-dialog.config';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { HasPermissionDirective } from '../../directives/has-permission.directive';
import { MaxNumberPipe } from '../../pipes/max-number.pipe';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { EpiService } from '../../services/epi/epi.service';
import { EventBusService } from '../../services/event-bus.service';
import { MarketService } from '../../services/market.service';
import { NotificationService } from '../../services/notification.service';
import { ProductService } from '../../services/product.service';
import { PunchoutService } from '../../services/punchout.service';
import { UserService } from '../../services/user/user.service';
import { FeatureToggles } from '../../types/configuration.types';
import { Customer, User } from '../../types/user.types';
import { ButtonComponent } from '../button/button.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { IconComponent } from '../icon/icon.component';
import { LoadingAreaComponent } from '../loading-area/loading-area.component';
import { UserSideNavigationMenuLocalizedLabels } from './localization.model';

@Component({
	selector: 'cramo-user-side-nav',
	templateUrl: './user-side-nav.component.html',
	styleUrls: ['./user-side-nav.component.scss'],
	animations: [
		trigger('menuVisibility', [
			transition(':enter', [
				style({ transform: 'translateX(100%)' }),
				animate('250ms ease-out', style({ transform: 'translateX(0%)' })),
			]),
			transition(':leave', [
				style({ transform: 'translateX(0%)' }),
				animate('250ms ease-out', style({ transform: 'translateX(100%)' })),
			]),
		]),
		trigger('slideSelectCustomer', [
			transition(':enter', [
				style({ height: '0px', opacity: 0 }),
				animate('300ms', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ transform: 'translateX(0%)' }),
				animate('300ms', style({ transform: 'translateX(100%)' })),
			]),
		]),
		trigger('slideMainMenu', [
			transition(':enter', [
				style({ height: '0px', opacity: 0 }),
				animate('300ms', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ transform: 'translateX(0%)' }),
				animate('300ms', style({ transform: 'translateX(-100%)' })),
			]),
		]),
	],
	standalone: true,
	imports: [
		HasPermissionDirective,
		ClickOutsideDirective,
		IconComponent,
		NgIf,
		RouterModule,
		MaxNumberPipe,
		ButtonComponent,
		NgClass,
		AsyncPipe,
		NgFor,
		LoadingAreaComponent,
	],
})
export class UserSideNavComponent implements OnDestroy {
	public featureToggles: Signal<FeatureToggles>;
	public savedProductsCount: Signal<number>;
	public showSavedProductsCount: Signal<boolean>;
	public notificationCount: Signal<number>;
	public currentLanguage: string;
	public isCustomerExpanded: Record<string, boolean>;
	public isOpened = false;
	public localizedLabels: UserSideNavigationMenuLocalizedLabels;
	public mainMenuState: boolean;
	public selectCustomerState: boolean;
	public selectedCustomerId: string | undefined;
	public settingCustomer = false;
	public showMenuFlag: boolean;
	public quantityLoading = true;
	public user: Signal<User | undefined>;
	public userCustomers$: Observable<Customer[]>;
	private onDestroy$ = new ReplaySubject(1);
	public smartControlUrl: string;
	protected isLoggedIn: Signal<boolean>;

	constructor(
		private eventBusService: EventBusService,
		private userService: UserService,
		private epiService: EpiService,
		private productService: ProductService,
		private notificationService: NotificationService,
		private marketService: MarketService,
		private authService: AuthService,
		private punchoutService: PunchoutService,
		private configurationService: ConfigurationService,
		public dialog: MatDialog,
	) {
		this.featureToggles = toSignal(this.configurationService.featureToggles$, { requireSync: true });
		this.smartControlUrl = this.configurationService.configurations.smartControlUrl;
		const notifications = toSignal(this.notificationService.notifications$);
		this.notificationCount = computed(() => notifications()?.length ?? 0);
		const savedProducts = toSignal(this.productService.savedProducts$);
		this.savedProductsCount = computed(() => savedProducts()?.length ?? 0);
		this.showSavedProductsCount = computed(() => this.savedProductsCount() !== 0);
		this.currentLanguage = this.marketService.currentLanguage;
		this.isCustomerExpanded = {};
		this.localizedLabels = new UserSideNavigationMenuLocalizedLabels(this.epiService.appData);
		this.setShowMenuFlag(true);
		this.selectedCustomerId = '';
		this.user = toSignal(this.userService.user$);
		this.isLoggedIn = toSignal(this.userService.isLoggedIn$, { initialValue: false });

		this.eventBusService.userSideNavToggleSubject.subscribe((sideBarState: boolean) => {
			this.isOpened = sideBarState;
		});

		this.userService.user$.pipe(takeUntilDestroyed()).subscribe((user: User) => {
			if (user) {
				this.isCustomerExpanded = {};
				this.selectedCustomerId = user.CustomerInfo?.CustomerId;
			}
		});
	}

	public closeMenu() {
		setTimeout(() => {
			this.setShowMenuFlag(true);
			this.isOpened = false;
		}, 10);
	}

	public invertCustomerDetailsVisibility(customerId: string) {
		if (this.isCustomerExpanded[customerId] !== undefined) {
			this.isCustomerExpanded[customerId] = !this.isCustomerExpanded[customerId];
		} else {
			this.isCustomerExpanded[customerId] = true;
		}
	}

	public logout() {
		this.punchoutService.isPunchout$.pipe(take(1)).subscribe((isPunchout) => {
			if (isPunchout) {
				this.punchoutService.logoutPunchout();
			}
		});
		this.isOpened = false;
		this.authService.logout();
	}

	private fetchCustomers() {
		this.userCustomers$ = this.userService.getSidebarUsersCustomers().pipe(map((customers) => customers.Customers));
	}

	public setCustomer(customer: Customer) {
		if (customer?.CustomerId && this.selectedCustomerId && customer.CustomerId !== this.selectedCustomerId) {
			this.settingCustomer = true;
			this.userService.setCustomerAndAccountForMarketChange(customer, (success) => {
				if (success) {
					this.currentLanguage = this.marketService.marketLanguageMap[customer.MarketId];
				}
				this.settingCustomer = false;
			});
		}
	}

	public showCustomers(e: Event) {
		e.stopPropagation();
		this.fetchCustomers();
		this.setShowMenuFlag(false);
	}

	public showMenu(e: Event) {
		e.stopPropagation();
		this.setShowMenuFlag(true);
	}

	public onCloseEnd() {
		if (this.isOpened === false) {
			this.eventBusService.userSideNavToggleSubject.next(false);
		}
	}

	public goToSmartControl() {
		if (this.user()?.IsLoggedinWithElectronicId) {
			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				...CRAMO_CONFIRM_CONFIG,
				disableClose: true,
				data: {
					header: this.localizedLabels.smartControlModalTitle,
					text: this.localizedLabels.smartControlModalMessage,
				},
			});

			dialogRef.afterClosed().subscribe((result) => {
				if (result === true) {
					this.authService.logoutAndRedirectToSmartControl({ forceLogout: true });
				}
			});
		} else {
			location.href = this.smartControlUrl;
		}
	}

	private setShowMenuFlag(value: boolean) {
		this.showMenuFlag = value;

		setTimeout(
			() => {
				this.selectCustomerState = !value;
			},
			value ? 10 : 250,
		);

		setTimeout(
			() => {
				this.mainMenuState = value;
			},
			value ? 250 : 10,
		);
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
