import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, catchError, of, shareReplay } from 'rxjs';
import { SKIP_ERROR_INTERCEPTOR_HEADER } from '../interceptors/error.interceptor';
import {
	CompanyInfo,
	DigitalAccountApplicationForm,
	MainDirection,
	PaymentMethodResponse,
	SubmitApplicationRequest,
	SubmitApplicationResponse,
} from '../pages/digital-account-application-page/types/digital-account-application.types';
import { MarketService } from './market.service';

type CompanyId = string;

@Injectable({
	providedIn: 'root',
})
export class DigitalAccountApplicationService {
	constructor(
		private httpClient: HttpClient,
		private marketService: MarketService,
	) {}

	public readonly companyIsLoading = signal(false);
	public readonly companyError = signal(false);
	private readonly companies = new Map<CompanyId, Observable<CompanyInfo>>();

	public companyLookup(companyId: string): Observable<CompanyInfo> {
		const sanitizedCompanyId = companyId.trim().replace(/[\D\s]/g, '');

		// return save data if exists
		if (this.companies.has(sanitizedCompanyId)) {
			return this.companies.get(sanitizedCompanyId);
		}
		const request = this.httpClient
			.post<CompanyInfo>(
				`/webapi/${this.marketService.currentMarket}/customer/customer-info`,
				{
					companyNumber: sanitizedCompanyId,
				},
				{
					headers: new HttpHeaders().set(SKIP_ERROR_INTERCEPTOR_HEADER, ''),
				},
			)
			.pipe(
				catchError(() => {
					this.companyError.set(true);
					this.companies.delete(sanitizedCompanyId);
					return of(null);
				}),
				shareReplay(1),
			);

		this.companies.set(sanitizedCompanyId, request);

		return request;
	}

	public getPaymentMethods(): Observable<PaymentMethodResponse> {
		return this.httpClient.get<PaymentMethodResponse>(
			`/webapi/${this.marketService.currentMarket}/${this.marketService.currentLanguage}/payment-methods`,
		);
	}

	public submitApplication(form: FormGroup<DigitalAccountApplicationForm>) {
		const application = this.formDataToSubmitApplicationRequest(form);
		return this.httpClient.post<SubmitApplicationResponse>(
			`/webapi/${this.marketService.currentMarket}/${this.marketService.currentLanguage}/digital-account-application`,
			application,
		);
	}

	public individualLookup(identifier: string): Observable<boolean> {
		const headers = new HttpHeaders().set(SKIP_ERROR_INTERCEPTOR_HEADER, '');
		const cleanedIdentifier = identifier.replace(/[^a-zA-Z0-9]/g, '');

		return this.httpClient
			.post<boolean>(
				`/webapi/${this.marketService.currentMarket}/customer/is-already-customer`,
				{
					socialSecurityNumber: cleanedIdentifier,
				},
				{
					headers,
				},
			)
			.pipe(
				catchError(() => {
					// return nothing as it is non-critical.
					return of(null);
				}),
			);
	}

	public getAddressAsString = (address: {
		streetAddress: string;
		streetNumber?: string | null;
		postalCode: string;
		city: string;
	}): string => {
		return `${address.streetAddress}${address.streetNumber ?? ''}, ${address.postalCode}, ${address.city}`;
	};

	public isCompanyLegalStatusValid(legalStatus: CompanyInfo['LegalStatus']): boolean {
		if (!legalStatus) return true;
		return legalStatus === 'active' || legalStatus === 'unknown';
	}

	public getMainDirectionOptions(): MainDirection[] {
		return [
			{ value: 'NHQC4', labelKey: 'newBuildingsLabel' },
			{ value: 'NHQC7', labelKey: 'rotLabel' },
			{ value: 'NHQC5', labelKey: 'installationLabel' },
			{ value: 'NHQC1', labelKey: 'constructionLabel' },
			{ value: 'NHQO9', labelKey: 'otherLabel' },
		];
	}

	public getMainDirectionTranslatedLabelKey(mainDirection2: string): MainDirection['labelKey'] {
		const mainDirections = this.getMainDirectionOptions();
		const matchedMainDirection = mainDirections.find((mainDirection) => mainDirection.value === mainDirection2);
		return matchedMainDirection?.labelKey;
	}

	private formDataToSubmitApplicationRequest(form: FormGroup<DigitalAccountApplicationForm>): SubmitApplicationRequest {
		const { individual, company, payment, contact, type, priceAgreement, salesContractContact } = form.getRawValue();

		const contactAddress =
			type === 'Individual'
				? {
						streetAddress: individual.address.streetAddress,
						postalCode: individual.address.postalCode,
						city: individual.address.city,
					}
				: {
						streetAddress: company.address.streetAddress,
						postalCode: company.address.postalCode,
						city: company.address.city,
					};
		return {
			applicant: {
				givenName: individual.givenName,
				familyName: individual.familyName,
				personalNumber: individual.id,
				contact: {
					email: contact.email,
					phone: contact.phone,
				},
				address: type === 'Individual' ? contactAddress : null,
			},
			type: type,
			priceAgreement: priceAgreement,
			salesContractContact: salesContractContact,
			payment: {
				method: payment.method,
				invoiceOption: payment.selectedInvoiceDistribution,
				invoiceAddress: contactAddress,
				// Commented 2024-09-06 Cause of new requirements from Finland that rental does not have the ability to handle different invoice addresses
				// invoiceAddress: payment.sameAddress
				// ? contactAddress
				// : {
				// 		streetAddress: payment.invoiceAddress.streetAddress,
				// 		postalCode: payment.invoiceAddress.postalCode,
				// 		city: payment.invoiceAddress.city,
				// 	},
				invoiceEmail: payment.sameEmail ? contact.email : payment.invoiceEmail,
				eInvoiceAddress: type === 'Company' && payment.eInvoiceAddress ? payment.eInvoiceAddress : null,
				eInvoiceOperator: type === 'Company' && payment.eInvoiceOperator ? payment.eInvoiceOperator : null,
			},
			company:
				type === 'Company'
					? {
							name: company.name,
							companyNumber: company.id,
							address: contactAddress,
							companyVatNumber: company.vatId,
							mainDirection: company.direction ?? null,
							membershipAgreement: company.membership ?? null,
							signatories: company.signatories.map((signatory) => ({
								name: signatory.name,
								role: signatory.role,
								email: signatory.email,
								ssn: signatory.ssn,
							})),
						}
					: null,
		};
	}
}
