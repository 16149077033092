/**
 * PropertiesPlugin.ts
 * @copyright Microsoft 2018
 */
import { CoreUtils, hasWindow } from '@microsoft/applicationinsights-core-js';
import { Session, _SessionManager } from './Context/Session';
import { Extensions, CtxTagKeys } from '@microsoft/applicationinsights-common';
import { Application } from './Context/Application';
import { Device } from './Context/Device';
import { Internal } from './Context/Internal';
import { User } from './Context/User';
import { Location } from './Context/Location';
import { TelemetryTrace } from './Context/TelemetryTrace';
var TelemetryContext = /** @class */function () {
  function TelemetryContext(logger, defaultConfig) {
    if (hasWindow()) {
      this.sessionManager = new _SessionManager(defaultConfig, logger);
      this.application = new Application();
      this.device = new Device();
      this.internal = new Internal(defaultConfig);
      this.location = new Location();
      this.user = new User(defaultConfig, logger);
      this.telemetryTrace = new TelemetryTrace();
      this.session = new Session();
    }
    this.appId = function () {
      return null;
    };
  }
  TelemetryContext.prototype.applySessionContext = function (event, itemCtx) {
    var sessionContext = this.session || this.sessionManager && this.sessionManager.automaticSession;
    if (sessionContext) {
      if (typeof sessionContext.id === "string") {
        event.ext.app.sesId = sessionContext.id;
      }
    }
    if (this.session) {
      // If customer set session info, apply his context; otherwise apply context automatically generated
      if (typeof this.session.id === "string") {
        event.ext.app.sesId = this.session.id;
      } else {
        event.ext.app.sesId = this.sessionManager.automaticSession.id;
      }
    }
  };
  TelemetryContext.prototype.applyOperatingSystemContxt = function (event, itemCtx) {
    if (this.os && this.os.name) {
      event.ext.os = this.os;
    }
  };
  TelemetryContext.prototype.applyApplicationContext = function (event, itemCtx) {
    if (this.application) {
      if (typeof this.application.ver === "string") {
        event.tags[CtxTagKeys.applicationVersion] = this.application.ver;
      }
      if (typeof this.application.build === "string") {
        event.tags[CtxTagKeys.applicationBuild] = this.application.build;
      }
    }
  };
  TelemetryContext.prototype.applyDeviceContext = function (event, itemCtx) {
    if (this.device) {
      if (typeof this.device.id === "string") {
        event.ext.device.localId = this.device.id;
      }
      if (typeof this.device.ip === "string") {
        event.ext.device.ip = this.device.ip;
      }
      if (typeof this.device.model === "string") {
        event.ext.device.model = this.device.model;
      }
      if (typeof this.device.deviceClass === "string") {
        event.ext.device.deviceClass = this.device.deviceClass;
      }
    }
  };
  TelemetryContext.prototype.applyInternalContext = function (event, itemCtx) {
    if (this.internal) {
      if (typeof this.internal.agentVersion === "string") {
        event.tags[CtxTagKeys.internalAgentVersion] = this.internal.agentVersion; // not mapped in CS 4.0
      }
      if (typeof this.internal.sdkVersion === "string") {
        event.tags[CtxTagKeys.internalSdkVersion] = this.internal.sdkVersion;
      }
    }
  };
  TelemetryContext.prototype.applyLocationContext = function (event, itemCtx) {
    if (this.location) {
      if (typeof this.location.ip === "string") {
        event.tags[CtxTagKeys.locationIp] = this.location.ip;
      }
    }
  };
  TelemetryContext.prototype.applyOperationContext = function (event, itemCtx) {
    if (this.telemetryTrace) {
      var trace = event.ext.trace || {
        traceID: undefined,
        parentID: undefined
      };
      if (typeof this.telemetryTrace.traceID === "string") {
        trace.traceID = this.telemetryTrace.traceID;
      }
      if (typeof this.telemetryTrace.name === "string") {
        trace.name = this.telemetryTrace.name;
      }
      if (typeof this.telemetryTrace.parentID === "string") {
        trace.parentID = this.telemetryTrace.parentID;
      }
      event.ext.trace = trace;
    }
  };
  TelemetryContext.prototype.applyWebContext = function (event, itemCtx) {
    if (this.web) {
      event.ext.web = event.ext.web || {};
      event.ext.web = this.web;
    }
  };
  TelemetryContext.prototype.applyUserContext = function (event, itemCtx) {
    if (this.user) {
      if (!event.tags) {
        event.tags = [];
      }
      // stays in tags
      if (typeof this.user.accountId === "string") {
        var item = {};
        event.tags[CtxTagKeys.userAccountId] = this.user.accountId;
      }
      // CS 4.0
      if (typeof this.user.id === "string") {
        event.ext.user.id = this.user.id;
      }
      if (typeof this.user.authenticatedId === "string") {
        event.ext.user.authId = this.user.authenticatedId;
      }
    }
  };
  TelemetryContext.prototype.cleanUp = function (event, itemCtx) {
    if (event.ext[Extensions.DeviceExt] && CoreUtils.objKeys(event.ext[Extensions.DeviceExt]).length === 0) {
      delete event.ext[Extensions.DeviceExt];
    }
    if (event.ext[Extensions.UserExt] && CoreUtils.objKeys(event.ext[Extensions.UserExt]).length === 0) {
      delete event.ext[Extensions.UserExt];
    }
    if (event.ext[Extensions.WebExt] && CoreUtils.objKeys(event.ext[Extensions.WebExt]).length === 0) {
      delete event.ext[Extensions.WebExt];
    }
    if (event.ext[Extensions.OSExt] && CoreUtils.objKeys(event.ext[Extensions.OSExt]).length === 0) {
      delete event.ext[Extensions.OSExt];
    }
    if (event.ext[Extensions.AppExt] && CoreUtils.objKeys(event.ext[Extensions.AppExt]).length === 0) {
      delete event.ext[Extensions.AppExt];
    }
    if (event.ext[Extensions.TraceExt] && CoreUtils.objKeys(event.ext[Extensions.TraceExt]).length === 0) {
      delete event.ext[Extensions.TraceExt];
    }
  };
  return TelemetryContext;
}();
export { TelemetryContext };
