<ng-container [formGroup]="form">
	<!-- Company -->
	<section *ngIf="form.get('type').value === 'Company'">
		<h2>{{ appData().digitalAccount.summaryFormCompanyHeader }}</h2>
		<ul>
			<li>
				<span>{{ appData().digitalAccount.summaryCompanyName }}:</span>{{ form.get('company.name').value }}
			</li>
			<li>
				<span>{{ appData().digitalAccount.summaryCompanyNumber }}:</span>{{ form.get('company.id').value }}
			</li>
			@if (form.get('payment.eInvoiceOperator').value) {
				<li>
					<span>{{ appData().digitalAccount.summaryOperatorLabel }}:</span
					>{{ form.get('payment.eInvoiceOperator').value }}
				</li>
			}
			<li>
				<span>{{ appData().digitalAccount.summaryCompanyRegistrationNumber }}:</span
				>{{ form.get('company.vatId').value }}
			</li>
			<li>
				<span>{{ appData().digitalAccount.summaryAddress }}:</span>{{ getAddressString() }}
			</li>
			<li *ngIf="currentMarket === 'SE'">
				<span>{{ appData().digitalAccount.summaryMainDirection }}:</span
				>{{ getTranslatedMainDirection(form.get('company.direction').value) }}
			</li>
			<li *ngIf="currentMarket === 'FI'">
				@if (form.get('salesContractContact').value) {
					<li>
						<span>{{ appData().digitalAccount.summarySalesContractContactLabel }}:</span
						>{{ form.get('salesContractContact').value }}
					</li>
				}
				<span>{{ appData().digitalAccount.summaryPaymentMethod }}:</span>
				@if (form.get('payment.method').value === 'Card') {
					{{ this.appData().digitalAccount.chosenPaymentMethodIsCard }}
				} @else {
					{{ form.get('payment.method').value }}
				}
			</li>
			<li *ngIf="form.get('payment.method').value === 'Invoice'">
				<span>{{ appData().digitalAccount.summaryInvoiceOption }}:</span
				>{{ getInvoicePreferenceText(form.get('payment.selectedInvoiceDistribution').value) }}
			</li>
		</ul>
	</section>

	<!-- Individual -->
	<section *ngIf="form.get('type').value === 'Individual'">
		<h2>{{ appData().digitalAccount.summaryFormIndividualHeader }}</h2>
		<ul>
			<li>
				<span>{{ appData().digitalAccount.summaryFullName }}:</span>
				{{ form.get('individual.givenName').value }} {{ form.get('individual.familyName').value }}
			</li>
			<li>
				<span>{{ appData().digitalAccount.summaryPersonalNumber }}:</span>{{ form.get('individual.id').value }}
			</li>
			<li>
				<span>{{ appData().digitalAccount.summaryAddress }}:</span>{{ getAddressString() }}
			</li>
			<li *ngIf="currentMarket === 'FI'">
				<span>{{ appData().digitalAccount.summaryPaymentMethod }}:</span>
				@if (form.get('payment.method').value === 'Card') {
					{{ this.appData().digitalAccount.chosenPaymentMethodIsCard }}
				} @else {
					{{ form.get('payment.method').value }}
				}
			</li>
			<li *ngIf="form.get('payment.method').value === 'Invoice'">
				<span>{{ appData().digitalAccount.summaryInvoiceOption }}:</span
				>{{ getInvoicePreferenceText(form.get('payment.selectedInvoiceDistribution').value) }}
			</li>
		</ul>
	</section>

	<!-- Signatories -->
	@if (form.get('type').value === 'Company') {
		<section>
			<h2>{{ appData().digitalAccount.summaryFormSignaturesHeader }}</h2>
			@if (form.get('company.hasSignatoryCombinations').value === false) {
				<p>{{ appData().digitalAccount.summaryFormTextNoSignatures }}</p>
			} @else {
				<p>{{ appData().digitalAccount.summaryFormTextSignatures }}</p>
			}

			<ul>
				<li
					class="signatory-list-item"
					*ngFor="let signatory of form.get('company.signatories').value; let index = index"
				>
					<div>{{ index + 1 }}. {{ signatory.name }}, {{ signatory.role }}</div>
					<div class="email">{{ signatory.email }}</div>
				</li>
			</ul>
		</section>
	}
</ng-container>
