import { Util } from '@microsoft/applicationinsights-common';
import { LoggingSeverity, _InternalMessageId, getJSON } from '@microsoft/applicationinsights-core-js';
/*
 * An array based send buffer.
 */
var ArraySendBuffer = /** @class */function () {
  function ArraySendBuffer(config) {
    this._config = config;
    this._buffer = [];
  }
  ArraySendBuffer.prototype.enqueue = function (payload) {
    this._buffer.push(payload);
  };
  ArraySendBuffer.prototype.count = function () {
    return this._buffer.length;
  };
  ArraySendBuffer.prototype.clear = function () {
    this._buffer.length = 0;
  };
  ArraySendBuffer.prototype.getItems = function () {
    return this._buffer.slice(0);
  };
  ArraySendBuffer.prototype.batchPayloads = function (payload) {
    if (payload && payload.length > 0) {
      var batch = this._config.emitLineDelimitedJson() ? payload.join("\n") : "[" + payload.join(",") + "]";
      return batch;
    }
    return null;
  };
  ArraySendBuffer.prototype.markAsSent = function (payload) {
    this.clear();
  };
  ArraySendBuffer.prototype.clearSent = function (payload) {
    // not supported
  };
  return ArraySendBuffer;
}();
export { ArraySendBuffer };
/*
 * Session storege buffer holds a copy of all unsent items in the browser session storage.
 */
var SessionStorageSendBuffer = /** @class */function () {
  function SessionStorageSendBuffer(logger, config) {
    this._bufferFullMessageSent = false;
    this._logger = logger;
    this._config = config;
    var bufferItems = this.getBuffer(SessionStorageSendBuffer.BUFFER_KEY);
    var notDeliveredItems = this.getBuffer(SessionStorageSendBuffer.SENT_BUFFER_KEY);
    this._buffer = bufferItems.concat(notDeliveredItems);
    // If the buffer has too many items, drop items from the end.
    if (this._buffer.length > SessionStorageSendBuffer.MAX_BUFFER_SIZE) {
      this._buffer.length = SessionStorageSendBuffer.MAX_BUFFER_SIZE;
    }
    // update DataLossAnalyzer with the number of recovered items
    // Uncomment if you want to use DataLossanalyzer
    // DataLossAnalyzer.itemsRestoredFromSessionBuffer = this._buffer.length;
    this.setBuffer(SessionStorageSendBuffer.SENT_BUFFER_KEY, []);
    this.setBuffer(SessionStorageSendBuffer.BUFFER_KEY, this._buffer);
  }
  SessionStorageSendBuffer.prototype.enqueue = function (payload) {
    if (this._buffer.length >= SessionStorageSendBuffer.MAX_BUFFER_SIZE) {
      // sent internal log only once per page view
      if (!this._bufferFullMessageSent) {
        this._logger.throwInternal(LoggingSeverity.WARNING, _InternalMessageId.SessionStorageBufferFull, "Maximum buffer size reached: " + this._buffer.length, true);
        this._bufferFullMessageSent = true;
      }
      return;
    }
    this._buffer.push(payload);
    this.setBuffer(SessionStorageSendBuffer.BUFFER_KEY, this._buffer);
  };
  SessionStorageSendBuffer.prototype.count = function () {
    return this._buffer.length;
  };
  SessionStorageSendBuffer.prototype.clear = function () {
    this._buffer.length = 0;
    this.setBuffer(SessionStorageSendBuffer.BUFFER_KEY, []);
    this.setBuffer(SessionStorageSendBuffer.SENT_BUFFER_KEY, []);
    this._bufferFullMessageSent = false;
  };
  SessionStorageSendBuffer.prototype.getItems = function () {
    return this._buffer.slice(0);
  };
  SessionStorageSendBuffer.prototype.batchPayloads = function (payload) {
    if (payload && payload.length > 0) {
      var batch = this._config.emitLineDelimitedJson() ? payload.join("\n") : "[" + payload.join(",") + "]";
      return batch;
    }
    return null;
  };
  SessionStorageSendBuffer.prototype.markAsSent = function (payload) {
    this._buffer = this.removePayloadsFromBuffer(payload, this._buffer);
    this.setBuffer(SessionStorageSendBuffer.BUFFER_KEY, this._buffer);
    var sentElements = this.getBuffer(SessionStorageSendBuffer.SENT_BUFFER_KEY);
    if (sentElements instanceof Array && payload instanceof Array) {
      sentElements = sentElements.concat(payload);
      if (sentElements.length > SessionStorageSendBuffer.MAX_BUFFER_SIZE) {
        // We send telemetry normally. If the SENT_BUFFER is too big we don't add new elements
        // until we receive a response from the backend and the buffer has free space again (see clearSent method)
        this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.SessionStorageBufferFull, "Sent buffer reached its maximum size: " + sentElements.length, true);
        sentElements.length = SessionStorageSendBuffer.MAX_BUFFER_SIZE;
      }
      this.setBuffer(SessionStorageSendBuffer.SENT_BUFFER_KEY, sentElements);
    }
  };
  SessionStorageSendBuffer.prototype.clearSent = function (payload) {
    var sentElements = this.getBuffer(SessionStorageSendBuffer.SENT_BUFFER_KEY);
    sentElements = this.removePayloadsFromBuffer(payload, sentElements);
    this.setBuffer(SessionStorageSendBuffer.SENT_BUFFER_KEY, sentElements);
  };
  SessionStorageSendBuffer.prototype.removePayloadsFromBuffer = function (payloads, buffer) {
    var remaining = [];
    for (var i in buffer) {
      var contains = false;
      for (var j in payloads) {
        if (payloads[j] === buffer[i]) {
          contains = true;
          break;
        }
      }
      if (!contains) {
        remaining.push(buffer[i]);
      }
    }
    ;
    return remaining;
  };
  SessionStorageSendBuffer.prototype.getBuffer = function (key) {
    var prefixedKey = key;
    try {
      prefixedKey = this._config.namePrefix && this._config.namePrefix() ? this._config.namePrefix() + "_" + prefixedKey : prefixedKey;
      var bufferJson = Util.getSessionStorage(this._logger, prefixedKey);
      if (bufferJson) {
        var buffer = getJSON().parse(bufferJson);
        if (buffer) {
          return buffer;
        }
      }
    } catch (e) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.FailedToRestoreStorageBuffer, " storage key: " + prefixedKey + ", " + Util.getExceptionName(e), {
        exception: Util.dump(e)
      });
    }
    return [];
  };
  SessionStorageSendBuffer.prototype.setBuffer = function (key, buffer) {
    var prefixedKey = key;
    try {
      prefixedKey = this._config.namePrefix && this._config.namePrefix() ? this._config.namePrefix() + "_" + prefixedKey : prefixedKey;
      var bufferJson = JSON.stringify(buffer);
      Util.setSessionStorage(this._logger, prefixedKey, bufferJson);
    } catch (e) {
      // if there was an error, clear the buffer
      // telemetry is stored in the _buffer array so we won't loose any items
      Util.setSessionStorage(this._logger, prefixedKey, JSON.stringify([]));
      this._logger.throwInternal(LoggingSeverity.WARNING, _InternalMessageId.FailedToSetStorageBuffer, " storage key: " + prefixedKey + ", " + Util.getExceptionName(e) + ". Buffer cleared", {
        exception: Util.dump(e)
      });
    }
  };
  SessionStorageSendBuffer.BUFFER_KEY = "AI_buffer";
  SessionStorageSendBuffer.SENT_BUFFER_KEY = "AI_sentBuffer";
  // Maximum number of payloads stored in the buffer. If the buffer is full, new elements will be dropped.
  SessionStorageSendBuffer.MAX_BUFFER_SIZE = 2000;
  return SessionStorageSendBuffer;
}();
export { SessionStorageSendBuffer };
