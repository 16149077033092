import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, Signal, inject, input } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { EMPTY, catchError, debounceTime, switchMap } from 'rxjs';
import { EpiService } from '../../services/epi/epi.service';
import { JobsiteService } from '../../services/jobsite/jobsite.service';
import { MarketService } from '../../services/market.service';
import { AddJobsiteType, CreateJobsiteForm } from '../../types/jobsite.types';
import { AppData } from '../../types/translations.types';
import { postalCodeValidator } from '../../utils/validation/postal-code-validator';
import { noWhitespaceValidator } from '../../utils/validation/whitespace-validator';
import { ButtonComponent } from '../button/button.component';
import { InputComponent } from '../input/input.component';
import { MessageBoxComponent } from '../message-box/message-box.component';

@Component({
	selector: 'cramo-create-jobsite-form',
	standalone: true,
	imports: [CommonModule, InputComponent, ReactiveFormsModule, ButtonComponent, MessageBoxComponent],
	templateUrl: './create-jobsite-form.component.html',
	styleUrl: './create-jobsite-form.component.scss',
})
export class CreateJobsiteFormComponent {
	@Output() public jobsiteSubmit = new EventEmitter<AddJobsiteType>();
	public isLoading = input(false);

	public form: FormGroup<CreateJobsiteForm>;
	public appData: Signal<AppData>;
	public jobsiteExists: boolean = false;
	public isJobsiteExistsMessageShown: boolean = false;
	public existingJobsiteName: string;
	private marketService = inject(MarketService);

	private jobsiteService = inject(JobsiteService);

	constructor(
		private epiService: EpiService,
		private formBuilder: NonNullableFormBuilder,
	) {
		this.appData = toSignal(this.epiService.appData$, { requireSync: true });

		this.form = this.formBuilder.group({
			name: ['', [Validators.required, noWhitespaceValidator()]],
			reference: [''],
			address: this.formBuilder.group({
				city: ['', [Validators.required, noWhitespaceValidator()]],
				postalCode: ['', [Validators.required, postalCodeValidator(this.marketService.currentMarket)]],
				streetAddress: ['', [Validators.required, noWhitespaceValidator()]],
			}),
		}) as unknown as FormGroup<CreateJobsiteForm>;

		this.form.controls.name.valueChanges
			.pipe(
				takeUntilDestroyed(),
				debounceTime(500),
				switchMap((jobsiteName) =>
					this.jobsiteService.getJobsiteByName(jobsiteName).pipe(
						catchError(() => {
							this.jobsiteExists = false;
							this.isJobsiteExistsMessageShown = false;
							return EMPTY;
						}),
					),
				),
			)
			.subscribe((jobsite) => {
				if (jobsite) {
					this.jobsiteExists = true;
					this.existingJobsiteName = jobsite.Name;
					this.isJobsiteExistsMessageShown = true;
				} else {
					this.jobsiteExists = false;
					this.isJobsiteExistsMessageShown = false;
				}
			});
	}

	public addJobsite() {
		const jobsite: AddJobsiteType = {
			Name: this.form.controls.name.value,
			ProjectId: this.form.controls.reference.value,
			Address: {
				City: this.form.controls.address.controls.city.value,
				StreetAddress: this.form.controls.address.controls.streetAddress.value,
				PostalCode: this.form.controls.address.controls.postalCode.value,
			},
			IsFavorite: false,
		};
		this.jobsiteSubmit.emit(jobsite);
	}
}
