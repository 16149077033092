import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * Replace variables inside translation strings with actual values.
 *
 * Example:
 *	<p
 *		cramoInjectTranslationVars
 *		[templateText]="hello {{name}}"
 *		[variables]="{ name: Arne}"
 *	></p>
 */

@Directive({
	selector: '[cramoInjectTranslationVars]',
	standalone: true,
})
export class InjectTranslationVarsDirective implements OnChanges {
	@Input() public templateText = '';
	@Input() public variables: Record<string, string> = {};

	constructor(private el: ElementRef) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.templateText || changes.variables) {
			this.updateText();
		}
	}

	private updateText(): void {
		let processedText = this.templateText;
		Object.keys(this.variables).forEach((key) => {
			const regex = new RegExp(`{{${key}}}`, 'g');
			processedText = processedText.replace(regex, this.variables[key]);
		});
		this.el.nativeElement.innerText = processedText;
	}
}
