<ng-container>
	<ng-container [formGroup]="form">
		<ng-container formGroupName="company" *ngIf="signatoryCombinations()?.length">
			<mat-radio-group color="primary" class="radio-wrapper" formControlName="selectedSignatoryGroup">
				<div
					class="radio-group"
					*ngFor="
						let combination of showAll ? signatoryCombinations() : signatoryCombinations().slice(0, 3);
						let i = index
					"
				>
					<mat-radio-button [id]="'radio-' + i" [value]="i" (change)="onSignatoriesSelect(combination.Signatories)" />

					<section class="signatories-container" *ngIf="combination.Signatories.length">
						<article class="signatory-container" *ngFor="let signatory of combination.Signatories; let index = index">
							<div class="signatory-info">
								<span class="signatory-name">{{ signatory.Name }}</span>
								<span class="signatory-role">{{ signatory.Role }}</span>
							</div>

							<ng-container *ngIf="form.get('company.selectedSignatoryGroup').value === i">
								<cramo-input
									type="email"
									label="{{ appData().formElements.emailLabel }}"
									[control]="getSignatoryEmailControl(index)"
									subscriptSizing="dynamic"
									[errorMessageMap]="{
										required: appData().formElements.fieldIsRequired,
										maxlength: emailMaxLengthErrorMessage,
										pattern: appData().formElements.emailIsInvalid,
									}"
								/>
							</ng-container>
						</article>
					</section>
				</div>
			</mat-radio-group>
			<cramo-button
				class="reveal-button"
				*ngIf="!showAll && signatoryCombinations().length > 3"
				text="{{ appData().global.showMore }} ({{ signatoryCombinations().length - 3 }})"
				(onclick)="showAll = true"
				buttonStyle="stroked"
			/>
			<cramo-button
				class="reveal-button"
				*ngIf="showAll && signatoryCombinations().length > 3"
				text="{{ appData().global.showLess }}"
				(onclick)="showAll = false"
				buttonStyle="stroked"
			/>
		</ng-container>
	</ng-container>
	<cramo-message-box
		*ngIf="
			form.get('company.selectedSignatoryGroup')?.touched &&
			form.get('company.selectedSignatoryGroup')?.hasError('required')
		"
		[header]="appData().digitalAccount.signatoryGroupNotSetHeader"
		[message]="appData().digitalAccount.signatoryGroupNotSetText"
		level="error"
	/>
</ng-container>

<ng-template #isLoadingTemplate>
	<cramo-icon iconName="spinner" [size]="24" />
</ng-template>
