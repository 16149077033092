import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/**
 * Modify request url to point to backend specified in used environment file.
 */

@Injectable()
export class ReqFormatInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(
			req.clone({
				url: this.modifyUrl(req.url),
			}),
		);
	}

	private modifyUrl(originalUrl: string): string {
		const excludedSubstrings = ['assets', 'googleapis', 'b2clogin'];

		const isExcluded = excludedSubstrings.some((substring) => originalUrl.includes(substring));

		if (environment.useDifferentBackend && !isExcluded) {
			return environment.backendDomain + originalUrl;
		}

		return originalUrl;
	}
}
