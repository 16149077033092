<cramo-page-old>
	<div class="error-container fcolumn-center-center" data-test-id="cy-not-found-container">
		<cramo-icon iconName="error" [size]="60" />

		<span class="error-title">{{ errorHeadingText }}</span>
		<span class="error-text">{{ errorDescriptionText }}</span>
		<span class="error-url">{{ url }}</span>
		<cramo-button buttonColor="primary" [routerLink]="'/'" data-test-id="button-back" [text]="backToHomeText" />
	</div>
</cramo-page-old>
