<cramo-page-old>
	<div class="done-container" data-test-id="order-success-message">
		<cramo-loading-area
			*ngIf="(cartService.cartCount$ | async).isLoading"
			[showTransparent]="true"
			title="{{ appData().global.loading }}"
		/>

		<h2 class="qa-label-thanksForOrderTitle">{{ appData().checkout.thanksForOrderTitle }}</h2>
		<p class="qa-label-thanksForOrderText">{{ appData().checkout.thanksForOrderText }}</p>
		<div *ngIf="hasTransportItem" class="no-transport-booked-container">
			<h4 class="no-transport-booked-title">{{ appData().checkout.noReturnTransportBookedTitle }}</h4>
			<p class="no-transport-booked-text">{{ appData().checkout.noReturnTransportBookedText }}</p>
		</div>
		<button
			*ngIf="(cartService.cartCount$ | async).cartCount === 0"
			class="cramo-btn cramo-btn-primary"
			[routerLink]="'/'"
		>
			{{ appData().checkout.backToHome }}
		</button>
		<button
			*ngIf="(cartService.cartCount$ | async).cartCount !== 0"
			class="cramo-btn cramo-btn-primary"
			[routerLink]="'/' + currentLanguage + '/cart'"
		>
			{{ appData().checkout.backToCart }}
		</button>
	</div>
</cramo-page-old>
