import { PreloadingStrategy, Route } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class AppCustomPreloader implements PreloadingStrategy {
	public preload(route: Route, load: () => Observable<void>): Observable<unknown> {
		return route.data?.preload ? load() : of(null);
	}
}
