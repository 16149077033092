import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, inject, Injector, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';
import { MenuDesktopComponent } from '../menu-desktop/menu-desktop.component';
import { MenuMobileComponent } from '../menu-mobile/menu-mobile.component';

@Component({
	selector: 'cramo-menu',
	standalone: true,
	imports: [MenuMobileComponent, MenuDesktopComponent],
	templateUrl: './menu.component.html',
})
export class MenuComponent implements OnInit {
	private breakpointObserver = inject(BreakpointObserver);
	private injector = inject(Injector);

	protected isMobile: Signal<boolean>;

	ngOnInit(): void {
		this.isMobile = toSignal(
			this.breakpointObserver.observe('(max-width: 767px)').pipe(map((result) => result.matches)),
			{ injector: this.injector },
		);
	}
}
