<ng-container [formGroup]="form">
	<!-- payment option - card -->
	@if (featureToggles().digitalAccount.isCardPaymentEnabled) {
		<section>
			<ng-container formGroupName="payment">
				<mat-radio-group color="primary" formControlName="method">
					<div *ngFor="let payment of payments()?.[form.get('type').value]">
						<mat-radio-button [value]="payment.Method">
							<span class="custom-radio">{{ payment.Label }}</span>
						</mat-radio-button>
					</div>
				</mat-radio-group>
			</ng-container>
		</section>
	}

	<!-- payment option - invoice -->
	@if (form.get('payment.method').value === 'Invoice') {
		<section class="invoice-options {{ featureToggles().digitalAccount.isCardPaymentEnabled ? 'card-exist' : '' }}">
			<ng-container formGroupName="payment">
				<mat-radio-group color="primary" formControlName="selectedInvoiceDistribution">
					<ng-container *ngFor="let invoiceOption of invoiceOptions()">
						<div
							class="invoice-option-wrapper"
							[ngClass]="{ selected: invoiceOption.Type === form.get('payment.selectedInvoiceDistribution').value }"
						>
							<!-- EMAIL OPTION -->
							@if (invoiceOption.Type === 'Email') {
								<mat-radio-button [value]="invoiceOption.Type">
									<span class="custom-radio">{{ invoiceOption.Label }}</span>
								</mat-radio-button>
								@if (form.get('payment.selectedInvoiceDistribution').value === 'Email') {
									<article>
										<p>{{ invoiceOption.Description }}</p>
										<cramo-checkbox
											[control]="form.get('payment.sameEmail')"
											label="{{ appData().digitalAccount.checkboxLabelAddInvoiceEmail }}"
										/>
										@if (!form.get('payment.sameEmail').value) {
											<cramo-input
												type="email"
												label="Email"
												[control]="form.get('payment.invoiceEmail')"
												[errorMessageMap]="{
													maxlength: emailMaxLengthErrorMessage,
													pattern: appData().formElements.emailIsInvalid,
												}"
											/>
										}
									</article>
								}
							}

							<!-- E-INVOICE OPTION -->
							@if (form.get('type').value === 'Company' && invoiceOption.Type === 'EInvoice') {
								<mat-radio-button [value]="invoiceOption?.Type">
									<span class="custom-radio">{{ invoiceOption.Label }}</span>
								</mat-radio-button>
								@if (
									form.get('payment.selectedInvoiceDistribution').value === 'EInvoice' &&
									featureToggles().digitalAccount.isEinvoicePaymentOptionEnabled
								) {
									<article>
										<p [innerHTML]="invoiceOption.Description"></p>
										<cramo-input
											class="operator-input"
											required
											type="text"
											label="{{ appData().digitalAccount.operatorLabel }}"
											[control]="form.get('payment.eInvoiceOperator')"
											subscriptSizing="dynamic"
											[errorMessageMap]="{
												required: appData().formElements.fieldIsRequired,
												whitespace: appData().formElements.operatorIsInvalid,
											}"
										/>
										<cramo-input
											required
											type="text"
											label="{{ appData().formElements.eInvoiceAddressLabel }}"
											[control]="form.get('payment.eInvoiceAddress')"
											subscriptSizing="dynamic"
											[errorMessageMap]="{
												required: appData().formElements.fieldIsRequired,
												IBAN: appData().formElements.IBANIsInvalid,
											}"
										/>
									</article>
								}
							}

							<!-- PAPER OPTION -->
							@if (invoiceOption.Type === 'Paper') {
								<mat-radio-button [value]="invoiceOption.Type">
									<span class="custom-radio">{{ invoiceOption.Label }}</span>
								</mat-radio-button>
								@if (form.get('payment.selectedInvoiceDistribution').value === 'Paper') {
									<article>
										@if (form.get('type').value === 'Company') {
											<p
												cramoInjectTranslationVars
												[templateText]="invoiceOption.Description"
												[variables]="{ address: getAddressAsString(form.get('company.address').value) }"
											></p>
										}
										@if (form.get('type').value === 'Individual') {
											<p
												cramoInjectTranslationVars
												[templateText]="invoiceOption.Description"
												[variables]="{ address: getAddressAsString(form.get('individual.address').value) }"
											></p>
										}
										<!-- Commented 2024-09-06 Cause of new requirements from Finland that rental does not have the ability to handle different invoice addresses -->
										<!-- <cramo-checkbox
											[control]="form.get('payment.sameAddress')"
											[label]="appData().digitalAccount.checkboxLabelAddInvoiceAddress"
										/> -->
										<!-- @if (!form.get('payment.sameAddress').value) {
											<div class="address-container">
												<cramo-input
													class="input-address"
													required
													type="text"
													label="{{ appData().formElements.addressLabel }}"
													[control]="form.get('payment.invoiceAddress.streetAddress')"
													subscriptSizing="dynamic"
												/>
												<cramo-input
													class="input-postalcode"
													required
													type="text"
													label="{{ appData().formElements.postalCodeLabel }}"
													[control]="form.get('payment.invoiceAddress.postalCode')"
													subscriptSizing="dynamic"
												/>
												<cramo-input
													class="input-city"
													required
													type="text"
													label="{{ appData().formElements.cityLabel }}"
													[control]="form.get('payment.invoiceAddress.city')"
													subscriptSizing="dynamic"
												/>
											</div>
										} -->
									</article>
								}
							}
						</div>
					</ng-container>
				</mat-radio-group>
			</ng-container>
		</section>
	}

	@if (form.get('payment.method')?.touched && form.get('payment.method')?.hasError('required')) {
		<cramo-message-box
			[header]="appData().digitalAccount.paymentMethodNotSetHeader"
			[message]="appData().digitalAccount.paymentMethodNotSetText"
			level="error"
		/>
	}

	@if (
		form.get('payment.selectedInvoiceDistribution')?.touched &&
		form.get('payment.method')?.value === 'Invoice' &&
		form.get('payment.selectedInvoiceDistribution')?.hasError('required')
	) {
		<cramo-message-box
			[header]="appData().digitalAccount.invoiceDistNotSelectedHeader"
			[message]="appData().digitalAccount.invoiceDistNotSelectedText"
			level="error"
		/>
	}
</ng-container>
