import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EpiService } from '../../../../services/epi/epi.service';
import { EventBusService } from '../../../../services/event-bus.service';
import { MarketService } from '../../../../services/market.service';
import { AppData } from '../../../../types/translations.types';
import { IconComponent } from '../../../icon/icon.component';
import { LinkComponent } from '../../../link/link.component';
import { Menu, MenuChild } from '../../types/menu.types';

@Component({
	selector: 'cramo-menu-desktop',
	templateUrl: './menu-desktop.component.html',
	styleUrls: ['./menu-desktop.component.scss'],
	standalone: true,
	imports: [CommonModule, LinkComponent, IconComponent],
})
export class MenuDesktopComponent {
	private epiService = inject(EpiService);
	private eventBusService = inject(EventBusService);
	private marketService = inject(MarketService);

	protected isMainMenuVisible = toSignal(this.epiService.isMainMenuVisible$);

	protected appData: AppData;
	protected showInnerMenu = false;
	protected showProductMenu = false;
	protected showContentMenu = false;
	protected selectedMenu: string;
	protected currentItem: MenuChild;
	protected menu: Menu;
	protected openTabs: Record<string, boolean> = {};
	protected currentLanguage: string;

	private showMenuDelayFlag = false;

	constructor() {
		this.menu = this.epiService.menu;
		this.currentItem = this.menu?.Category ?? null;
		this.appData = this.epiService.appData;
		this.currentLanguage = this.marketService.currentLanguage;
	}

	public showMenuItem(menuItem: MenuChild, immediate = false) {
		if (this.showInnerMenu === true || immediate === true) {
			this.openMenu(menuItem);
		} else {
			this.showMenuDelayFlag = true;
			setTimeout(() => {
				if (this.showMenuDelayFlag === true) {
					this.openMenu(menuItem);
				}
			}, 400);
		}
	}

	private openMenu(menuItem: MenuChild) {
		this.showInnerMenu = true;
		this.selectedMenu = menuItem.Name;
		if (menuItem.Name === this.menu.Category.Name) {
			this.currentItem = this.menu.Category;
			this.showProductMenu = true;
			this.showContentMenu = false;
		} else if (menuItem.Children.length > 0) {
			this.currentItem = menuItem;
			this.showContentMenu = true;
			this.showProductMenu = false;
		} else {
			this.showInnerMenu = false;
		}
		this.eventBusService.menuOpenSubject.next(true);
	}

	public hideMenu() {
		setTimeout(() => {
			this.showMenuDelayFlag = false;
			this.showInnerMenu = false;
			this.selectedMenu = null;
			this.eventBusService.menuOpenSubject.next(false);
		}, 0);
	}

	public onMenuClick(item: MenuChild, open = true) {
		if (item.Children && item.Children.length > 0 && open) {
			this.openTab(item);
		}
	}

	private openTab(category: MenuChild) {
		if (category.Children.length > 0) {
			const newState = this.openTabs[category.Name] !== true;
			this.closeAll();
			this.openTabs[category.Name] = newState;
		} else {
			this.closeAll();
		}
	}

	private closeAll() {
		for (const key in this.openTabs) {
			this.openTabs[key] = false;
		}
	}
}
