import { Util } from '@microsoft/applicationinsights-common';
var Traceparent = /** @class */function () {
  function Traceparent(traceId, spanId) {
    this.traceFlag = Traceparent.DEFAULT_TRACE_FLAG;
    this.version = Traceparent.DEFAULT_VERSION;
    if (traceId && Traceparent.isValidTraceId(traceId)) {
      this.traceId = traceId;
    } else {
      this.traceId = Util.generateW3CId();
    }
    if (spanId && Traceparent.isValidSpanId(spanId)) {
      this.spanId = spanId;
    } else {
      this.spanId = Util.generateW3CId().substr(0, 16);
    }
  }
  Traceparent.isValidTraceId = function (id) {
    return id.match(/^[0-9a-f]{32}$/) && id !== "00000000000000000000000000000000";
  };
  Traceparent.isValidSpanId = function (id) {
    return id.match(/^[0-9a-f]{16}$/) && id !== "0000000000000000";
  };
  Traceparent.prototype.toString = function () {
    return this.version + "-" + this.traceId + "-" + this.spanId + "-" + this.traceFlag;
  };
  Traceparent.DEFAULT_TRACE_FLAG = "01";
  Traceparent.DEFAULT_VERSION = "00";
  return Traceparent;
}();
export { Traceparent };
