<section [formGroup]="form">
	<cramo-input
		type="email"
		label="{{ appData().formElements.emailLabel }}"
		[control]="form.get('contact.email')"
		[maxLengthHint]="50"
		[errorMessageMap]="{
			required: appData().formElements.fieldIsRequired,
			maxlength: emailMaxLengthErrorMessage,
			pattern: appData().formElements.emailIsInvalid,
		}"
	/>
	<cramo-input-tel
		label="{{ appData().users.mobilePhone }}"
		[control]="form.get('contact.phone')"
		[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
	/>
</section>
