import { CoreUtils } from "./CoreUtils";
/**
 * Class to manage sending notifications to all the listeners.
 */
var NotificationManager = /** @class */function () {
  function NotificationManager() {
    this.listeners = [];
  }
  /**
   * Adds a notification listener.
   * @param {INotificationListener} listener - The notification listener to be added.
   */
  NotificationManager.prototype.addNotificationListener = function (listener) {
    this.listeners.push(listener);
  };
  /**
   * Removes all instances of the listener.
   * @param {INotificationListener} listener - AWTNotificationListener to remove.
   */
  NotificationManager.prototype.removeNotificationListener = function (listener) {
    var index = CoreUtils.arrIndexOf(this.listeners, listener);
    while (index > -1) {
      this.listeners.splice(index, 1);
      index = CoreUtils.arrIndexOf(this.listeners, listener);
    }
  };
  /**
   * Notification for events sent.
   * @param {ITelemetryItem[]} events - The array of events that have been sent.
   */
  NotificationManager.prototype.eventsSent = function (events) {
    var _this = this;
    var _loop_1 = function (i) {
      if (this_1.listeners[i].eventsSent) {
        setTimeout(function () {
          return _this.listeners[i].eventsSent(events);
        }, 0);
      }
    };
    var this_1 = this;
    for (var i = 0; i < this.listeners.length; ++i) {
      _loop_1(i);
    }
  };
  /**
   * Notification for events being discarded.
   * @param {ITelemetryItem[]} events - The array of events that have been discarded by the SDK.
   * @param {number} reason           - The reason for which the SDK discarded the events. The EventsDiscardedReason
   * constant should be used to check the different values.
   */
  NotificationManager.prototype.eventsDiscarded = function (events, reason) {
    var _this = this;
    var _loop_2 = function (i) {
      if (this_2.listeners[i].eventsDiscarded) {
        setTimeout(function () {
          return _this.listeners[i].eventsDiscarded(events, reason);
        }, 0);
      }
    };
    var this_2 = this;
    for (var i = 0; i < this.listeners.length; ++i) {
      _loop_2(i);
    }
  };
  return NotificationManager;
}();
export { NotificationManager };
