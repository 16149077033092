import { AbstractControl, ValidatorFn } from '@angular/forms';

export function glnOrIbanValidator(): ValidatorFn {
	return (control: AbstractControl<string>): { [key: string]: unknown } | null => {
		const value = control.value.replace(/ /g, '').toUpperCase();

		if (value.startsWith('0037') || value.startsWith('FI0037') || value.startsWith('TE0037')) {
			return null;
		}

		if (validateIban(value)) {
			return null;
		}

		return { IBAN: { value: control.value } };
	};
}

const IBANMinLength = 15;
const IBANMaxLength = 34;

function validateIban(iban: string): boolean {
	const cleanIBAN = iban.replace(/ /g, '').toUpperCase();

	if (cleanIBAN.length < IBANMinLength || cleanIBAN.length > IBANMaxLength) {
		return false;
	}

	const rearrangedIBAN = cleanIBAN.slice(4) + cleanIBAN.slice(0, 4);

	const numericIBAN = rearrangedIBAN
		.split('')
		.map((n) => {
			const code = n.charCodeAt(0);
			return code >= 'A'.charCodeAt(0) && code <= 'Z'.charCodeAt(0) ? code - 'A'.charCodeAt(0) + 10 : n;
		})
		.join('');

	let remainder = numericIBAN;
	while (remainder.length > 2) {
		const block = remainder.slice(0, 9);
		remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
	}

	return parseInt(remainder, 10) % 97 === 1;
}
