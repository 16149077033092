import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject, take } from 'rxjs';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { UserService } from '../../services/user/user.service';
import { InternationalPhoneRegExp } from '../../utils/constants';

@Component({
	selector: 'cramo-account-change-phone-page',
	templateUrl: './account-change-phone-page.component.html',
	styleUrls: ['./account-change-phone-page.component.scss'],
})
export class AccountChangePhonePageComponent implements OnInit, OnDestroy {
	private onDestroy$ = new ReplaySubject(1);
	private currentLanguage: string;

	public textLabels: { [key: string]: string } = {};
	public phoneForm: UntypedFormGroup;
	public isLoading = false;

	constructor(
		private epiService: EpiService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private marketService: MarketService,
		private userService: UserService,
	) {}

	ngOnInit() {
		this.createForms();
		this.setTextLabels();
		this.currentLanguage = this.marketService.currentLanguage;
	}

	private setTextLabels(): void {
		const accountLabels = this.epiService.appData.myPages.account;
		const globalLabels = this.epiService.appData.global;

		this.textLabels.tel = accountLabels.telephone;
		this.textLabels.cancel = globalLabels.cancel;
		this.textLabels.save = globalLabels.saveChanges;
		this.textLabels.title = accountLabels.changePhoneNumber;
		this.textLabels.phoneFormatExample = accountLabels.telephoneFormatExample;
		this.textLabels.pageDescription = accountLabels.changePhonePageDescription;
		this.textLabels.invalidPhoneMessage = accountLabels.invalidPhoneMessage;
	}

	private createForms() {
		this.userService.user$.pipe(take(1)).subscribe((user) => {
			const currentNumber = user.MobilePhone;

			this.phoneForm = this.formBuilder.group({
				phone: [
					currentNumber,
					[Validators.required, Validators.maxLength(20), Validators.pattern(InternationalPhoneRegExp)],
				],
			});
		});
	}

	private navigateToAccount() {
		this.router.navigate(['../', this.currentLanguage, 'account']);
	}

	public onSubmit() {
		this.isLoading = true;
		const phone = this.phoneForm.get('phone').value;
		this.userService.updateMobilePhone(phone).subscribe(() => {
			this.isLoading = false;
			this.navigateToAccount();
		});
	}

	public cancel(event: Event) {
		event.preventDefault();
		this.navigateToAccount();
	}

	public ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
