<cramo-page-old>
	<div class="account-not-found-page__page">
		<h2 class="my-4">{{ epiTexts.accountHeading }}</h2>
		<div class="account-not-found-page__page__white">
			<div class="account-not-found-page__container">
				<h4>{{ epiTexts.accountNotFoundHeading }}</h4>
				<p>{{ epiTexts.accountNotFoundText }}</p>
				<h4>{{ epiTexts.accountExistsHeading }}</h4>
				<p>{{ epiTexts.accountExistsText }}</p>

				<a (click)="login()" class="pointer my-4 cramo-btn-link-1">{{ epiTexts.loginAgainLink }}</a>

				<ng-container *ngIf="registrationLink">
					<h4>{{ epiTexts.applyForAccountHeading }}</h4>
					<p>{{ epiTexts.applyForAccountText }}</p>
					<a [href]="[registrationLink]" class="my-4 cramo-btn-link-1"> {{ epiTexts.applyForAccountLink }}</a>
				</ng-container>
			</div>
		</div>
	</div>
</cramo-page-old>
