<div
	class="menu-header-container frow-center-stretch"
	[ngClass]="isMainMenuVisible() ? 'visible' : 'hidden'"
	data-test-id="menu-header-desktop"
	(mouseleave)="hideMenu()"
>
	<div class="menu-header-items page-wrapper">
		<!-- First level links -->
		<div
			class="menu-header-item"
			*ngFor="let menuItem of menu?.MenuItems; let i = index"
			(mouseenter)="showMenuItem(menuItem)"
			(click)="showMenuItem(menuItem, true)"
			[ngClass]="{ 'selected-menu': menuItem.Name === selectedMenu }"
			attr.data-test-id="menu-desktop-lvl1-item-{{ i + 1 }}"
		>
			<span attr.data-test-id="menu-header-item-{{ i }}">{{ menuItem.Name }}</span>
		</div>
	</div>

	<!-- Link tree -->
	<div class="menu-container" [ngClass]="{ 'hide-menu': !showInnerMenu }" data-test-id="desktop-menu-link-tree">
		<div class="page-wrapper">
			<div class="menu-padding">
				<div class="menu-grid">
					<div
						class="menu-cell"
						*ngFor="let category of currentItem?.Children; let i = index"
						attr.data-test-id="menu-desktop-lvl2-item-{{ i + 1 }}"
					>
						<cramo-link [url]="category.LinkUrl" class="main-category-link" (click)="hideMenu()">
							<div class="main-category">{{ category.Name }}</div>
						</cramo-link>

						<div class="sub-category">
							<div *ngFor="let subCategory of category.Children">
								@if (subCategory.Children && subCategory.Children.length > 0) {
									<div>
										<div
											class="cursor sub-category-row single-indent frow-start-center"
											(click)="onMenuClick(subCategory)"
										>
											@if (openTabs[subCategory.Name]) {
												<div class="collapse-icon">
													<cramo-icon iconName="remove" [size]="16" />
												</div>
											} @else {
												<div class="expand-icon">
													<cramo-icon iconName="add" [size]="16" />
												</div>
											}

											<span class="hover" [ngClass]="{ 'expanded-text': openTabs[subCategory.Name] }"
												>{{ subCategory.Name }}
											</span>
										</div>
									</div>
								}

								@if (!subCategory.Children || subCategory.Children.length === 0) {
									<div class="sub-category-row">
										<cramo-link [url]="subCategory.LinkUrl" (click)="hideMenu()">
											<div class="double-indent hover">{{ subCategory.Name }}</div>
										</cramo-link>
									</div>
								}

								@if (openTabs[subCategory.Name]) {
									<div>
										<cramo-link
											*ngFor="let subSubCategory of subCategory.Children"
											[url]="subSubCategory.LinkUrl"
											(click)="hideMenu()"
										>
											<div class="triple-indent sub-category-row hover">{{ subSubCategory.Name }}</div>
										</cramo-link>
										<cramo-link [url]="subCategory.LinkUrl" (click)="hideMenu()">
											@if (subCategory.Children?.length > 0) {
												<div class="triple-indent sub-category-row hover show-all">
													{{ appData.product.showAll }}
												</div>
											}
										</cramo-link>
									</div>
								}
							</div>
							@if (category.Children?.length > 0) {
								<cramo-link [url]="category.LinkUrl" (click)="hideMenu()">
									<div class="double-indent sub-category-row hover show-all">{{ appData.product.showAll }}</div>
								</cramo-link>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="blur" [ngClass]="{ 'hide-menu': !showInnerMenu }" (click)="hideMenu()"></div>
</div>
