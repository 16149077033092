<cramo-page>
	<section>
		<cramo-icon iconName="check_circle" [size]="32" />
		<h1>
			{{
				isInvitesFlow
					? appData().digitalAccount.successPageHeaderInvites
					: appData().digitalAccount.successPageHeaderNoInvites
			}}
		</h1>
		<p
			[innerHTML]="
				isInvitesFlow
					? appData().digitalAccount.successPageTextInvites
					: appData().digitalAccount.successPageTextNoInvites
			"
		></p>
		<cramo-button
			text="{{ appData().digitalAccount.successPageBackToHome }}"
			buttonColor="primary"
			(onclick)="backToHome()"
			suffixIcon="arrow_forward"
		/>
	</section>
</cramo-page>
