@if (data) {
	<cramo-dialog [hideClose]="true" [title]="data.heading">
		<div modal-body>
			<p>{{ data.description }}</p>

			<div class="buttons">
				<button class="cramo-btn cramo-btn-link-2" (click)="close()">{{ data.closeButtonText }}</button>
			</div>
		</div>
	</cramo-dialog>
}
