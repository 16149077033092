<cramo-page title="{{ appData().digitalAccount.pageTitle }}">
	<div class="wrapper">
		<cramo-page-title>{{ appData().digitalAccount.pageTitle }}</cramo-page-title>
		<div class="content-container">
			@if (verificationResult()) {
				<cramo-button
					text="{{ appData().global.cancel }}"
					(onclick)="clearVerificationResult()"
					prefixIcon="arrow_backward"
					buttonStyle="basic"
					buttonColor="primary"
				/>
				<form [formGroup]="form">
					<mat-vertical-stepper #stepper linear>
						<ng-template matStepperIcon="edit">
							<mat-icon>done</mat-icon>
						</ng-template>
						<mat-step color="primary" label="{{ appData().digitalAccount.welcomeFormHeader }}">
							<div class="step">
								<h2>{{ appData().digitalAccount.welcomeFormHeader }} {{ verificationResult().givenName }}</h2>
								<p>{{ appData().digitalAccount.welcomeText }}</p>
							</div>
							<cramo-button
								class="proceed-button"
								type="button"
								text="{{ appData().global.continueText }}"
								buttonColor="primary"
								(onclick)="proceed()"
								suffixIcon="arrow_forward"
							/>
						</mat-step>

						<!-- CUSTOMER STEP -->
						<ng-template matStepperIcon="edit">
							<mat-icon>done</mat-icon>
						</ng-template>
						<mat-step
							color="primary"
							label="{{ appData().digitalAccount.customerFormHeader }}"
							[completed]="isCustomerStepCompleted()"
						>
							<div class="step">
								<p>{{ appData().digitalAccount.customerFormText }}</p>
								<cramo-customer-form
									[form]="form"
									[verificationResult]="verificationResult()"
									[isCompanyLegalStatusValid]="isCompanyLegalStatusValid"
								/>
							</div>
							<cramo-button
								class="proceed-button"
								type="button"
								text="{{ appData().global.continueText }}"
								buttonColor="primary"
								[isDisabled]="!isCompanyLegalStatusValid() || !getCustomerTypeIsValid()"
								[disabledTooltip]="getcustomerFormHeaderDisabledText()"
								(onclick)="proceed()"
								suffixIcon="arrow_forward"
							/>
						</mat-step>

						<!-- CONTACT EMAIL STEP -->
						<ng-template matStepperIcon="edit">
							<mat-icon>done</mat-icon>
						</ng-template>
						<mat-step
							color="primary"
							label="{{ appData().digitalAccount.contactFormHeader }}"
							[completed]="form.get('contact').valid"
						>
							<div class="step">
								<p>{{ appData().digitalAccount.contactFormText }}</p>
								<cramo-contact-form [form]="form" />
							</div>
							<cramo-button
								class="proceed-button"
								type="button"
								text="{{ appData().global.continueText }}"
								buttonColor="primary"
								(onclick)="proceed()"
								suffixIcon="arrow_forward"
							/>
						</mat-step>

						<!-- MAIN DIRECTION STEP -->
						@if (featureToggles().digitalAccount.isMainDirectionStepEnabled && form.get('type').value === 'Company') {
							<ng-template matStepperIcon="edit">
								<mat-icon>done</mat-icon>
							</ng-template>
							<mat-step
								color="primary"
								label="{{ appData().digitalAccount.mainDirectionFormHeader }}"
								[completed]="form.get('company.direction').valid"
							>
								<div class="step">
									<p>{{ appData().digitalAccount.mainDirectionFormText }}</p>
									<cramo-direction-form [form]="form" />
								</div>
								<cramo-button
									class="proceed-button"
									text="{{ appData().global.continueText }}"
									buttonColor="primary"
									(onclick)="proceed()"
									suffixIcon="arrow_forward"
								/>
							</mat-step>
						}

						<!-- MEMBERSHIP ORGANISATION STEP -->
						@if (
							form.get('type').value === 'Company' &&
							featureToggles().digitalAccount.isMembershipOrganisationStepEnabled
						) {
							<ng-template matStepperIcon="edit">
								<mat-icon>done</mat-icon>
							</ng-template>
							<mat-step
								color="primary"
								label="{{ appData().digitalAccount.membershipFormHeader }}"
								optional
								[completed]="this.form.get('currentStep').value > 3"
							>
								<div class="step">
									<p>{{ appData().digitalAccount.membershipFormText }}</p>
									<cramo-input
										type="text"
										label="{{ appData().digitalAccount.membershipLabel }}"
										[control]="form.get('company.membership')"
										hint="{{ appData().formElements?.fieldIsOptional }}"
									/>
									<cramo-button
										class="proceed-button"
										type="button"
										[text]="
											form.get('company.membership').value ? appData().global.continueText : appData().global.skip
										"
										buttonColor="primary"
										(onclick)="proceed()"
										[suffixIcon]="
											form.get('company.membership').value ? 'arrow_forward' : 'keyboard_double_arrow_right'
										"
									/>
								</div>
							</mat-step>
						}

						<!-- PRICE AGREEMENT -->
						@if (form.get('type').value === 'Company' && featureToggles().digitalAccount.isPriceAgreementStepEnabled) {
							<ng-template matStepperIcon="edit">
								<mat-icon>done</mat-icon>
							</ng-template>
							<mat-step
								color="primary"
								label="{{ appData().digitalAccount.priceAgreementFormHeader }}"
								[completed]="getPriceAgreementIsValid()"
							>
								<div class="step" [formGroup]="form">
									<p>{{ appData().digitalAccount.priceAgreementFormText }}</p>
									<div>
										<cramo-checkbox
											[control]="form.get('priceAgreement')"
											label="{{ appData().digitalAccount.priceAgreementLabel }}"
										/>
										@if (form.get('priceAgreement').value) {
											<cramo-input
												type="text"
												required
												label="{{ appData().digitalAccount.nameOfSalesContractContactLabel }}"
												[control]="form.get('salesContractContact')"
												[errorMessageMap]="{
													required: appData().formElements.fieldIsRequired,
													whitespace: appData().formElements.operatorIsInvalid,
												}"
											/>
										}
									</div>
									@if (
										form.get('priceAgreement').value &&
										form.get('salesContractContact')?.touched &&
										form.get('salesContractContact')?.hasError('required')
									) {
										<cramo-message-box
											[header]="appData().formElements.fieldIsRequired"
											[message]="appData().formElements.fieldIsRequired"
											level="error"
										/>
									}
									<cramo-button
										class="proceed-button"
										type="button"
										[text]="form.get('priceAgreement').value ? appData().global.continueText : appData().global.skip"
										buttonColor="primary"
										(onclick)="proceed('priceAgreement')"
										[suffixIcon]="form.get('priceAgreement').value ? 'arrow_forward' : 'keyboard_double_arrow_right'"
									/>
								</div>
							</mat-step>
						}

						<!-- PAYMENT METHOD STEP -->
						<ng-template matStepperIcon="edit">
							<mat-icon>done</mat-icon>
						</ng-template>
						<mat-step
							color="primary"
							label="{{ appData().digitalAccount.paymentFormHeader }}"
							[completed]="getPaymentIsValid()"
						>
							<div class="step">
								<p>{{ appData().digitalAccount.paymentFormText }}</p>
								<cramo-payment-method-form [form]="form" />
								<cramo-button
									class="proceed-button"
									type="button"
									text="{{ appData().global.continueText }}"
									buttonColor="primary"
									(onclick)="proceed('payment')"
									suffixIcon="arrow_forward"
								/>
							</div>
						</mat-step>

						<!-- SIGNATORY STEP -->
						@if (form.get('type').value === 'Company' && hasSignatureCombinations()) {
							<ng-template matStepperIcon="edit">
								<mat-icon>done</mat-icon>
							</ng-template>
							<mat-step
								color="primary"
								label="{{ appData().digitalAccount.signatoriesFormHeader }}"
								[completed]="
									form.get('company.selectedSignatoryGroup').value !== null && form.get('company.signatories').valid
								"
							>
								<div class="step">
									<p>{{ appData().digitalAccount.signatoriesFormText }}</p>
									<cramo-signatory-form [form]="form" />
									<cramo-button
										class="proceed-button"
										type="button"
										text="{{ appData().global.continueText }}"
										buttonColor="primary"
										(onclick)="proceed('signatories')"
										suffixIcon="arrow_forward"
									/>
								</div>
							</mat-step>
						}

						<!-- SUMMARY STEP -->
						<ng-template matStepperIcon="edit">
							<mat-icon>done</mat-icon>
						</ng-template>
						<mat-step color="primary" label="{{ appData().digitalAccount.summaryFormHeader }}">
							<div class="step">
								<cramo-summary-form [form]="form" />
								<cramo-button
									class="proceed-button"
									(onclick)="submitForm()"
									text="{{ appData().digitalAccount.submitApplication }}"
									buttonColor="primary"
									[isLoading]="submitIsLoading"
								/>
							</div>
						</mat-step>
					</mat-vertical-stepper>
				</form>
			}

			@if (!verificationResult()) {
				<section class="start-container">
					<article>
						<h5 class="content-header">{{ appData().digitalAccount.startHeader }}</h5>
						<p class="content-text">{{ appData().digitalAccount.startText }}</p>
					</article>
					<article>
						<h5 class="content-header">{{ appData().digitalAccount.verificationHeader }}</h5>
						<p class="content-text">{{ appData().digitalAccount.verificationText }}</p>
						<cramo-button
							[text]="appData().digitalAccount.verifyWithEidLinkText"
							(onclick)="verify()"
							buttonColor="primary"
						/>
					</article>
					@if (applyForLoginLink) {
						<article>
							<h5 class="content-header">{{ appData().digitalAccount.alreadyCustomerHeader }}</h5>
							<p class="content-text">{{ appData().digitalAccount.alreadyCustomerText }}</p>
							<cramo-button
								[text]="appData().digitalAccount.alreadyCustomerLinkText"
								buttonColor="primary"
								buttonStyle="stroked"
								[routerLink]="this.applyForLoginLink"
							/>
						</article>
					}
				</section>
			}
		</div>
	</div>
</cramo-page>
