import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { environment } from '../../environments/environment';
import { GtmService } from './gtm.service';

@Injectable({
	providedIn: 'root',
})
export class ScriptsService {
	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private gtmService: GtmService,
	) {}

	public injectScripts(renderer2: Renderer2) {
		if (environment.injectScripts) {
			this.injectGTM(renderer2);
		}
	}

	private injectGTM(renderer2: Renderer2) {
		const script = renderer2.createElement('script');
		script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-WR3WHB';
		script.async = true;
		this.gtmService.init();
		renderer2.appendChild(this._document.head, script);
	}
}
