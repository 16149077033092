import { Component } from '@angular/core';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { UserService } from '../../services/user/user.service';
import { SupportedLanguage, SupportedMarket } from '../../types/market.types';
import { User } from '../../types/user.types';

@Component({
	selector: 'cramo-no-available-customers-page',
	templateUrl: './no-available-customers-page.component.html',
	styleUrls: ['./no-available-customers-page.component.scss'],
})
export class NoAvailableCustomersPageComponent {
	public currentMarket: string;
	public viewData$: Observable<{ user: User; translations: NoAvailableCustomersTranslations }>;
	public marketOptions$: Observable<MarketOption[] | null>;

	private currentLanguage: SupportedLanguage;
	private user$: Observable<User>;

	constructor(
		private marketService: MarketService,
		private authService: AuthService,
		private epiService: EpiService,
		private userService: UserService,
	) {
		this.currentMarket = this.marketService.currentMarket;
		this.currentLanguage = this.marketService.currentLanguage;
		this.user$ = this.userService.user$;
		this.marketOptions$ = this.getUserCustomers();

		this.viewData$ = combineLatest({ user: this.user$, translations: this.getTranslations() });
	}

	public goToMarket(marketId: SupportedMarket) {
		this.marketService.updateMarket(marketId);
	}

	public logout() {
		this.authService.logout();
	}

	private getTranslations(): Observable<NoAvailableCustomersTranslations> {
		return this.epiService.appData$.pipe(
			switchMap((appData) =>
				combineLatest({
					header: of(appData?.onboarding?.noAvailableCustomersHeader),
					description: this.parseDescription(appData?.onboarding?.noAvailableCustomersDescription),
					defaultCustomerDescription: this.parseDefaultCustomerDescription(
						appData?.onboarding?.noAvailableCustomersDefaultCustomerDescription,
					),
					selectMarketDescription: of(appData?.onboarding?.noAvailableCustomersSelectMarketDescription),
					switchMarketButtonText: of(appData?.onboarding?.noAvailableCustomersConfirmSwitchMarketButtonText),
					signOutText: of(appData?.framework?.signOutText),
				}),
			),
		);
	}

	private parseDescription(description: string): Observable<string> {
		return this.marketService.marketLabels$.pipe(
			map((marketLabels: MarketLabels) => {
				const marketLabel = marketLabels[this.currentLanguage][this.currentMarket];
				return description.replace(/%current_market%/g, marketLabel);
			}),
		);
	}

	private parseDefaultCustomerDescription(description: string): Observable<string> {
		return combineLatest({
			user: this.user$,
			markets: this.marketService.markets$,
			marketLabels: this.marketService.marketLabels$,
		}).pipe(
			map(({ user, markets, marketLabels }) => {
				const customerName = user.CustomerInfo?.CompanyName;
				const defaultMarket = markets.find((market) => market?.ID === user.CustomerInfo?.MarketId);
				const marketLabel = marketLabels[this.currentLanguage][defaultMarket?.ID];
				const host = `cramo.${defaultMarket?.ID.toLowerCase()}`;

				return description
					.replace(/%customer_name%/g, customerName)
					.replace(/%default_market%/g, marketLabel)
					.replace(/%cramo.xx%/g, host);
			}),
		);
	}

	private getUserCustomers(): Observable<MarketOption[] | null> {
		return combineLatest({
			markets: this.marketService.markets$,
			userCustomers: this.userService.getUserCustomers(),
		}).pipe(
			map(({ markets, userCustomers }) => {
				return markets
					.filter((market) => market.ID !== this.currentMarket)
					.filter((market) => userCustomers.Customers.some((userCustomer) => userCustomer.MarketId === market.ID))
					.map((market) => {
						return {
							id: market.ID,
							flagImageUrl: `assets/icons/flags/${market.ID}.svg`,
							label: `cramo.${market.ID.toLocaleLowerCase()}`,
						};
					});
			}),
		);
	}
}

interface NoAvailableCustomersTranslations {
	header: string;
	description: string;
	selectMarketDescription: string;
	defaultCustomerDescription: string;
	switchMarketButtonText: string;
	signOutText: string;
}

interface MarketOption {
	flagImageUrl: string;
	label: string;
	id: SupportedMarket;
}

interface MarketLabels {
	[language: string]: {
		[market: string]: string;
	};
}
