import * as tslib_1 from "tslib";
import { Data, Envelope, RemoteDependencyData, Event, Exception, Metric, PageView, Trace, PageViewPerformance, CtxTagKeys, HttpMethod, SampleRate } from '@microsoft/applicationinsights-common';
import { CoreUtils, LoggingSeverity, _InternalMessageId, hasJSON, getJSON } from '@microsoft/applicationinsights-core-js';
// these two constants are used to filter out properties not needed when trying to extract custom properties and measurements from the incoming payload
var baseType = "baseType";
var baseData = "baseData";
var EnvelopeCreator = /** @class */function () {
  function EnvelopeCreator() {}
  EnvelopeCreator.extractPropsAndMeasurements = function (data, properties, measurements) {
    if (!CoreUtils.isNullOrUndefined(data)) {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {
          var value = data[key];
          if (CoreUtils.isNumber(value)) {
            measurements[key] = value;
          } else if (CoreUtils.isString(value)) {
            properties[key] = value;
          } else if (hasJSON()) {
            properties[key] = getJSON().stringify(value);
          }
        }
      }
    }
  };
  // TODO: Do we want this to take logger as arg or use this._logger as nonstatic?
  EnvelopeCreator.createEnvelope = function (logger, envelopeType, telemetryItem, data) {
    var envelope = new Envelope(logger, data, envelopeType);
    if (telemetryItem[SampleRate]) {
      envelope.sampleRate = telemetryItem[SampleRate];
    }
    envelope.iKey = telemetryItem.iKey;
    var iKeyNoDashes = telemetryItem.iKey.replace(/-/g, "");
    envelope.name = envelope.name.replace("{0}", iKeyNoDashes);
    // extract all extensions from ctx
    EnvelopeCreator.extractPartAExtensions(telemetryItem, envelope);
    // loop through the envelope tags (extension of Part A) and pick out the ones that should go in outgoing envelope tags
    if (!telemetryItem.tags) {
      telemetryItem.tags = [];
    }
    return envelope;
  };
  /*
   * Maps Part A data from CS 4.0
   */
  EnvelopeCreator.extractPartAExtensions = function (item, env) {
    // todo: switch to keys from common in this method
    if (!env.tags) {
      env.tags = {};
    }
    if (!item.ext) {
      item.ext = {};
    }
    if (!item.tags) {
      item.tags = [];
    }
    if (item.ext.user) {
      if (item.ext.user.authId) {
        env.tags[CtxTagKeys.userAuthUserId] = item.ext.user.authId;
      }
      var userId = item.ext.user.id || item.ext.user.localId;
      if (userId) {
        env.tags[CtxTagKeys.userId] = userId;
      }
    }
    if (item.ext.app) {
      if (item.ext.app.sesId) {
        env.tags[CtxTagKeys.sessionId] = item.ext.app.sesId;
      }
    }
    if (item.ext.device) {
      if (item.ext.device.id || item.ext.device.localId) {
        env.tags[CtxTagKeys.deviceId] = item.ext.device.id || item.ext.device.localId;
      }
      if (item.ext.device.deviceClass) {
        env.tags[CtxTagKeys.deviceType] = item.ext.device.deviceClass;
      }
      if (item.ext.device.ip) {
        env.tags[CtxTagKeys.deviceIp] = item.ext.device.ip;
      }
    }
    if (item.ext.web) {
      var web = item.ext.web;
      if (web.browserLang) {
        env.tags[CtxTagKeys.deviceLanguage] = web.browserLang;
      }
      if (web.browserVer) {
        env.tags[CtxTagKeys.deviceBrowserVersion] = web.browserVer;
      }
      if (web.browser) {
        env.tags[CtxTagKeys.deviceBrowser] = web.browser;
      }
      env.data = env.data || {};
      env.data.baseData = env.data.baseData || {};
      env.data.baseData.properties = env.data.baseData.properties || {};
      if (web.domain) {
        env.data.baseData.properties['domain'] = web.domain;
      }
      if (web.isManual) {
        env.data.baseData.properties['isManual'] = web.isManual.toString();
      }
      if (web.screenRes) {
        env.data.baseData.properties['screenRes'] = web.screenRes;
      }
      if (web.userConsent) {
        env.data.baseData.properties['userConsent'] = web.userConsent.toString();
      }
    }
    if (item.ext.device) {
      if (item.ext.device.model) {
        env.tags[CtxTagKeys.deviceModel] = item.ext.device.model;
      }
    }
    if (item.ext.os && item.ext.os.name) {
      env.tags[CtxTagKeys.deviceOS] = item.ext.os.name;
    }
    if (item.ext.device) {
      if (item.ext.device.deviceType) {
        env.tags[CtxTagKeys.deviceType] = item.ext.device.deviceType;
      }
    }
    // No support for mapping Trace.traceState to 2.0 as it is currently empty
    if (item.ext.trace) {
      if (item.ext.trace.parentID) {
        env.tags[CtxTagKeys.operationParentId] = item.ext.trace.parentID;
      }
      if (item.ext.trace.name) {
        env.tags[CtxTagKeys.operationName] = item.ext.trace.name;
      }
      if (item.ext.trace.traceID) {
        env.tags[CtxTagKeys.operationId] = item.ext.trace.traceID;
      }
    }
    // Sample 4.0 schema
    //  {
    //     "time" : "2018-09-05T22:51:22.4936Z",
    //     "name" : "MetricWithNamespace",
    //     "iKey" : "ABC-5a4cbd20-e601-4ef5-a3c6-5d6577e4398e",
    //     "ext": {  "cloud": {
    //          "role": "WATSON3",
    //          "roleInstance": "CO4AEAP00000260"
    //      },
    //      "device": {}, "correlation": {} },
    //      "tags": [
    //        { "amazon.region" : "east2" },
    //        { "os.expid" : "wp:02df239" }
    //     ]
    //   }
    var tgs = {};
    var _loop_1 = function (i) {
      var tg = item.tags[i];
      // CoreUtils.objKeys returns an array of keys
      CoreUtils.arrForEach(CoreUtils.objKeys(tg), function (key) {
        tgs[key] = tg[key];
      });
      item.tags.splice(i, 1);
    };
    // deals with tags.push({object})
    for (var i = item.tags.length - 1; i >= 0; i--) {
      _loop_1(i);
    }
    // deals with tags[key]=value
    for (var tg in item.tags) {
      tgs[tg] = item.tags[tg];
    }
    env.tags = tslib_1.__assign({}, env.tags, tgs);
    if (!env.tags[CtxTagKeys.internalSdkVersion]) {
      // Append a version in case it is not already set
      env.tags[CtxTagKeys.internalSdkVersion] = "javascript:" + EnvelopeCreator.Version;
    }
  };
  EnvelopeCreator.Version = "2.4.4";
  return EnvelopeCreator;
}();
export { EnvelopeCreator };
var DependencyEnvelopeCreator = /** @class */function (_super) {
  tslib_1.__extends(DependencyEnvelopeCreator, _super);
  function DependencyEnvelopeCreator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  DependencyEnvelopeCreator.prototype.Create = function (logger, telemetryItem) {
    this._logger = logger;
    if (CoreUtils.isNullOrUndefined(telemetryItem.baseData)) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryEnvelopeInvalid, "telemetryItem.baseData cannot be null.");
    }
    var customMeasurements = telemetryItem.baseData.measurements || {};
    var customProperties = telemetryItem.baseData.properties || {};
    EnvelopeCreator.extractPropsAndMeasurements(telemetryItem.data, customProperties, customMeasurements);
    var bd = telemetryItem.baseData;
    if (CoreUtils.isNullOrUndefined(bd)) {
      logger.warnToConsole("Invalid input for dependency data");
      return null;
    }
    var id = bd.id;
    var absoluteUrl = bd.target;
    var command = bd.name;
    var duration = bd.duration;
    var success = bd.success;
    var resultCode = bd.responseCode;
    var requestAPI = bd.type;
    var correlationContext = bd.correlationContext;
    var method = bd.properties && bd.properties[HttpMethod] ? bd.properties[HttpMethod] : "GET";
    var baseData = new RemoteDependencyData(logger, id, absoluteUrl, command, duration, success, resultCode, method, requestAPI, correlationContext, customProperties, customMeasurements);
    var data = new Data(RemoteDependencyData.dataType, baseData);
    return EnvelopeCreator.createEnvelope(logger, RemoteDependencyData.envelopeType, telemetryItem, data);
  };
  DependencyEnvelopeCreator.DependencyEnvelopeCreator = new DependencyEnvelopeCreator();
  return DependencyEnvelopeCreator;
}(EnvelopeCreator);
export { DependencyEnvelopeCreator };
var EventEnvelopeCreator = /** @class */function (_super) {
  tslib_1.__extends(EventEnvelopeCreator, _super);
  function EventEnvelopeCreator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  EventEnvelopeCreator.prototype.Create = function (logger, telemetryItem) {
    this._logger = logger;
    if (CoreUtils.isNullOrUndefined(telemetryItem.baseData)) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryEnvelopeInvalid, "telemetryItem.baseData cannot be null.");
    }
    var customProperties = {};
    var customMeasurements = {};
    if (telemetryItem.baseType !== Event.dataType) {
      customProperties['baseTypeSource'] = telemetryItem.baseType; // save the passed in base type as a property
    }
    if (telemetryItem.baseType === Event.dataType) {
      customProperties = telemetryItem.baseData.properties || {};
      customMeasurements = telemetryItem.baseData.measurements || {};
    } else {
      if (telemetryItem.baseData) {
        EnvelopeCreator.extractPropsAndMeasurements(telemetryItem.baseData, customProperties, customMeasurements);
      }
    }
    // Exract root level properties from part C telemetryItem.data
    EnvelopeCreator.extractPropsAndMeasurements(telemetryItem.data, customProperties, customMeasurements);
    var eventName = telemetryItem.baseData.name;
    var baseData = new Event(logger, eventName, customProperties, customMeasurements);
    var data = new Data(Event.dataType, baseData);
    return EnvelopeCreator.createEnvelope(logger, Event.envelopeType, telemetryItem, data);
  };
  EventEnvelopeCreator.EventEnvelopeCreator = new EventEnvelopeCreator();
  return EventEnvelopeCreator;
}(EnvelopeCreator);
export { EventEnvelopeCreator };
var ExceptionEnvelopeCreator = /** @class */function (_super) {
  tslib_1.__extends(ExceptionEnvelopeCreator, _super);
  function ExceptionEnvelopeCreator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  ExceptionEnvelopeCreator.prototype.Create = function (logger, telemetryItem) {
    this._logger = logger;
    if (CoreUtils.isNullOrUndefined(telemetryItem.baseData)) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryEnvelopeInvalid, "telemetryItem.baseData cannot be null.");
    }
    var bd = telemetryItem.baseData;
    var baseData = Exception.CreateFromInterface(logger, bd);
    var data = new Data(Exception.dataType, baseData);
    return EnvelopeCreator.createEnvelope(logger, Exception.envelopeType, telemetryItem, data);
  };
  ExceptionEnvelopeCreator.ExceptionEnvelopeCreator = new ExceptionEnvelopeCreator();
  return ExceptionEnvelopeCreator;
}(EnvelopeCreator);
export { ExceptionEnvelopeCreator };
var MetricEnvelopeCreator = /** @class */function (_super) {
  tslib_1.__extends(MetricEnvelopeCreator, _super);
  function MetricEnvelopeCreator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  MetricEnvelopeCreator.prototype.Create = function (logger, telemetryItem) {
    this._logger = logger;
    if (CoreUtils.isNullOrUndefined(telemetryItem.baseData)) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryEnvelopeInvalid, "telemetryItem.baseData cannot be null.");
    }
    var props = telemetryItem.baseData.properties || {};
    var measurements = telemetryItem.baseData.measurements || {};
    EnvelopeCreator.extractPropsAndMeasurements(telemetryItem.data, props, measurements);
    var name = telemetryItem.baseData.name;
    var average = telemetryItem.baseData.average;
    var sampleCount = telemetryItem.baseData.sampleCount;
    var min = telemetryItem.baseData.min;
    var max = telemetryItem.baseData.max;
    var baseData = new Metric(logger, name, average, sampleCount, min, max, props, measurements);
    var data = new Data(Metric.dataType, baseData);
    return EnvelopeCreator.createEnvelope(logger, Metric.envelopeType, telemetryItem, data);
  };
  MetricEnvelopeCreator.MetricEnvelopeCreator = new MetricEnvelopeCreator();
  return MetricEnvelopeCreator;
}(EnvelopeCreator);
export { MetricEnvelopeCreator };
var PageViewEnvelopeCreator = /** @class */function (_super) {
  tslib_1.__extends(PageViewEnvelopeCreator, _super);
  function PageViewEnvelopeCreator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  PageViewEnvelopeCreator.prototype.Create = function (logger, telemetryItem) {
    this._logger = logger;
    if (CoreUtils.isNullOrUndefined(telemetryItem.baseData)) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryEnvelopeInvalid, "telemetryItem.baseData cannot be null.");
    }
    // Since duration is not part of the domain properties in Common Schema, extract it from part C
    var duration;
    if (!CoreUtils.isNullOrUndefined(telemetryItem.baseData) && !CoreUtils.isNullOrUndefined(telemetryItem.baseData.properties) && !CoreUtils.isNullOrUndefined(telemetryItem.baseData.properties.duration)) {
      duration = telemetryItem.baseData.properties.duration;
      delete telemetryItem.baseData.properties.duration;
    } else if (!CoreUtils.isNullOrUndefined(telemetryItem.data) && !CoreUtils.isNullOrUndefined(telemetryItem.data["duration"])) {
      duration = telemetryItem.data["duration"];
      delete telemetryItem.data["duration"];
    }
    var bd = telemetryItem.baseData;
    // special case: pageview.id is grabbed from current operation id. Analytics plugin is decoupled from properties plugin, so this is done here instead. This can be made a default telemetry intializer instead if needed to be decoupled from channel
    var currentContextId;
    if (telemetryItem.ext && telemetryItem.ext.trace && telemetryItem.ext.trace.traceID) {
      currentContextId = telemetryItem.ext.trace.traceID;
    }
    var id = bd.id || currentContextId;
    var name = bd.name;
    var url = bd.uri;
    var properties = bd.properties || {};
    var measurements = bd.measurements || {};
    // refUri is a field that Breeze still does not recognize as part of Part B. For now, put it in Part C until it supports it as a domain property
    if (!CoreUtils.isNullOrUndefined(bd.refUri)) {
      properties["refUri"] = bd.refUri;
    }
    // pageType is a field that Breeze still does not recognize as part of Part B. For now, put it in Part C until it supports it as a domain property
    if (!CoreUtils.isNullOrUndefined(bd.pageType)) {
      properties["pageType"] = bd.pageType;
    }
    // isLoggedIn is a field that Breeze still does not recognize as part of Part B. For now, put it in Part C until it supports it as a domain property
    if (!CoreUtils.isNullOrUndefined(bd.isLoggedIn)) {
      properties["isLoggedIn"] = bd.isLoggedIn.toString();
    }
    // pageTags is a field that Breeze still does not recognize as part of Part B. For now, put it in Part C until it supports it as a domain property
    if (!CoreUtils.isNullOrUndefined(bd.properties)) {
      var pageTags = bd.properties;
      for (var key in pageTags) {
        if (pageTags.hasOwnProperty(key)) {
          properties[key] = pageTags[key];
        }
      }
    }
    EnvelopeCreator.extractPropsAndMeasurements(telemetryItem.data, properties, measurements);
    var baseData = new PageView(logger, name, url, duration, properties, measurements, id);
    var data = new Data(PageView.dataType, baseData);
    return EnvelopeCreator.createEnvelope(logger, PageView.envelopeType, telemetryItem, data);
  };
  PageViewEnvelopeCreator.PageViewEnvelopeCreator = new PageViewEnvelopeCreator();
  return PageViewEnvelopeCreator;
}(EnvelopeCreator);
export { PageViewEnvelopeCreator };
var PageViewPerformanceEnvelopeCreator = /** @class */function (_super) {
  tslib_1.__extends(PageViewPerformanceEnvelopeCreator, _super);
  function PageViewPerformanceEnvelopeCreator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  PageViewPerformanceEnvelopeCreator.prototype.Create = function (logger, telemetryItem) {
    this._logger = logger;
    if (CoreUtils.isNullOrUndefined(telemetryItem.baseData)) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryEnvelopeInvalid, "telemetryItem.baseData cannot be null.");
    }
    var bd = telemetryItem.baseData;
    var name = bd.name;
    var url = bd.uri || bd.url;
    var properties = bd.properties || {};
    var measurements = bd.measurements || {};
    EnvelopeCreator.extractPropsAndMeasurements(telemetryItem.data, properties, measurements);
    var baseData = new PageViewPerformance(logger, name, url, undefined, properties, measurements, bd);
    var data = new Data(PageViewPerformance.dataType, baseData);
    return EnvelopeCreator.createEnvelope(logger, PageViewPerformance.envelopeType, telemetryItem, data);
  };
  PageViewPerformanceEnvelopeCreator.PageViewPerformanceEnvelopeCreator = new PageViewPerformanceEnvelopeCreator();
  return PageViewPerformanceEnvelopeCreator;
}(EnvelopeCreator);
export { PageViewPerformanceEnvelopeCreator };
var TraceEnvelopeCreator = /** @class */function (_super) {
  tslib_1.__extends(TraceEnvelopeCreator, _super);
  function TraceEnvelopeCreator() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TraceEnvelopeCreator.prototype.Create = function (logger, telemetryItem) {
    this._logger = logger;
    if (CoreUtils.isNullOrUndefined(telemetryItem.baseData)) {
      this._logger.throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryEnvelopeInvalid, "telemetryItem.baseData cannot be null.");
    }
    var message = telemetryItem.baseData.message;
    var severityLevel = telemetryItem.baseData.severityLevel;
    var props = telemetryItem.baseData.properties || {};
    var measurements = telemetryItem.baseData.measurements || {};
    EnvelopeCreator.extractPropsAndMeasurements(telemetryItem.data, props, measurements);
    var baseData = new Trace(logger, message, severityLevel, props, measurements);
    var data = new Data(Trace.dataType, baseData);
    return EnvelopeCreator.createEnvelope(logger, Trace.envelopeType, telemetryItem, data);
  };
  TraceEnvelopeCreator.TraceEnvelopeCreator = new TraceEnvelopeCreator();
  return TraceEnvelopeCreator;
}(EnvelopeCreator);
export { TraceEnvelopeCreator };
