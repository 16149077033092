<cramo-page>
	<div class="page-wrapper" *ngIf="!isSearching">
		<div class="page-margin my-4">
			<div class="mb-3">
				<span class="product-title">{{ appData().search?.productResultLabel }}</span>
				@if (searchResults && searchResults?.ProductContentSearchResult.length > 0) {
					<span>({{ searchResults?.ProductContentSearchResult.length }})</span>
				} @else {
					<span>{{ appData().search?.noResultFoundText }}</span>
				}
			</div>

			<cramo-products *ngIf="searchResults" [products]="searchResults.ProductContentSearchResult" [wide]="true" />

			<hr />

			<div class="mb-3">
				<span class="content-title">{{ appData().search?.contentResultLabel }}</span>
				<span *ngIf="searchResults">({{ searchResults?.ContentSearchResult.length }})</span>
			</div>

			<div *ngIf="searchResults">
				<div
					class="content-box frow-between-stretch"
					*ngFor="let content of searchResults?.ContentSearchResult | limit: contentLimit"
				>
					<div class="frow-start-center">
						<span class="content-header">{{ content.Header }}</span>
					</div>
					<div class="frow-end-stretch">
						<cramo-link [url]="content.LinkUrl">
							<span class="link-text">{{ appData().search.goToPageLabel }}</span>
							<button class="link-btn">
								<cramo-icon iconName="arrow_forward" iconDisplay="inline" />
							</button>
						</cramo-link>
					</div>
				</div>
			</div>

			<div
				class="show-more-results"
				*ngIf="searchResults?.ContentSearchResult.length && searchResults?.ContentSearchResult.length > contentLimit"
				(click)="showMoreContentPages()"
			>
				{{ appData().search.showMoreContentPagesLabel }}
			</div>
		</div>
	</div>

	<cramo-loading-area *ngIf="isSearching" [showTransparent]="true" [title]="searchProgressText" />
</cramo-page>
