import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { UserService } from '../services/user/user.service';

@Injectable({
	providedIn: 'root',
})
export class LoggedOutAuthGuard {
	constructor(
		private router: Router,
		private userService: UserService,
	) {}

	// This guard is specifically designed to protect routes that should not be
	// accessible by logged-in users. It is intended to be used only for routes
	// where it is not okay to access if a user is logged in. In this particular
	// implementation, the guard reacts to user updates and dynamically changes
	// route access based on the user's authentication state.
	//
	// This guard deviates from the typical usage of Angular route guards.
	// Normally, route guards are used to determine access based on the current
	// state of the application and do not need to subscribe to observables
	// within the canActivate method.
	//
	// However, in this specific case, the guard needs to react to user updates
	// and dynamically change route access. The router does not automatically
	// resubscribe to the observable every time the user updates, so subscribing
	// to the observable within the canActivate method allows the guard to react
	// to user updates correctly.
	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const observable = this.userService.isLoggedIn$.pipe(
			map((isAuthenticated) => {
				// If the user is logged in, navigate to the root route and
				// return false, indicating that access to the route is denied.
				if (isAuthenticated) {
					this.router.navigate(['/']);
					return false;
				}

				// If the user is not logged in, return true, granting access to the route.
				return true;
			}),
		);

		// Subscribe to the observable, which is a deviation from typical route guard usage,
		// but necessary in this specific case to react to user updates correctly.
		observable.subscribe();

		return observable;
	}
}
