import { ContextTagKeys } from "./Contracts/Generated/ContextTagKeys";
var Extensions = /** @class */function () {
  function Extensions() {}
  Extensions.UserExt = "user";
  Extensions.DeviceExt = "device";
  Extensions.TraceExt = "trace";
  Extensions.WebExt = "web";
  Extensions.AppExt = "app";
  Extensions.OSExt = "os";
  Extensions.SessionExt = "ses";
  Extensions.SDKExt = "sdk";
  return Extensions;
}();
export { Extensions };
export var CtxTagKeys = new ContextTagKeys();
