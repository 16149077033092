import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MarketService } from '../services/market.service';
import { isSupportedLanguage } from '../types/market.types';
import { isDotComDomain } from '../utils/location';

@Injectable()
export class RoutePathGuard {
	constructor(
		private router: Router,
		private marketService: MarketService,
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if (isDotComDomain()) return true;

		if (state.url === '/') {
			this.router.navigate([this.marketService.currentLanguage]);
		} else if (!isSupportedLanguage(route.params.lang)) {
			// If the current route's language parameter is not valid, prepend the language
			// parameter for the current market before the original path. This transforms
			// the URL to include the appropriate language parameter.
			// Example: cramo.se/whatever -> cramo.se/sv/whatever (when the current market's language is 'sv')
			const path = `/${this.marketService.marketLanguageMap[this.marketService.currentMarket]}${state.url}`;
			this.router.navigateByUrl(path);
		}
	}
}
