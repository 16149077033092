import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
	selector: 'cramo-error-popup',
	standalone: true,
	imports: [DialogComponent],
	templateUrl: './error-popup.component.html',
	styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent {
	constructor(
		private dialogRef: MatDialogRef<ErrorPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ErrorPopupInformation,
	) {}

	public close() {
		this.dialogRef.close();
	}
}

export interface ErrorPopupInformation {
	heading: string;
	description: string;
	closeButtonText: string;
}
