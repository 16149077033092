<div
	class="menu-header-container frow-center-stretch"
	*ngIf="isMenuMenuVisible()"
	[cramoClickOutside]="isMenuMenuVisible()"
	data-test-id="menu-mobile"
>
	<div class="menu-overlay" (click)="hideMenu()"></div>
	<div class="menu-inner">
		<div class="top-level-links">
			<!-- link to depot -->
			<cramo-link
				[url]="'/' + currentLanguage + '/depot'"
				(click)="hideMenu()"
				data-test-id="menu-mobile-button-depot"
				class="menu-mobile-button-depot"
			>
				<cramo-icon iconName="location_on" iconStyle="filled" iconDisplay="inline" />{{ appData.product.locateDepot }}
			</cramo-link>

			<!-- link to contact -->
			<cramo-flip-panel [minWidth]="250" [alignLeft]="false" (click)="hideMenu()">
				<button trigger data-test-id="menu-mobile-button-contact">
					<cramo-icon iconName="smartphone" />{{ appData.product.contactUs }}
				</button>
				<cramo-contact-us body />
			</cramo-flip-panel>
		</div>

		<!-- First level -->
		<div
			class="menu-header-item"
			*ngFor="let menuItem of updatedMenu; let i = index"
			[ngClass]="{ 'is-last': i === updatedMenu.length - 1 && isLoggedIn() }"
		>
			<div
				class="menu-header-item-text"
				attr.data-test-id="menu-mobile-lvl1-item-{{ i + 1 }}"
				(click)="toggleTopLevel(menuItem)"
			>
				<div class="menu-header-litem-inner">
					@if (openMainTab?.Name !== menuItem?.Name && menuItem?.Children.length > 0) {
						<div class="expand-icon">
							<cramo-icon iconName="add" [size]="16" />
						</div>
					}

					@if (openMainTab?.Name === menuItem?.Name && menuItem?.Children.length > 0) {
						<div class="collapse-icon">
							<cramo-icon iconName="remove" [size]="16" />
						</div>
					}

					<span>{{ menuItem.Name }}</span>
				</div>
			</div>

			<!-- Only open active main -->
			@if (openMainTab?.Name === menuItem.Name) {
				<div class="first-category-chunk single-indent" data-test-id="menu-mobile-lvl2">
					<!-- Second level -->
					<div
						class="menu-cell"
						*ngFor="let category of openMainTab.Children; let y = index"
						attr.data-test-id="menu-mobile-lvl2-item-{{ y + 1 }}"
					>
						<div class="menu-category-mobile" (click)="onMainMenuClick(category)">
							<div class="frow-start-center">
								@if (category.Children.length > 0) {
									@if (openSecondTabs[category.Name]) {
										<div class="collapse-icon">
											<cramo-icon iconName="remove" [size]="16" />
										</div>
									} @else {
										<div class="expand-icon">
											<cramo-icon iconName="add" [size]="16" />
										</div>
									}
									<span>{{ category.Name }}</span>
								} @else {
									<cramo-link [url]="category.LinkUrl" (click)="hideMenu()">
										<span>{{ category.Name }}</span>
									</cramo-link>
								}
							</div>
						</div>

						<!-- Third level -->
						@if (openSecondTabs[category.Name]) {
							<div>
								<div *ngFor="let subCategory of category?.Children">
									<!-- Show children if any -->
									@if (subCategory.Children && subCategory.Children.length > 0) {
										<div>
											<div
												class="category-row cursor sub-category-row single-indent frow-start-center"
												(click)="onMenuClick(subCategory)"
											>
												@if (openTabs[subCategory.Name]) {
													<div class="collapse-icon">
														<cramo-icon iconName="remove" [size]="16" />
													</div>
												} @else {
													<div class="expand-icon">
														<cramo-icon iconName="add" [size]="16" />
													</div>
												}

												<span class="sub-category hover" [ngClass]="{ 'expanded-text': openTabs[subCategory.Name] }">
													{{ subCategory.Name }}
												</span>
											</div>
										</div>
									}

									<!-- If no children show link instead -->
									@if (!subCategory.Children || subCategory.Children.length === 0) {
										<div class="sub-category-row">
											<cramo-link [url]="subCategory.LinkUrl" (click)="hideMenu()">
												<div class="sub-category double-indent hover cursor">{{ subCategory.Name }}</div>
											</cramo-link>
										</div>
									}

									<!-- Fourth level -->
									@if (openTabs[subCategory.Name]) {
										<div>
											<cramo-link
												*ngFor="let subSubCategory of subCategory.Children"
												[url]="subSubCategory.LinkUrl"
												(click)="hideMenu()"
												class="sub-category-row"
											>
												<div class="sub-sub-category triple-indent sub-category-row hover">
													{{ subSubCategory.Name }}
												</div>
											</cramo-link>
											<cramo-link [url]="subCategory.LinkUrl" (click)="hideMenu()">
												@if (subCategory.Children?.length > 0) {
													<span class="show-all sub-sub-category triple-indent">{{ appData.product.showAll }} </span>
												}
											</cramo-link>
										</div>
									}
								</div>

								<!-- Link to category page -->
								@if (category.Children?.length > 0) {
									<cramo-link [url]="category.LinkUrl" (click)="hideMenu()">
										<span class="show-all sub-sub-category cursor double-indent">{{ appData.product.showAll }} </span>
									</cramo-link>
								}
							</div>
						}
					</div>
				</div>
			}
		</div>
	</div>
</div>
