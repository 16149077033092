import { CoreUtils, EventHelper, getWindow, getDocument, getNavigator } from '@microsoft/applicationinsights-core-js';
/**
 * @description Monitors browser for offline events
 * @export default - Offline: Static instance of OfflineListener
 * @class OfflineListener
 */
var OfflineListener = /** @class */function () {
  function OfflineListener() {
    this._onlineStatus = true;
    var _window = getWindow();
    var _document = getDocument();
    var isListening = false;
    var _this = this;
    try {
      if (_window) {
        if (EventHelper.Attach(_window, 'online', this._setOnline.bind(_this))) {
          EventHelper.Attach(_window, 'offline', this._setOffline.bind(_this));
          isListening = true;
        }
      }
      if (_document) {
        // Also attach to the document.body or document
        var target = _document.body || _document;
        if (!CoreUtils.isUndefined(target.ononline)) {
          target.ononline = this._setOnline.bind(_this);
          target.onoffline = this._setOffline.bind(_this);
          isListening = true;
        }
      }
    } catch (e) {
      // this makes react-native less angry
      isListening = false;
    }
    this.isListening = isListening;
  }
  OfflineListener.prototype.isOnline = function () {
    var _navigator = getNavigator();
    if (this.isListening) {
      return this._onlineStatus;
    } else if (_navigator && !CoreUtils.isNullOrUndefined(_navigator.onLine)) {
      return _navigator.onLine;
    } else {
      // Cannot determine online status - report as online
      return true;
    }
  };
  OfflineListener.prototype.isOffline = function () {
    return !this.isOnline();
  };
  OfflineListener.prototype._setOnline = function () {
    this._onlineStatus = true;
  };
  OfflineListener.prototype._setOffline = function () {
    this._onlineStatus = false;
  };
  OfflineListener.Offline = new OfflineListener();
  return OfflineListener;
}();
export { OfflineListener };
export var Offline = OfflineListener.Offline;
