import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of, switchMap, tap } from 'rxjs';
import { MarketService } from '../services/market.service';
import { PunchoutService } from '../services/punchout.service';
import { UserService } from '../services/user/user.service';

export const authGuard: CanActivateFn = () => {
	const punchoutService = inject(PunchoutService);
	const userService = inject(UserService);
	const marketService = inject(MarketService);
	const router = inject(Router);

	return punchoutService.isPunchout$.pipe(
		switchMap((isPunchout) => {
			if (isPunchout) {
				return of(true);
			}
			return userService.isLoggedIn$;
		}),
		tap((isUserAuthenticated) => {
			if (!isUserAuthenticated) {
				router.navigateByUrl(`/${marketService.currentLanguage}`);
			}
		}),
	);
};
