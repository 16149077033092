import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, take } from 'rxjs';
import { Customer } from '../types/user.types';
import { isDotComDomain } from '../utils/location';
import { DepotService } from './depot/depot.service';
import { GtmService } from './gtm.service';
import { MarketService } from './market.service';
import { UserService } from './user/user.service';

export type ViewState =
	| 'loading'
	| 'landingPage'
	| 'noAvailableCustomers'
	| 'confirmMarketSwitch'
	| 'onboarding'
	| 'marketSelector';

@Injectable({
	providedIn: 'root',
})
export class ViewPickerService {
	private viewState = new BehaviorSubject<ViewState>('loading');
	public currentViewState$ = this.viewState.asObservable();

	constructor(
		private userService: UserService,
		private marketService: MarketService,
		private depotService: DepotService,
		private gtmService: GtmService,
	) {
		if (isDotComDomain()) {
			this.setView('marketSelector');
		} else {
			this.userService.isLoggedIn$.subscribe((isUserAuthenticated) => {
				if (isUserAuthenticated) {
					this.userService.user$.pipe(take(1)).subscribe(() => {
						this.determineInitialView();
					});
				} else {
					this.setView('landingPage');
				}
			});
		}
	}

	public setView(view: ViewState): void {
		this.viewState.next(view);
	}

	private determineInitialView(): void {
		combineLatest({
			isCustomerSelected: this.isCustomerSelected(),
			isCustomerInCurrentMarket: this.isCustomerInCurrentMarket(),
			isDepotInCurrentMarket: this.isDepotInCurrentMarket(),
		})
			.pipe(take(1))
			.subscribe({
				next: ({ isCustomerSelected, isCustomerInCurrentMarket, isDepotInCurrentMarket }) => {
					if (isCustomerSelected && isCustomerInCurrentMarket && isDepotInCurrentMarket) {
						return this.setView('landingPage');
					}

					this.hasNoAvailableCustomersOnCurrentMarket()
						.pipe(take(1))
						.subscribe((hasNoAvailableCustomersOnCurrentMarket) => {
							if (hasNoAvailableCustomersOnCurrentMarket) {
								return this.setView('noAvailableCustomers');
							}

							if (isCustomerSelected && !isCustomerInCurrentMarket) {
								return this.setView('confirmMarketSwitch');
							}

							this.setView('onboarding');
							this.sendOnboardingReason({
								hasNoAvailableCustomersOnCurrentMarket,
								isCustomerSelected,
								isDepotInCurrentMarket,
							});
						});
				},
				error: (error) => console.info(error),
			});
	}

	private hasNoAvailableCustomersOnCurrentMarket(): Observable<boolean> {
		const { currentMarket } = this.marketService;
		return this.userService.getUserCustomers().pipe(
			map(({ Customers }) => this.filterCustomersByMarketId(Customers, currentMarket)),
			map((customersInCurrentMarket) => this.hasNoCustomers(customersInCurrentMarket)),
		);
	}

	private filterCustomersByMarketId(customers: Customer[], marketId: string): Customer[] {
		return customers.filter((customer) => customer.MarketId === marketId);
	}

	private hasNoCustomers(customers: Customer[]): boolean {
		return customers.length === 0;
	}

	private isCustomerSelected(): Observable<boolean> {
		return this.userService.user$.pipe(map((user) => Boolean(user.CustomerInfo)));
	}

	private isCustomerInCurrentMarket(): Observable<boolean> {
		const { currentMarket } = this.marketService;
		return this.userService.user$.pipe(map(({ CustomerInfo }) => CustomerInfo?.MarketId === currentMarket));
	}

	private isDepotInCurrentMarket(): Observable<boolean> {
		return this.depotService.currentDepot$.pipe(map((currentDepot) => Boolean(currentDepot)));
	}

	private sendOnboardingReason({
		hasNoAvailableCustomersOnCurrentMarket,
		isCustomerSelected,
		isDepotInCurrentMarket,
	}: OnboardingReasons) {
		if (!isCustomerSelected) {
			this.gtmService.sendOnboardingEvent('onboardingStarted', '', 'MissingDefaultCustomer');
		}
		if (!hasNoAvailableCustomersOnCurrentMarket) {
			this.gtmService.sendOnboardingEvent('onboardingStarted', '', 'DefaultCustomerOtherMarket');
		}
		if (!isDepotInCurrentMarket) {
			this.gtmService.sendOnboardingEvent('onboardingStarted', '', 'MissingDefaultDepot');
		}
	}
}

interface OnboardingReasons {
	hasNoAvailableCustomersOnCurrentMarket: boolean;
	isCustomerSelected: boolean;
	isDepotInCurrentMarket: boolean;
}
