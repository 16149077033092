<div
	class="search-menu-container page-wrapper fcolumn-start-stretch"
	[cramoClickOutside]="searchString.length > 1"
	(byOutsideClicked)="close()"
	data-test-id="header-search-container"
>
	<div class="search-container" (keyup.enter)="goToSearchPage($event, searchFor.value)" (keyup.esc)="closeAndReset()">
		<input
			type="search"
			spellcheck="false"
			id="search-input-1"
			autocomplete="no"
			class="search-input"
			#searchFor
			[(ngModel)]="searchString"
			(ngModelChange)="searchChanged($event)"
			data-test-id="header-search-input"
		/>
		<button *ngIf="showSearchDropdown" class="close-icon-container" (click)="closeAndReset()">
			<cramo-icon iconName="close" [size]="20" />
		</button>
		<button
			class="search-icon-container"
			(keyup.enter)="goToSearchPage($event, searchFor.value)"
			(click)="goToSearchPage($event, searchFor.value)"
			[disabled]="searchString.length < 2"
			data-test-id="header-search-button"
		>
			<cramo-icon iconName="search" [size]="20" />
		</button>
	</div>
	<div
		class="search-dropdown fcolumn-start-stretch"
		*ngIf="showSearchDropdown"
		data-testid="header-search-result-container"
	>
		<div class="search-result-container">
			<div *ngIf="searchResults?.ProductContentSearchResult.length > 0 && !isSearching">
				<div class="search-result-type-header" tabindex="-1">{{ appData().search.productResultLabel }}</div>
				<div
					class="search-result-row"
					data-testid="header-search-row-product"
					*ngFor="let product of searchResults?.ProductContentSearchResult"
				>
					<a [routerLink]="product.LinkUrl" (click)="registerAndcloseAndReset(searchFor.value, product.LinkUrl)">
						<div class="frow-start-center">
							<img class="product-image" [src]="product.ImageUrl" alt="Product image" />
							<span>{{ product.Description }}</span>
						</div>
					</a>
				</div>
				<!-- TODO: Click area should be bigger -->
				<div class="search-result-row" *ngIf="searchResults?.ProductContentSearchResult.length > 0 && !isSearching">
					<a href="#" (click)="goToSearchPage($event, searchFor.value)" data-test-id="header-search-show-more">
						<span>{{ appData().search.showMoreProductsLabel }}</span>
					</a>
				</div>
			</div>
			<div *ngIf="searchResults?.ContentSearchResult.length > 0 && !isSearching">
				<div class="search-result-type-header" tabindex="-1">{{ appData().search.contentResultLabel }}</div>
				<div class="search-result-row" *ngFor="let content of searchResults?.ContentSearchResult">
					<cramo-link [url]="content.LinkUrl" (click)="registerAndcloseAndReset(searchFor.value, content.LinkUrl)">
						<span>{{ content.Header }}</span>
					</cramo-link>
				</div>
			</div>
			<div *ngIf="isSearching" class="loader-container">
				<cramo-loading-area [showTransparent]="true" [title]="appData().search.searchProgressText" />
			</div>
			<div class="search-result-row no-result" tabindex="-1" *ngIf="emptyResult">
				<span>{{ appData().search.noResultFoundText }}</span>
			</div>
		</div>
	</div>
</div>
