<form [formGroup]="form" [ngClass]="{ disabled: isLoading() }">
	<div class="input-fields">
		<cramo-input
			[label]="appData().onboarding.jobsiteNameHeader"
			type="text"
			[control]="form.controls.name"
			[maxLengthHint]="50"
			[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
			data-testid="create-jobsite-form-name"
		/>
		@if (isJobsiteExistsMessageShown) {
			<cramo-message-box
				header="{{ appData().jobsites.existsHeader }}"
				message="{{ existingJobsiteName + ' ' + appData().jobsites.existsMessage }}"
				level="info"
				data-testid="create-jobsite-exists-message"
			/>
		}

		<cramo-input
			[label]="appData().onboarding.invoiceReferenceHeader"
			type="text"
			[control]="form.controls.reference"
		/>
		<cramo-input
			type="text"
			[label]="appData().onboarding.jobsiteAddressHeader"
			[control]="form.controls.address.controls.streetAddress"
			[maxLengthHint]="50"
			[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
			data-testid="create-jobsite-form-street-address"
		/>
		<cramo-input
			[label]="appData().onboarding.jobsiteZipcodeHeader"
			type="text"
			[control]="form.controls.address.controls.postalCode"
			[maxLengthHint]="5"
			[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
			data-testid="create-jobsite-form-postalcode"
		/>
		<cramo-input
			autocomplete
			type="text"
			[label]="appData().onboarding.jobsiteCityHeader"
			[control]="form.controls.address.controls.city"
			[maxLengthHint]="50"
			[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
			data-testid="create-jobsite-form-city"
		/>
	</div>
	<cramo-button
		[disabledTooltip]="appData().onboarding.createJobsiteButtonDisabledText"
		[isDisabled]="!this.form.valid"
		[isLoading]="isLoading()"
		[text]="appData().onboarding.jobsiteCompleteButtonText"
		[innerId]="'create-jobsite-button'"
		(onclick)="addJobsite()"
		buttonColor="primary"
		suffixIcon="arrow_forward"
	/>
</form>
