<cramo-page-old>
	<div class="account-page-container">
		<div class="user-page-wrapper maxw-840">
			<div class="page-padding">
				<div class="account-container">
					<h1>{{ textLabels.title }}</h1>
					<div class="account-section">
						<div class="account-box">
							<div class="account-section__header">
								<p class="account-section__desc">{{ textLabels.pageDescription }}</p>
							</div>
							<div class="account-section-content">
								<form [formGroup]="phoneForm">
									<label for="phone">{{ textLabels.tel }}</label>
									<input
										class="form-control"
										formControlName="phone"
										name="phone"
										type="text"
										inputmode="numeric"
										[ngClass]="{ 'is-invalid': !phoneForm.valid }"
										cramoAlphanumeric
									/>
									<small *ngIf="!phoneForm.valid" class="form-text text-is-warning">{{
										textLabels.phoneFormatExample
									}}</small>
									<div class="mt-3">
										<button
											(click)="onSubmit()"
											class="cramo-btn cramo-btn-primary"
											[disabled]="!phoneForm.valid || isLoading"
										>
											{{ textLabels.save }}
										</button>
										<button (click)="cancel($event)" class="cramo-btn cramo-btn-link mr-2">
											{{ textLabels.cancel }}
										</button>
									</div>
								</form>
								<cramo-loading-area *ngIf="isLoading" [showTransparent]="true" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</cramo-page-old>
