import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { tap } from 'rxjs';
import { MarketService } from '../services/market.service';
import { UserService } from '../services/user/user.service';
import { UserPermission } from '../types/user.types';

/**
 * This guard can be used to determine if the current user has any of the required permissions before activating a route.
 * It leverages the `userService.hasPermission` method to perform the actual permission check.
 *
 * @param {...UserPermission[]} permissionsToCheck - A list of permissions to check against the current user's roles.
 * @returns {CanActivateFn} A route guard function that can be used with Angular's router to conditionally allow or prevent route activation based on the user's permissions.
 *
 * @example
 * // Usage in a routing module
 * const routes: Routes = [
 *   {
 *     path: 'some-path',
 *     canActivate: [permissionGuard('RENT', 'RENT_PICKUP')]
 *   }
 * ];
 *
 * @see {@link UserService#hasPermission} for details on the permission checking logic and its usage examples.
 */
export function permissionGuard(...permissionsToCheck: UserPermission[]): CanActivateFn {
	return () => {
		const userService = inject(UserService);
		const marketService = inject(MarketService);
		const router = inject(Router);

		return userService.hasPermission(...permissionsToCheck).pipe(
			tap((hasPermission) => {
				if (hasPermission) return;
				router.navigateByUrl(`/${marketService.currentLanguage}`);
			}),
		);
	};
}
