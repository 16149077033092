import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
	isValidFinnishCompanyId,
	isValidNorwegianCompanyId,
	isValidSwedishCompanyId,
} from '../utils/validate-company-id';
import { MarketService } from './market.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyIdValidationService {
	constructor(private marketService: MarketService) {}

	public companyIdValidator(): ValidatorFn {
		return (control: AbstractControl<string>): { [key: string]: unknown } | null => {
			const sanitizedValue = control.value.trim().replace(/[\D\s]/g, '');
			const valid = this.isValidCompanyId(sanitizedValue);
			return valid ? null : { invalidCompanyId: { value: sanitizedValue } };
		};
	}

	public isValidCompanyId(companyId: string): boolean {
		switch (this.marketService.currentMarket) {
			case 'NO':
				return isValidNorwegianCompanyId(companyId);
			case 'SE':
				return isValidSwedishCompanyId(companyId);
			case 'FI':
				return isValidFinnishCompanyId(companyId);
			default:
				return false;
		}
	}
}
