import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, of, switchMap, tap } from 'rxjs';
import { InputComponent } from '../../../../components/input/input.component';
import { MessageBoxComponent } from '../../../../components/message-box/message-box.component';
import { DigitalAccountApplicationService } from '../../../../services/digital-account-application.service';
import { EpiService } from '../../../../services/epi/epi.service';
import { VerificationResult } from '../../../../types/auth.types';
import { AppData } from '../../../../types/translations.types';
import { CompanyInfo, DigitalAccountApplicationForm } from '../../types/digital-account-application.types';

@Component({
	selector: 'cramo-customer-form',
	standalone: true,
	imports: [CommonModule, InputComponent, MatStepperModule, MatRadioModule, MessageBoxComponent, ReactiveFormsModule],
	templateUrl: './customer-form.component.html',
	styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {
	@Input() public form: FormGroup<DigitalAccountApplicationForm>;
	@Input() public verificationResult: VerificationResult;
	@Input() public isCompanyLegalStatusValid: Signal<boolean>;

	public companyIsLoading: Signal<boolean>;
	public companyError: Signal<boolean>;
	public registrationLink: string;
	public appData: Signal<AppData>;

	constructor(
		private epiService: EpiService,
		private digitalAccountApplicationService: DigitalAccountApplicationService,
		private destroyRef: DestroyRef,
	) {
		this.registrationLink = this.epiService.getRegistrationLinkUrl();
		this.appData = toSignal(this.epiService.appData$);
		this.companyIsLoading = this.digitalAccountApplicationService.companyIsLoading;
		this.companyError = this.digitalAccountApplicationService.companyError;
	}

	ngOnInit(): void {
		// strip non-numeric characters from postalCode input
		this.form
			.get('individual.address.postalCode')
			.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				const strippedValue = value?.replace(/\D/g, '') || '';
				if (value !== strippedValue) {
					this.form.get('individual.address.postalCode').setValue(strippedValue, { emitEvent: false });
				}
			});
		this.form
			.get('company.address.postalCode')
			.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				const strippedValue = value?.replace(/\D/g, '') || '';
				if (value !== strippedValue) {
					this.form.get('company.address.postalCode').setValue(strippedValue, { emitEvent: false });
				}
			});

		this.digitalAccountApplicationService
			.individualLookup(this.verificationResult.nationalId)
			.subscribe((bool) => this.form.patchValue({ individual: { isCustomer: bool } }));

		// company lookup
		this.form
			.get('company.id')
			.valueChanges.pipe(
				takeUntilDestroyed(this.destroyRef),
				distinctUntilChanged(),
				tap(() => {
					const shouldLoaderShow = this.form.get('company.id').valid;
					this.digitalAccountApplicationService.companyIsLoading.set(shouldLoaderShow);
				}),
				debounceTime(500),
				filter(() => {
					const shouldLoaderStillShow = this.form.get('company.id').valid;
					this.digitalAccountApplicationService.companyError.set(false);
					this.digitalAccountApplicationService.companyIsLoading.set(shouldLoaderStillShow);
					return shouldLoaderStillShow;
				}),
				switchMap((companyId) => this.companyLookup(companyId)),
			)
			.subscribe((response: CompanyInfo) => {
				if (!response) return;
				this.digitalAccountApplicationService.companyIsLoading.set(false);

				this.form.patchValue({
					company: {
						name: response.Name,
						id: response.CompanyNumber,
						vatId: response.CompanyVatNumber,
						isCustomer: response.IsCramoCustomer,
						address: {
							streetAddress: response.Address?.StreetAddress ?? '',
							postalCode: response.Address?.PostalCode ?? '',
							city: response.Address?.City ?? '',
						},
						hasSignatoryCombinations: response.SignatureCombinations.length > 0,
						shouldEnterAddressManually: response.Address === null || response.Address === undefined,
					},
				});
			});
	}

	public isCompanyValid(): boolean {
		return (
			this.form.get('company.id').valid &&
			this.form.get('company.name').value &&
			!this.form.get('company.isCustomer').value
		);
	}

	private companyLookup = (companyId: string) => {
		return this.digitalAccountApplicationService.companyLookup(companyId).pipe(
			catchError(() => {
				this.digitalAccountApplicationService.companyError.set(true);

				return of(null);
			}),
			finalize(() => {
				this.digitalAccountApplicationService.companyIsLoading.set(false);
			}),
		);
	};
}
