import { Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ConfigurationService } from '../services/configuration.service';
import { FeatureToggles } from '../types/configuration.types';

@Injectable()
export class RegistrationGuard {
	public featureToggles: Signal<FeatureToggles>;

	constructor(private configurationService: ConfigurationService) {
		this.featureToggles = toSignal(this.configurationService.featureToggles$);
	}

	canActivate() {
		return this.featureToggles().account.isRegisterNewUserPageEnabled;
	}
}
