import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, Input, OnInit, signal, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { startWith } from 'rxjs';
import { CheckboxComponent } from '../../../../components/checkbox/checkbox.component';
import { IconComponent } from '../../../../components/icon/icon.component';
import { InputComponent } from '../../../../components/input/input.component';
import { MessageBoxComponent } from '../../../../components/message-box/message-box.component';
import { InjectTranslationVarsDirective } from '../../../../directives/inject-translation-vars.directive';
import { ConfigurationService } from '../../../../services/configuration.service';
import { DigitalAccountApplicationService } from '../../../../services/digital-account-application.service';
import { EpiService } from '../../../../services/epi/epi.service';
import { FeatureToggles } from '../../../../types/configuration.types';
import { AppData } from '../../../../types/translations.types';
import { MaxEmailLength } from '../../../../utils/constants';
import {
	ApplicationType,
	DigitalAccountApplicationForm,
	InvoiceOption,
	PaymentMethodResponse,
} from '../../types/digital-account-application.types';

@Component({
	selector: 'cramo-payment-method-form',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatRadioModule,
		InputComponent,
		IconComponent,
		CheckboxComponent,
		InjectTranslationVarsDirective,
		MessageBoxComponent,
	],
	templateUrl: './payment-method-form.component.html',
	styleUrls: ['./payment-method-form.component.scss'],
})
export class PaymentMethodFormComponent implements OnInit {
	@Input() public form: FormGroup<DigitalAccountApplicationForm>;

	public emailMaxLengthErrorMessage = `Email must be less than ${MaxEmailLength} characters`;
	public appData: Signal<AppData>;
	public payments: Signal<PaymentMethodResponse>;
	public invoiceOptions: Signal<InvoiceOption[]>;
	public featureToggles: Signal<FeatureToggles>;
	public addressString: string;

	private applicationType = signal<ApplicationType>(null);

	constructor(
		private epiService: EpiService,
		private configurationService: ConfigurationService,
		private digitalAccountApplicationService: DigitalAccountApplicationService,
		private destroyRef: DestroyRef,
	) {
		this.appData = toSignal(this.epiService.appData$);
		this.payments = toSignal(this.digitalAccountApplicationService.getPaymentMethods());
		this.featureToggles = toSignal(this.configurationService.featureToggles$);

		this.invoiceOptions = computed(() => {
			if (!this.payments() || !this.applicationType()) {
				return;
			}

			return this.payments()[this.applicationType()].find((payment) => payment.Method === 'Invoice')?.Details;
		});
	}

	ngOnInit(): void {
		this.form
			.get('type')
			.valueChanges.pipe(startWith(this.form.get('type').value), takeUntilDestroyed(this.destroyRef))
			.subscribe((type) => this.applicationType.set(type));
	}

	public getAddressAsString(address: { streetAddress: string; postalCode: string; city: string }) {
		return this.digitalAccountApplicationService.getAddressAsString(address);
	}
}
