import * as tslib_1 from "tslib";
import { BaseCore } from './BaseCore';
import { EventsDiscardedReason } from "../JavaScriptSDK.Enums/EventsDiscardedReason";
import { NotificationManager } from "./NotificationManager";
import { CoreUtils } from "./CoreUtils";
import { _InternalLogMessage, DiagnosticLogger } from "./DiagnosticLogger";
"use strict";
var AppInsightsCore = /** @class */function (_super) {
  tslib_1.__extends(AppInsightsCore, _super);
  function AppInsightsCore() {
    return _super.call(this) || this;
  }
  AppInsightsCore.prototype.initialize = function (config, extensions) {
    var _self = this;
    _self._notificationManager = new NotificationManager();
    _self.logger = new DiagnosticLogger(config);
    _self.config = config;
    _super.prototype.initialize.call(this, config, extensions, _self.logger, _self._notificationManager);
  };
  AppInsightsCore.prototype.getTransmissionControls = function () {
    return _super.prototype.getTransmissionControls.call(this);
  };
  AppInsightsCore.prototype.track = function (telemetryItem) {
    if (telemetryItem === null) {
      this._notifyInvalidEvent(telemetryItem);
      // throw error
      throw Error("Invalid telemetry item");
    }
    // do basic validation before sending it through the pipeline
    this._validateTelemetryItem(telemetryItem);
    _super.prototype.track.call(this, telemetryItem);
  };
  /**
   * Adds a notification listener. The SDK calls methods on the listener when an appropriate notification is raised.
   * The added plugins must raise notifications. If the plugins do not implement the notifications, then no methods will be
   * called.
   * @param {INotificationListener} listener - An INotificationListener object.
   */
  AppInsightsCore.prototype.addNotificationListener = function (listener) {
    if (this._notificationManager) {
      this._notificationManager.addNotificationListener(listener);
    }
  };
  /**
   * Removes all instances of the listener.
   * @param {INotificationListener} listener - INotificationListener to remove.
   */
  AppInsightsCore.prototype.removeNotificationListener = function (listener) {
    if (this._notificationManager) {
      this._notificationManager.removeNotificationListener(listener);
    }
  };
  /**
   * Periodically check logger.queue for
   */
  AppInsightsCore.prototype.pollInternalLogs = function (eventName) {
    var _this = this;
    var interval = this.config.diagnosticLogInterval;
    if (!(interval > 0)) {
      interval = 10000;
    }
    return setInterval(function () {
      var _self = _this;
      var queue = _self.logger ? _self.logger.queue : [];
      CoreUtils.arrForEach(queue, function (logMessage) {
        var item = {
          name: eventName ? eventName : "InternalMessageId: " + logMessage.messageId,
          iKey: _self.config.instrumentationKey,
          time: CoreUtils.toISOString(new Date()),
          baseType: _InternalLogMessage.dataType,
          baseData: {
            message: logMessage.message
          }
        };
        _self.track(item);
      });
      queue.length = 0;
    }, interval);
  };
  AppInsightsCore.prototype._validateTelemetryItem = function (telemetryItem) {
    if (CoreUtils.isNullOrUndefined(telemetryItem.name)) {
      this._notifyInvalidEvent(telemetryItem);
      throw Error("telemetry name required");
    }
  };
  AppInsightsCore.prototype._notifyInvalidEvent = function (telemetryItem) {
    if (this._notificationManager) {
      this._notificationManager.eventsDiscarded([telemetryItem], EventsDiscardedReason.InvalidEvent);
    }
  };
  return AppInsightsCore;
}(BaseCore);
export { AppInsightsCore };
