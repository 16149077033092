<ng-container [formGroup]="form">
	<mat-radio-group color="primary" formControlName="type">
		<div class="radio-group">
			<mat-radio-button value="Company">
				<span class="custom-radio">{{ appData().digitalAccount.customerFormCompanyLabel }}</span>
			</mat-radio-button>
			<mat-radio-button value="Individual">
				<span class="custom-radio">{{ appData().digitalAccount.customerFormIndividualLabel }}</span>
			</mat-radio-button>
		</div>
	</mat-radio-group>

	@if (form.get('type').value === 'Company') {
		<section class="radio-content">
			<cramo-input
				required
				type="text"
				label="{{ appData().formElements.companyNumberLabel }}"
				[isLoading]="companyIsLoading()"
				[control]="form.get('company.id')"
				subscriptSizing="dynamic"
				[errorMessageMap]="{
					required: appData().formElements.fieldIsRequired,
					invalidCompanyId: appData().formElements.companyNumberIsInvalid,
				}"
			/>
			@if (!isCompanyLegalStatusValid()) {
				<cramo-message-box
					[header]="appData().digitalAccount.companyLegalStatusNotValidHeader"
					[message]="appData().digitalAccount.companyLegalStatusNotValidText"
					level="error"
				/>
			} @else if (!companyIsLoading() && form.get('company.isCustomer').value && form.get('company.id').valid) {
				<cramo-message-box
					header="{{ form.get('company.name').value }} {{
						appData().digitalAccount.customerFormCompanyAlreadyExistHeader
					}}"
					[message]="appData().digitalAccount.customerFormCompanyAlreadyExistText"
					[link]="registrationLink"
					linkText="{{ appData().digitalAccount.toUserRegistration }}"
					level="warning"
				/>
			} @else if (companyError() && form.get('company.id').value.length > 0) {
				<cramo-message-box
					header="{{ appData().digitalAccount.companyNotFoundHeader }}"
					message="{{ appData().digitalAccount.companyNotFoundText }}"
					level="error"
				/>
			} @else if (!companyError() && !companyIsLoading() && isCompanyValid()) {
				<cramo-message-box
					[header]="form.get('company.name').value"
					[message]="form.get('company.id').value"
					level="success"
				/>
			}
			@if (form.get('company.shouldEnterAddressManually').value) {
				<section class="radio-content address-section">
					<cramo-input
						class="input-address"
						required
						type="text"
						[label]="appData().formElements.addressLabel"
						[control]="form.get('company.address.streetAddress')"
						subscriptSizing="dynamic"
						[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
					/>
					<cramo-input
						class="input-postalcode"
						required
						type="text"
						label="{{ appData().formElements.postalCodeLabel }}"
						[control]="form.get('company.address.postalCode')"
						subscriptSizing="dynamic"
						[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
					/>
					<cramo-input
						class="input-city"
						required
						type="text"
						label="{{ appData().formElements.cityLabel }}"
						[control]="form.get('company.address.city')"
						subscriptSizing="dynamic"
						[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
					/>
				</section>
			}
		</section>
	} @else if (form.get('type').value === 'Individual') {
		<section class="radio-content address-section">
			@if (form.get('individual.isCustomer').value) {
				<cramo-message-box
					[header]="appData().digitalAccount.customerFormIndividualAlreadyExistHeader"
					[message]="appData().digitalAccount.customerFormIndividualAlreadyExistText"
					[link]="registrationLink"
					[linkText]="appData().digitalAccount.toUserRegistration"
					level="warning"
				/>
			} @else {
				<cramo-input
					class="input-address"
					required
					type="text"
					[label]="appData().formElements.addressLabel"
					[control]="form.get('individual.address.streetAddress')"
					subscriptSizing="dynamic"
					[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
				/>
				<cramo-input
					class="input-postalcode"
					required
					type="text"
					[label]="appData().formElements.postalCodeLabel"
					[control]="form.get('individual.address.postalCode')"
					subscriptSizing="dynamic"
					[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
				/>
				<cramo-input
					class="input-city"
					required
					type="text"
					[label]="appData().formElements.cityLabel"
					[control]="form.get('individual.address.city')"
					subscriptSizing="dynamic"
					[errorMessageMap]="{ required: appData().formElements.fieldIsRequired }"
				/>
			}
		</section>
	}
</ng-container>
