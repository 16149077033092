import { Component, OnDestroy, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { EpiService } from '../../services/epi/epi.service';
import { MetaService } from '../../services/meta.service';
import { SearchResult, SearchService } from '../../services/search.service';
import { UserService } from '../../services/user/user.service';
import { AppData } from '../../types/translations.types';

@Component({
	selector: 'cramo-search-page',
	templateUrl: './search-page.component.html',
	styleUrls: ['./search-page.component.scss'],
})
export class SearchPageComponent implements OnInit, OnDestroy {
	public searchResults: SearchResult;
	public contentLimit = 20;
	public goToPageLabel: string;
	public showMoreContentPagesLabel: string;
	public isSearching = false;
	public searchProgressText: string;
	public appData: Signal<AppData>;

	private onDestroy$ = new ReplaySubject(1);
	private userAuthenticated = false;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private searchService: SearchService,
		private epiService: EpiService,
		private metaService: MetaService,
		private userService: UserService,
	) {
		this.appData = toSignal(this.epiService.appData$);
		this.userService.isLoggedIn$.pipe(takeUntil(this.onDestroy$)).subscribe((isAuthenticated) => {
			this.userAuthenticated = isAuthenticated;
		});
	}

	ngOnInit() {
		this.metaService.setNoIndex();
		this.route.queryParams?.pipe(debounceTime(300), distinctUntilChanged()).subscribe((params) => {
			if (params?.['str']) {
				this.search(params['str']);
			} else {
				// When no search string is provided, navigate to home page
				this.router.navigate(['/']);
			}
		});
	}

	private search(searchString: string) {
		this.isSearching = true;
		this.searchService
			.search({ query: searchString, productSize: 100, pageSize: 200, forUser: this.userAuthenticated })
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((searchResult: SearchResult) => {
				this.searchResults = searchResult;
				this.contentLimit = 6;
				this.isSearching = false;
			});
	}

	public showMoreContentPages() {
		this.contentLimit += 6;
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(true);
		this.onDestroy$.complete();
	}
}
