<cramo-page-old>
	<div class="account-page-container">
		<div class="user-page-wrapper maxw-840">
			<div class="page-padding">
				<div class="account-container">
					<h1>{{ textLabels.title }}</h1>
					<div class="account-section">
						<div class="account-box">
							<div class="account-section__header">
								<p class="account-section__desc">{{ textLabels.pageDescription }}</p>
							</div>
							<div class="account-section-content">
								<div *ngIf="error" class="error-details">
									<div class="alert alert-danger" role="alert">{{ textLabels.errorText }}</div>
								</div>
								<div class="row">
									<div class="col-6">
										<form [formGroup]="passwordForm">
											<div class="form-group">
												<label for="currentPassword">{{ textLabels.currentpassword }}</label>
												<input
													class="form-control"
													formControlName="currentPassword"
													name="currentPassword"
													type="password"
													autocomplete="current-password"
													[ngClass]="{
														'input-error':
															passwordForm.controls.currentPassword.errors &&
															passwordForm.controls.currentPassword.touched,
													}"
												/>
											</div>
											<div class="form-group">
												<label for="newPassword">{{ textLabels.newpassword }}</label>
												<input
													class="form-control"
													formControlName="newPassword"
													name="newPassword"
													type="password"
													autocomplete="new-password"
													[ngClass]="{
														'is-invalid':
															passwordForm.controls.newPassword.errors && passwordForm.controls.newPassword.touched,
													}"
												/>
											</div>
											<div class="form-group">
												<label for="newPasswordRepeat">{{ textLabels.repeatpassword }}</label>
												<input
													class="form-control"
													formControlName="newPasswordRepeat"
													name="newPasswordRepeat"
													type="password"
													autocomplete="new-password"
													[ngClass]="{
														'is-invalid':
															(passwordForm.controls.newPasswordRepeat.errors ||
																passwordForm.errors?.passwordNotMatching) &&
															passwordForm.controls.newPasswordRepeat.touched,
													}"
												/>
												<div
													*ngIf="
														passwordForm.errors?.passwordNotMatching && passwordForm.controls.newPasswordRepeat.touched
													"
													class="invalid-feedback"
												>
													{{ textLabels.passwordsDontMatchMessage }}
												</div>
											</div>
											<div class="mt-3">
												<button
													(click)="save($event)"
													class="cramo-btn cramo-btn-primary"
													[disabled]="passwordForm.invalid"
													[ngClass]="{ disabled: passwordForm.invalid }"
												>
													{{ textLabels.save }}
												</button>
												<button (click)="cancel($event)" class="cramo-btn cramo-btn-link mr-2">
													{{ textLabels.cancel }}
												</button>
											</div>

											<cramo-loading-area *ngIf="loading" [showTransparent]="true" />
										</form>
									</div>
									<div class="col-6">
										<div class="cms-richtext" [innerHtml]="textLabels.passwordRequirements"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</cramo-page-old>
