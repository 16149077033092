import { Location } from '@angular/common';
import { Component, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ArrowButtonComponent } from '../../../../components/arrow-button/arrow-button.component';
import { ButtonComponent } from '../../../../components/button/button.component';
import { IconComponent } from '../../../../components/icon/icon.component';
import { PageComponent } from '../../../../components/page/page.component';
import { EpiService } from '../../../../services/epi/epi.service';
import { MarketService } from '../../../../services/market.service';
import { AppData } from '../../../../types/translations.types';

@Component({
	selector: 'cramo-digital-account-application-success-page',
	standalone: true,
	imports: [ArrowButtonComponent, ButtonComponent, IconComponent, PageComponent],
	templateUrl: './digital-account-application-success-page.component.html',
	styleUrls: ['./digital-account-application-success-page.component.scss'],
})
export class DigitalAccountApplicationSuccessPageComponent {
	public currentLanguage: string;
	public invites = true;
	public appData: Signal<AppData>;
	public isInvitesFlow: boolean;

	constructor(
		private epiService: EpiService,
		private marketService: MarketService,
		private router: Router,
		private location: Location,
	) {
		this.currentLanguage = this.marketService.currentLanguage;
		this.appData = toSignal(this.epiService.appData$);

		const locationState = this.location.getState() as { invitesFlow?: boolean };
		this.isInvitesFlow = locationState.invitesFlow ?? false;
	}

	public backToHome(): void {
		this.router.navigateByUrl('/');
	}
}
