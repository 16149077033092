import { CommonModule } from '@angular/common';
import { Component, OnInit, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { EpiService } from '../../services/epi/epi.service';
import { EventBusService } from '../../services/event-bus.service';
import { GtmService } from '../../services/gtm.service';
import { MarketService } from '../../services/market.service';
import { QuickSearchResult, SearchService } from '../../services/search.service';
import { AppData } from '../../types/translations.types';
import { IconComponent } from '../icon/icon.component';
import { LinkComponent } from '../link/link.component';
import { LoadingAreaComponent } from '../loading-area/loading-area.component';

@Component({
	selector: 'cramo-search-header-input',
	templateUrl: './search-header-input.component.html',
	styleUrls: ['./search-header-input.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		ClickOutsideDirective,
		IconComponent,
		LinkComponent,
		LoadingAreaComponent,
		RouterLink,
		FormsModule,
	],
})
export class SearchHeaderInputComponent implements OnInit {
	public searchString = '';
	public searchResults: QuickSearchResult;
	public isSearching = false;
	public emptyResult = false;
	public showSearchDropdown = false;
	public appData: Signal<AppData>;

	private currentLanguage: string;
	private stringChange: Subject<string> = new Subject<string>();
	private isDebounceCancelled = false;

	constructor(
		private searchService: SearchService,
		private epiService: EpiService,
		private router: Router,
		private eventBusService: EventBusService,
		private gtmService: GtmService,
		private marketService: MarketService,
	) {
		this.currentLanguage = this.marketService.currentLanguage;
		this.appData = toSignal(this.epiService.appData$);
	}

	ngOnInit() {
		this.initSearch();
	}

	private initSearch() {
		// Unsubscribe not needed since this is always shown
		this.stringChange
			.pipe(
				debounceTime(400),
				switchMap((term: string) => {
					if (this.isDebounceCancelled) {
						return of(null);
					}
					return of(term);
				}),
				tap((term: string) => {
					if (term === null) return;

					this.searchString = term;
					this.showSearchDropdown = true;
					this.eventBusService.searchOpenSubject.next(true);
					this.isSearching = true;
					this.emptyResult = false;
				}),
				switchMap((term): Observable<QuickSearchResult> => {
					if (this.isDebounceCancelled) {
						return of(null);
					}
					return this.searchService.quicksearch({ query: term, productSize: 6, pageSize: 6 });
				}),
			)
			.subscribe((searchResult: QuickSearchResult | null) => {
				this.isSearching = false;

				if (this.isDebounceCancelled) return;

				if (isSearchResultEmpty(searchResult)) {
					this.emptyResult = true;
				} else {
					this.emptyResult = false;
					searchResult.ProductContentSearchResult.forEach((p) => {
						if (p.ImageUrl === null) {
							p.ImageUrl = '/assets/icons/icon_nophoto.svg';
						}
					});
				}

				this.searchResults = searchResult;
			});
	}

	// not handling query.length of 1 makes response stay when deleting from 2 letters to 1.
	public searchChanged(query: string) {
		if (query.length > 1) {
			this.isDebounceCancelled = false;
			this.stringChange.next(query);
		} else if (query.length < 1) {
			this.close();
		} else {
			this.isDebounceCancelled = true;

			if (this.isSearching) return;

			if (isSearchResultEmpty(this.searchResults)) {
				this.close();
			}
		}
	}

	public close() {
		this.isDebounceCancelled = true;
		this.showSearchDropdown = false;
		this.eventBusService.searchOpenSubject.next(false);
	}

	public closeAndReset() {
		this.close();
		this.searchString = '';
	}

	public goToSearchPage(event: Event, searchString: string) {
		event.preventDefault();
		event.stopPropagation();

		if (searchString.length < 2) {
			return;
		}
		const targetUrl = `/${this.currentLanguage}/search?str=${searchString}`;
		this.gtmService.sendSearchEvent(searchString, targetUrl);
		this.router.navigateByUrl(targetUrl);
		this.close();
	}

	public registerAndcloseAndReset(searchString: string, destinationUrl: string) {
		this.gtmService.sendSearchEvent(searchString, destinationUrl);
		this.closeAndReset();
	}
}

function isSearchResultEmpty(searchResult: QuickSearchResult | null): boolean {
	if (!searchResult) return true;
	return searchResult?.ContentSearchResult.length === 0 && searchResult?.ProductContentSearchResult.length === 0;
}
