import { Component, OnInit } from '@angular/core';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';
import { MetaService } from '../../services/meta.service';
import { AppData } from '../../types/translations.types';

@Component({
	selector: 'cramo-notfound-page',
	templateUrl: './notfound-page.component.html',
	styleUrls: ['./notfound-page.component.scss'],
})
export class NotfoundPageComponent implements OnInit {
	constructor(
		private epiService: EpiService,
		private metaService: MetaService,
		private marketService: MarketService,
	) {
		this.appData = this.epiService.appData;
		this.url = location.href.replace('/notfound', '');
		this.errorHeadingText = this.appData.error?.notFoundHeading ?? this.errorHeadingText;
		this.errorDescriptionText = this.appData.error?.notFoundText ?? this.errorDescriptionText;
		this.backToHomeText = this.appData.error?.backToHome ?? this.backToHomeText;
	}

	public productName: string;
	public url: string;
	public errorHeadingText = 'Error 404 - Page not found';
	public errorDescriptionText = 'The requested URL was not found on this server';
	public backToHomeText = 'Back to Home';

	private appData: AppData;

	ngOnInit() {
		this.setMetaInfo();
	}

	private setMetaInfo() {
		const title = this.errorHeadingText;
		const description = this.errorDescriptionText;

		this.metaService.setMeta(`${title} | cramo.${this.marketService.currentMarket.toLowerCase()}`, [
			{ key: 'description', value: description },
			{ key: 'prerender-status-code', value: '404' },
		]);
	}
}
