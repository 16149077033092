import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
	selector: 'cramo-cookie-declaration-page',
	templateUrl: './cookie-declaration-page.component.html',
	styleUrls: ['./cookie-declaration-page.component.scss'],
})
export class CookieDeclarationPageComponent implements OnInit {
	constructor(
		private renderer: Renderer2,
		private element: ElementRef<HTMLElement>,
	) {}

	ngOnInit(): void {
		this.loadCookieBotScript();
	}

	private loadCookieBotScript(): void {
		const scriptSrc = 'https://consent.cookiebot.com/c08da1f1-6a1a-4c31-bcc3-74008b165c16/cd.js';

		const script = this.renderer.createElement('script');
		this.renderer.setAttribute(script, 'id', 'CookieDeclaration');
		this.renderer.setAttribute(script, 'src', scriptSrc);
		this.renderer.setAttribute(script, 'type', 'text/javascript');
		this.renderer.setAttribute(script, 'async', 'true');

		this.renderer.appendChild(this.element.nativeElement.querySelector('#cookie-declaration-page'), script);
	}
}
