import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { AppCustomPreloader } from '../app-custom-preloader';
import { authGuard } from '../guards/auth.guard';
import { DepotGuard } from '../guards/depot.guard';
import { LoggedOutAuthGuard } from '../guards/logged-out-auth.guard';
import { permissionGuard } from '../guards/permission.guard';
import { RoutePathGuard } from '../guards/route-path.guard';
import { OCCPreloadingStrategy } from '../occ-preloading-strategy';
import { OCCRouteReuseStrategy } from '../occ-route-reuse-strategy';
import { AccountChangePhonePageComponent } from '../pages/account-change-phone-page/account-change-phone-page.component';
import { AccountNotFoundPageComponent } from '../pages/account-not-found-page/account-not-found-page.component';
import { CompletePageComponent } from '../pages/complete-page/complete-page.component';
import { CookieDeclarationPageComponent } from '../pages/cookie-declaration-page/cookie-declaration-page.component';
import { DigitalAccountApplicationSuccessPageComponent } from '../pages/digital-account-application-page/components/digital-account-application-success-page/digital-account-application-success-page.component';
import { DigitalAccountApplicationPageComponent } from '../pages/digital-account-application-page/digital-account-application-page.component';
import { NotfoundPageComponent } from '../pages/notfound-page/notfound-page.component';
import { TemporaryProductPageRedirectComponent } from '../pages/product-page/components/temporary-product-page-redirect/temporary-product-page-redirect.component';
import { PunchoutPageComponent } from '../pages/punchout-page/punchout-page.component';
import { SearchPageComponent } from '../pages/search-page/search-page.component';

const routes: Routes = [
	{
		path: ':lang/complete',
		component: CompletePageComponent,
		canActivate: [authGuard],
	},
	{
		path: ':lang/cart',
		loadComponent: () =>
			import('../pages/cart-page/components/cart-page-picker/cart-page-picker.component').then(
				(c) => c.CartPagePickerComponent,
			),
		pathMatch: 'full',
		canActivate: [authGuard, DepotGuard],
		data: { preload: true },
	},
	{
		path: ':lang/category/:categoryName',
		loadChildren: () => import('../pages/category-page/category-page.module').then((m) => m.CategoryPageModule),
		pathMatch: 'full',
		canActivate: [RoutePathGuard],
		data: { preload: true },
	},
	{
		path: ':lang/category/:categoryName/product/:productName',
		component: TemporaryProductPageRedirectComponent,
	},
	{
		path: ':lang/category/:categoryName/product/:productId/:productName',
		loadComponent: () =>
			import('../pages/product-page/components/product-page-picker/product-page-picker.component').then(
				(c) => c.ProductPagePickerComponent,
			),
		pathMatch: 'full',
		canActivate: [RoutePathGuard],
		data: { preload: true },
	},
	{
		path: ':lang/depot',
		loadComponent: () =>
			import('../pages/depot-map-page/depot-map-page.component').then((m) => m.DepotMapPageComponent),
		pathMatch: 'full',
		canActivate: [RoutePathGuard, DepotGuard],
		data: { preload: true },
	},
	{
		path: ':lang/invoices',
		loadChildren: () => import('../pages/invoices-page/invoices-page.module').then((m) => m.InvoicesPageModule),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('LIST_INVOICES')],
	},
	{
		path: ':lang/account',
		loadChildren: () => import('../pages/account-page/account-page.module').then((m) => m.AccountPageModule),
		pathMatch: 'full',
		canActivate: [authGuard, DepotGuard],
	},
	{
		path: ':lang/account/phone',
		component: AccountChangePhonePageComponent,
		canActivate: [authGuard],
	},
	{
		path: ':lang/jobsite/:jobsiteId',
		loadChildren: () => import('../pages/jobsite-page/jobsite-page.module').then((m) => m.JobsitePageModule),
		pathMatch: 'full',
		canActivate: [authGuard],
	},
	{
		path: ':lang/statistics',
		loadChildren: () => import('../pages/statistics-page/statistics-page.module').then((m) => m.StatisticsPageModule),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('STATISTICS_LIST_PRESENTATION')],
	},
	{
		path: ':lang/notifications',
		loadChildren: () =>
			import('../pages/notifications-page/notifications-page.module').then((m) => m.NotificationsPageModule),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('LIST_ORDER_LINES')],
	},
	{
		path: ':lang/savedproducts',
		loadChildren: () =>
			import('../pages/savedproducts-page/savedproducts-page.module').then((m) => m.SavedProductsPageModule),
		pathMatch: 'full',
		canActivate: [authGuard],
	},
	{
		path: ':lang/users',
		loadChildren: () => import('../pages/users-page/users-page.module').then((m) => m.UsersPageModule),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('ADMINISTRATE_USERS')],
	},
	{
		path: ':lang/jobsites',
		loadChildren: () => import('../pages/jobsites-page/jobsites-page.module').then((m) => m.JobsitesPageModule),
		pathMatch: 'full',
		canActivate: [authGuard, permissionGuard('VIEW_JOB_SITES')],
	},
	{
		path: ':lang/search',
		component: SearchPageComponent,
		canActivate: [RoutePathGuard],
		data: { preload: true },
	},
	{
		path: ':lang/registration',
		loadChildren: () =>
			import('../pages/registration-page/registration-page.module').then((m) => m.RegistrationPageModule),
		pathMatch: 'full',
		canActivate: [RoutePathGuard],
		data: { preload: true },
	},
	{
		path: ':lang/registration/:sign',
		loadChildren: () =>
			import('../pages/registration-page/registration-page.module').then((m) => m.RegistrationPageModule),
		pathMatch: 'full',
		canActivate: [RoutePathGuard],
		data: { preload: true },
	},
	{
		path: ':lang/digital-account-application',
		component: DigitalAccountApplicationPageComponent,
		canActivate: [RoutePathGuard],
	},
	{
		path: ':lang/digital-account-application/success',
		component: DigitalAccountApplicationSuccessPageComponent,
		canActivate: [RoutePathGuard],
	},
	{
		path: ':lang/account-not-found',
		component: AccountNotFoundPageComponent,
		canActivate: [LoggedOutAuthGuard],
	},
	{
		path: ':lang/notfound',
		children: [
			{
				path: '**',
				component: NotfoundPageComponent,
			},
		],
	},
	{
		path: ':lang/punchout/:punchoutSessionId',
		component: PunchoutPageComponent,
		pathMatch: 'full',
		canActivate: [RoutePathGuard],
		data: { preload: true },
	},
	{
		path: ':lang/cookie-declaration',
		component: CookieDeclarationPageComponent,
	},
	{
		path: ':lang/feature-toggles',
		loadComponent: () =>
			import('../pages/feature-toggles-page/feature-toggles-page.component').then((c) => c.FeatureTogglesPageComponent),
	},
	{
		path: ':lang',
		loadChildren: () => import('../pages/cms-page/cms-page.module').then((m) => m.CMSPageModule),
		canActivate: [RoutePathGuard],
		data: { preload: true },
	},
	{
		path: ':lang/:contentUrl',
		children: [
			{
				path: '**',
				loadChildren: () => import('../pages/cms-page/cms-page.module').then((m) => m.CMSPageModule),
				canActivate: [RoutePathGuard],
				data: { preload: true, reuseComponent: false },
			},
		],
	},

	{ path: '**', pathMatch: 'full', canActivate: [RoutePathGuard], component: NotfoundPageComponent },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			useHash: false,
			preloadingStrategy: OCCPreloadingStrategy,
			anchorScrolling: 'disabled',
		}),
	],
	exports: [RouterModule],
	providers: [AppCustomPreloader, { provide: RouteReuseStrategy, useClass: OCCRouteReuseStrategy }],
})
export class AppRoutingModule {}
