import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketService } from '../../../../services/market.service';

/**
 * WARNING: This component should be reomoved as soon as there are no more visitors to ':lang/category/:categoryName/product/:productName'
 */
@Component({
	selector: 'cramo-temporary-product-page-redirect',
	templateUrl: './temporary-product-page-redirect.component.html',
})
export class TemporaryProductPageRedirectComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private http: HttpClient,
		private marketService: MarketService,
		private meta: Meta,
	) {}

	ngOnInit() {
		this.meta.addTags([{ name: 'robots', content: 'noindex' }]);

		const categoryName = this.route.snapshot.paramMap.get('categoryName');
		const productName = this.route.snapshot.paramMap.get('productName');

		if (categoryName && productName) {
			const apiUrl = `/webapi/content/productUrlByCategoryPathAndProductUriName?language=${this.marketService.currentLanguage}&marketId=${this.marketService.currentMarket}`;
			this.http
				.post<string>(apiUrl, { CategoryLinkUrl: categoryName, ProductUriName: productName })
				.subscribe((newProductRoute) => {
					this.router.navigateByUrl(newProductRoute);
				});
		}
	}
}
