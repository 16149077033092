import { CommonModule } from '@angular/common';
import { Component, Signal, ViewChild, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Observable, filter, finalize, map, of, switchMap, take, tap } from 'rxjs';
import { AutocompleteComponent } from '../../components/autocomplete/autocomplete.component';
import { AutoCompleteOption } from '../../components/autocomplete/types/autocomplete.types';
import { ButtonComponent } from '../../components/button/button.component';
import { CardComponent } from '../../components/card/card.component';
import { CreateJobsiteFormComponent } from '../../components/create-jobsite-form/create-jobsite-form.component';
import { IconComponent } from '../../components/icon/icon.component';
import { LanguageButtonComponent } from '../../components/language-button/language-button.component';
import { LoadingAreaComponent } from '../../components/loading-area/loading-area.component';
import { PunchoutBannerComponent } from '../../components/punchout-banner/punchout-banner.component';
import { HasPermissionDirective } from '../../directives/has-permission.directive';
import { DepotService } from '../../services/depot/depot.service';
import { EpiService } from '../../services/epi/epi.service';
import { GtmService } from '../../services/gtm.service';
import { JobsiteService } from '../../services/jobsite/jobsite.service';
import { MarketService } from '../../services/market.service';
import { PunchoutService } from '../../services/punchout.service';
import { UserService } from '../../services/user/user.service';
import { AddJobsiteType, Jobsite } from '../../types/jobsite.types';
import { SupportedLanguage } from '../../types/market.types';
import { AppData } from '../../types/translations.types';
import { Customer } from '../../types/user.types';

@Component({
	selector: 'cramo-onboarding-page',
	templateUrl: './onboarding-page.component.html',
	styleUrls: ['./onboarding-page.component.scss'],
	standalone: true,
	imports: [
		CardComponent,
		ButtonComponent,
		CommonModule,
		PunchoutBannerComponent,
		LanguageButtonComponent,
		LoadingAreaComponent,
		MatStepperModule,
		MatIconModule,
		ReactiveFormsModule,
		CreateJobsiteFormComponent,
		AutocompleteComponent,
		MatFormFieldModule,
		IconComponent,
		HasPermissionDirective,
	],
})
export class OnboardingPageComponent {
	@ViewChild('stepper') public stepper: MatStepper;
	@ViewChild(CreateJobsiteFormComponent) public createJobsiteFormComponent: CreateJobsiteFormComponent;

	public appData: Signal<AppData>;
	public isPunchout$: Observable<boolean>;
	// this is needed due to (click) not being able to process async pipes (cramo-button for selecting customer in this component)
	public isPunchout = false;
	public isAddingJobsite = false;
	public currentMarket: string;
	public currentLanguage: SupportedLanguage;

	protected customerOptions: Signal<AutoCompleteOption[]>;
	protected depotOptions: Signal<AutoCompleteOption[]>;
	public depots$: Observable<AutoCompleteOption[]>;

	public customerForm: FormControl<string>;
	public isLoadingSetCustomer = false;
	public isCustomerSet = false;

	public jobsiteForm: UntypedFormGroup;

	public depotForm: FormControl<string>;
	public isLoadingSetDepot = false;
	public isDepotSet = false;
	public didCustomerSelectFail = false;
	public didDepotSelectFail = false;
	public hasError = false;
	public createdJobsite: AddJobsiteType | null = null;
	public isLoadingView = true;

	public jobsites = signal<Jobsite[] | null>(null);

	constructor(
		private userService: UserService,
		private epiService: EpiService,
		private marketService: MarketService,
		private depotService: DepotService,
		private punchoutService: PunchoutService,
		private jobsiteService: JobsiteService,
		private gtmService: GtmService,
	) {
		this.isPunchout$ = this.punchoutService.isPunchout$.pipe(tap((isPunchout) => (this.isPunchout = isPunchout)));
		this.currentMarket = this.marketService.currentMarket;
		this.currentLanguage = this.marketService.currentLanguage;
		this.appData = toSignal(this.epiService.appData$, { requireSync: true });

		this.customerOptions = toSignal(
			this.userService.user$.pipe(
				switchMap(() => this.checkIfCustomerIsSet()),
				switchMap((isCustomerSet) => (isCustomerSet ? this.getJobsites() : of(null))),
				switchMap(() => this.getCustomerOptions()),
			),
			{ initialValue: [] },
		);

		this.depotOptions = toSignal(this.getDepotOptions(), { initialValue: [] });

		this.customerForm = this.createCustomerForm();
		this.depotForm = this.createDepotForm();
	}

	public getMarketLanguage() {
		return this.marketService.marketLanguageMap[this.marketService.currentMarket];
	}

	public goForward() {
		this.stepper.next();
	}

	public goToDepot() {
		this.stepper.selectedIndex = 2;
	}

	public onCustomerSelect() {
		if (!this.customerForm.valid) return;
		this.didCustomerSelectFail = false;

		const customerId = this.customerForm.value;

		this.setCustomer(customerId).subscribe({
			next: () => {
				// Will not go to the next step if "isCustomerSet" is set in the same tick
				setTimeout(() => this.stepper.next(), 0);
			},
			error: () => (this.didCustomerSelectFail = true),
		});
	}

	public setDepot() {
		if (!this.depotForm.valid) return;

		this.didDepotSelectFail = false;
		this.isLoadingSetDepot = true;

		this.depotService.depots$
			.pipe(
				take(1),
				filter(() => Boolean(this.depotForm.value)),
				map((depots) => depots.find((depot) => depot.Id === this.depotForm.value)),
				filter((depot) => Boolean(depot)),
				switchMap((depot) => this.depotService.setDefaultDepot(depot!)),
			)
			.subscribe({
				next: () => {
					this.isDepotSet = true;
					// Will not go to the next step if "isDepotSet" is set in the same tick
					setTimeout(() => this.stepper.next(), 0);
				},
				error: () => (this.didDepotSelectFail = true),
				complete: () => (this.isLoadingSetDepot = false),
			});
	}

	public handleOnboardingDone() {
		// location.reload is used instead of directly using viewPickerService due to app
		// not handling lazy loading of notifications and cart
		location.reload();
	}

	private createCustomerForm() {
		return new FormControl('', {
			validators: [Validators.required],
			updateOn: 'change',
			nonNullable: true,
		});
	}

	private getJobsites() {
		return this.jobsiteService.getJobsites().pipe(
			take(1),
			tap((jobsites) => this.jobsites.set(jobsites)),
		);
	}

	private checkIfCustomerIsSet() {
		return this.userService.user$.pipe(
			map((user) => {
				this.isCustomerSet = user.CustomerInfo?.MarketId === this.currentMarket;
				if (this.isCustomerSet) {
					this.setCustomerFormValue(this.parseCustomerAutoCompleteOption(user.CustomerInfo!));
				}
				return this.isCustomerSet;
			}),
		);
	}

	private getCustomerOptions(): Observable<AutoCompleteOption[]> {
		return this.userService.getSidebarUsersCustomers().pipe(
			map((customers) => customers.Customers.filter((customer) => customer.MarketId === this.currentMarket)),
			tap((customers) => {
				if (customers.length === 1 && !this.isCustomerSet) {
					this.setCustomer(customers[0].CustomerId).subscribe(() => {
						this.setCustomerFormValue(this.parseCustomerAutoCompleteOption(customers[0]));
						this.isLoadingView = false;
					});
				} else {
					this.isLoadingView = false;
				}
			}),
			map((customers) => customers.map((customer) => this.parseCustomerAutoCompleteOption(customer))),
		);
	}

	private setCustomer(customerId: string) {
		this.isLoadingSetCustomer = true;
		return this.userService.setCustomer(customerId).pipe(
			take(1),
			switchMap(() => this.userService.getUser()),
			switchMap(() => this.getJobsites()),
			tap(() => {
				this.createdJobsite = null;
				this.isCustomerSet = true;
			}),
			finalize(() => (this.isLoadingSetCustomer = false)),
		);
	}

	private createDepotForm(): FormControl<string> {
		return new FormControl<string>('', {
			validators: [Validators.required],
			updateOn: 'change',
			nonNullable: true,
		});
	}

	private getDepotOptions(): Observable<AutoCompleteOption[]> {
		const localeForSorting = this.marketService.marketLanguageMap[this.marketService.currentMarket];

		return this.depotService.webDepots$.pipe(
			map((depots) =>
				depots
					.slice()
					.sort((a, b) => a.Name.localeCompare(b.Name, localeForSorting))
					.map((depot) => ({
						title: depot.Name,
						body: `${depot.Address.PostalCode} ${depot.Address.CityName}`,
						value: depot.Id,
					})),
			),
		);
	}

	private setCustomerFormValue(customerAutoCompleteOption: AutoCompleteOption<string>) {
		this.customerForm.setValue(customerAutoCompleteOption.value);
	}

	private parseCustomerAutoCompleteOption(customer: Customer): AutoCompleteOption<string> {
		return {
			title: customer.CompanyName,
			body: `${customer.Type} ${customer.InvoiceCustomerNumber}`,
			value: customer.CustomerId,
		};
	}

	public skipJobsiteStepForNow() {
		this.gtmService.sendOnboardingEvent('onboardingUserJobsiteSkipped', '', '');
		this.goForward();
	}

	public addJobsite(jobsite: AddJobsiteType) {
		this.isAddingJobsite = true;
		this.jobsiteService.addJobsite(jobsite).subscribe({
			next: () => {
				this.createdJobsite = jobsite;

				this.sendJobsiteCreatedEvent({
					// TODO -> this.customerForm.get('customer')!.value.value is probablu wrong, the formcontrol only contains a string
					customerId: this.customerForm.get('customer')!.value.value,
					marketId: this.currentMarket,
				});
				this.goForward();
			},
			complete: () => {
				this.isAddingJobsite = false;
			},
		});
	}

	public sendJobsiteCreatedEvent({ customerId, marketId }: { customerId: string; marketId: string }) {
		const value = JSON.stringify({ customerId, marketId });
		this.gtmService.sendOnboardingEvent('onboardingUserJobsite', '', value);
	}
}
