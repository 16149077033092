import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EpiService } from '../../services/epi/epi.service';
import { MarketService } from '../../services/market.service';

// TODO: Remove if not back in use...at some time.
@Component({
	selector: 'cramo-account-change-password-page',
	templateUrl: './account-change-password-page.component.html',
})
export class AccountChangePasswordPageComponent implements OnInit {
	public textLabels: { [key: string]: string } = {};
	public passwordForm: UntypedFormGroup;

	public loading = false;
	public error = false;

	constructor(
		private epiService: EpiService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		private marketService: MarketService,
	) {}

	ngOnInit() {
		this.createForms();
		this.setTextLabels();
	}

	private setTextLabels(): void {
		const accountLabels = this.epiService.appData.myPages.account;
		const globalLabels = this.epiService.appData.global;

		this.textLabels.errorText = accountLabels.updateErrorText;
		this.textLabels.currentpassword = accountLabels.currentPassword;
		this.textLabels.newpassword = accountLabels.newPassword;
		this.textLabels.repeatpassword = accountLabels.repeatNewPassword;
		this.textLabels.cancel = globalLabels.cancel;
		this.textLabels.save = globalLabels.saveChanges;
		this.textLabels.title = accountLabels.changePassword;
		this.textLabels.pageDescription = accountLabels.changePasswordPageDescription;
		this.textLabels.passwordsDontMatchMessage = accountLabels.passwordsDontMatchMessage;
		this.textLabels.passwordRequirements = accountLabels.passwordRequirements;
	}

	private createForms() {
		this.passwordForm = this.formBuilder.group(
			{
				currentPassword: ['', [Validators.required, Validators.minLength(6)]],
				newPassword: ['', [Validators.required, Validators.minLength(6)]],
				newPasswordRepeat: ['', [Validators.required, Validators.minLength(6)]],
			},
			{ validator: this.matchPassword },
		);
	}

	private matchPassword(form: UntypedFormGroup) {
		const newPassword = form.get('newPassword').value;
		const newPasswordRepeat = form.get('newPasswordRepeat').value;
		return newPassword !== newPasswordRepeat ? { passwordNotMatching: true } : null;
	}

	private navigateToAccount() {
		this.router.navigate(['/', this.marketService.currentLanguage, 'account']);
	}

	public save(event: MouseEvent) {
		event.preventDefault();
		this.loading = true;
		// const passwordRequest: PasswordRequest = { CurrentPassword: this.passwordForm.get('currentPassword').value, NewPassword: this.passwordForm.get('newPassword').value, NewPasswordRepeat: this.passwordForm.get('newPasswordRepeat').value }

		// this.userService
		// 	.updatePassword(passwordRequest)
		// 	.pipe(take(1))
		// 	.subscribe(
		// 		(data: any) => {
		// 			this.loading = false;
		// 			this.navigateToAccount();
		// 		},
		// 		(error: any) => {
		// 			this.loading = false;
		// 			this.error = true;
		// 		},
		// 	);
	}

	public cancel(event: MouseEvent) {
		event.preventDefault();
		this.navigateToAccount();
	}
}
