import * as tslib_1 from "tslib";
import { SessionStorageSendBuffer, ArraySendBuffer } from './SendBuffer';
import { DependencyEnvelopeCreator, EventEnvelopeCreator, ExceptionEnvelopeCreator, MetricEnvelopeCreator, PageViewEnvelopeCreator, PageViewPerformanceEnvelopeCreator, TraceEnvelopeCreator } from './EnvelopeCreator';
import { Serializer } from './Serializer'; // todo move to channel
import { DisabledPropertyName, RequestHeaders, Util, PageView, Event, Trace, Exception, Metric, PageViewPerformance, RemoteDependencyData, ProcessLegacy, BreezeChannelIdentifier, SampleRate } from '@microsoft/applicationinsights-common';
import { CoreUtils, _InternalMessageId, LoggingSeverity, getWindow, getNavigator, getJSON, BaseTelemetryPlugin } from '@microsoft/applicationinsights-core-js';
import { Offline } from './Offline';
import { Sample } from './TelemetryProcessors/Sample';
var Sender = /** @class */function (_super) {
  tslib_1.__extends(Sender, _super);
  function Sender() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.priority = 1001;
    _this.identifier = BreezeChannelIdentifier;
    /**
     * Whether XMLHttpRequest object is supported. Older version of IE (8,9) do not support it.
     */
    _this._XMLHttpRequestSupported = false;
    return _this;
  }
  Sender.constructEnvelope = function (orig, iKey, logger) {
    var envelope;
    if (iKey !== orig.iKey && !CoreUtils.isNullOrUndefined(iKey)) {
      envelope = tslib_1.__assign({}, orig, {
        iKey: iKey
      });
    } else {
      envelope = orig;
    }
    switch (envelope.baseType) {
      case Event.dataType:
        return EventEnvelopeCreator.EventEnvelopeCreator.Create(logger, envelope);
      case Trace.dataType:
        return TraceEnvelopeCreator.TraceEnvelopeCreator.Create(logger, envelope);
      case PageView.dataType:
        return PageViewEnvelopeCreator.PageViewEnvelopeCreator.Create(logger, envelope);
      case PageViewPerformance.dataType:
        return PageViewPerformanceEnvelopeCreator.PageViewPerformanceEnvelopeCreator.Create(logger, envelope);
      case Exception.dataType:
        return ExceptionEnvelopeCreator.ExceptionEnvelopeCreator.Create(logger, envelope);
      case Metric.dataType:
        return MetricEnvelopeCreator.MetricEnvelopeCreator.Create(logger, envelope);
      case RemoteDependencyData.dataType:
        return DependencyEnvelopeCreator.DependencyEnvelopeCreator.Create(logger, envelope);
      default:
        return EventEnvelopeCreator.EventEnvelopeCreator.Create(logger, envelope);
    }
  };
  Sender._getDefaultAppInsightsChannelConfig = function () {
    // set default values
    return {
      endpointUrl: function () {
        return "https://dc.services.visualstudio.com/v2/track";
      },
      emitLineDelimitedJson: function () {
        return false;
      },
      maxBatchInterval: function () {
        return 15000;
      },
      maxBatchSizeInBytes: function () {
        return 102400;
      },
      disableTelemetry: function () {
        return false;
      },
      enableSessionStorageBuffer: function () {
        return true;
      },
      isRetryDisabled: function () {
        return false;
      },
      isBeaconApiDisabled: function () {
        return true;
      },
      onunloadDisableBeacon: function () {
        return false;
      },
      instrumentationKey: function () {
        return undefined;
      },
      namePrefix: function () {
        return undefined;
      },
      samplingPercentage: function () {
        return 100;
      }
    };
  };
  Sender._getEmptyAppInsightsChannelConfig = function () {
    return {
      endpointUrl: undefined,
      emitLineDelimitedJson: undefined,
      maxBatchInterval: undefined,
      maxBatchSizeInBytes: undefined,
      disableTelemetry: undefined,
      enableSessionStorageBuffer: undefined,
      isRetryDisabled: undefined,
      isBeaconApiDisabled: undefined,
      onunloadDisableBeacon: undefined,
      instrumentationKey: undefined,
      namePrefix: undefined,
      samplingPercentage: undefined
    };
  };
  Sender.prototype.pause = function () {
    throw new Error("Method not implemented.");
  };
  Sender.prototype.resume = function () {
    throw new Error("Method not implemented.");
  };
  Sender.prototype.flush = function () {
    try {
      this.triggerSend();
    } catch (e) {
      this.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.FlushFailed, "flush failed, telemetry will not be collected: " + Util.getExceptionName(e), {
        exception: Util.dump(e)
      });
    }
  };
  Sender.prototype.onunloadFlush = function () {
    if ((this._senderConfig.onunloadDisableBeacon() === false || this._senderConfig.isBeaconApiDisabled() === false) && Util.IsBeaconApiSupported()) {
      try {
        this.triggerSend(true, this._beaconSender);
      } catch (e) {
        this.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.FailedToSendQueuedTelemetry, "failed to flush with beacon sender on page unload, telemetry will not be collected: " + Util.getExceptionName(e), {
          exception: Util.dump(e)
        });
      }
    } else {
      this.flush();
    }
  };
  Sender.prototype.teardown = function () {
    throw new Error("Method not implemented.");
  };
  Sender.prototype.initialize = function (config, core, extensions, pluginChain) {
    _super.prototype.initialize.call(this, config, core, extensions, pluginChain);
    var ctx = this._getTelCtx();
    var identifier = this.identifier;
    this._serializer = new Serializer(core.logger);
    this._consecutiveErrors = 0;
    this._retryAt = null;
    this._lastSend = 0;
    this._sender = null;
    var defaultConfig = Sender._getDefaultAppInsightsChannelConfig();
    this._senderConfig = Sender._getEmptyAppInsightsChannelConfig();
    var _loop_1 = function (field) {
      this_1._senderConfig[field] = function () {
        return ctx.getConfig(identifier, field, defaultConfig[field]());
      };
    };
    var this_1 = this;
    for (var field in defaultConfig) {
      _loop_1(field);
    }
    this._buffer = this._senderConfig.enableSessionStorageBuffer && Util.canUseSessionStorage() ? new SessionStorageSendBuffer(this.diagLog(), this._senderConfig) : new ArraySendBuffer(this._senderConfig);
    this._sample = new Sample(this._senderConfig.samplingPercentage(), this.diagLog());
    if (!this._senderConfig.isBeaconApiDisabled() && Util.IsBeaconApiSupported()) {
      this._sender = this._beaconSender;
    } else {
      if (!CoreUtils.isUndefined(XMLHttpRequest)) {
        var testXhr = new XMLHttpRequest();
        if ("withCredentials" in testXhr) {
          this._sender = this._xhrSender;
          this._XMLHttpRequestSupported = true;
        } else if (!CoreUtils.isUndefined(XDomainRequest)) {
          this._sender = this._xdrSender; // IE 8 and 9
        }
      }
    }
  };
  Sender.prototype.processTelemetry = function (telemetryItem, itemCtx) {
    itemCtx = this._getTelCtx(itemCtx);
    try {
      // if master off switch is set, don't send any data
      if (this._senderConfig.disableTelemetry()) {
        // Do not send/save data
        return;
      }
      // validate input
      if (!telemetryItem) {
        itemCtx.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.CannotSendEmptyTelemetry, "Cannot send empty telemetry");
        return;
      }
      // validate event
      if (telemetryItem.baseData && !telemetryItem.baseType) {
        itemCtx.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.InvalidEvent, "Cannot send telemetry without baseData and baseType");
        return;
      }
      if (!telemetryItem.baseType) {
        // Default
        telemetryItem.baseType = "EventData";
      }
      // ensure a sender was constructed
      if (!this._sender) {
        itemCtx.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.SenderNotInitialized, "Sender was not initialized");
        return;
      }
      // check if this item should be sampled in, else add sampleRate tag
      if (!this._isSampledIn(telemetryItem)) {
        // Item is sampled out, do not send it
        itemCtx.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.TelemetrySampledAndNotSent, "Telemetry item was sampled out and not sent", {
          SampleRate: this._sample.sampleRate
        });
        return;
      } else {
        telemetryItem[SampleRate] = this._sample.sampleRate;
      }
      // construct an envelope that Application Insights endpoint can understand
      var aiEnvelope_1 = Sender.constructEnvelope(telemetryItem, this._senderConfig.instrumentationKey(), itemCtx.diagLog());
      if (!aiEnvelope_1) {
        itemCtx.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.CreateEnvelopeError, "Unable to create an AppInsights envelope");
        return;
      }
      var doNotSendItem_1 = false;
      // this is for running in legacy mode, where customer may already have a custom initializer present
      if (telemetryItem.tags && telemetryItem.tags[ProcessLegacy]) {
        CoreUtils.arrForEach(telemetryItem.tags[ProcessLegacy], function (callBack) {
          try {
            if (callBack && callBack(aiEnvelope_1) === false) {
              doNotSendItem_1 = true;
              itemCtx.diagLog().warnToConsole("Telemetry processor check returns false");
            }
          } catch (e) {
            // log error but dont stop executing rest of the telemetry initializers
            // doNotSendItem = true;
            itemCtx.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryInitializerFailed, "One of telemetry initializers failed, telemetry item will not be sent: " + Util.getExceptionName(e), {
              exception: Util.dump(e)
            }, true);
          }
        });
        delete telemetryItem.tags[ProcessLegacy];
      }
      if (doNotSendItem_1) {
        return; // do not send, no need to execute next plugin
      }
      // check if the incoming payload is too large, truncate if necessary
      var payload = this._serializer.serialize(aiEnvelope_1);
      // flush if we would exceed the max-size limit by adding this item
      var bufferPayload = this._buffer.getItems();
      var batch = this._buffer.batchPayloads(bufferPayload);
      if (batch && batch.length + payload.length > this._senderConfig.maxBatchSizeInBytes()) {
        this.triggerSend();
      }
      // enqueue the payload
      this._buffer.enqueue(payload);
      // ensure an invocation timeout is set
      this._setupTimer();
    } catch (e) {
      itemCtx.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.FailedAddingTelemetryToBuffer, "Failed adding telemetry to the sender's buffer, some telemetry will be lost: " + Util.getExceptionName(e), {
        exception: Util.dump(e)
      });
    }
    // hand off the telemetry item to the next plugin
    this.processNext(telemetryItem, itemCtx);
  };
  /**
   * xhr state changes
   */
  Sender.prototype._xhrReadyStateChange = function (xhr, payload, countOfItemsInPayload) {
    if (xhr.readyState === 4) {
      var response = null;
      if (!this._appId) {
        response = this._parseResponse(xhr.responseText || xhr.response);
        if (response && response.appId) {
          this._appId = response.appId;
        }
      }
      if ((xhr.status < 200 || xhr.status >= 300) && xhr.status !== 0) {
        if (!this._senderConfig.isRetryDisabled() && this._isRetriable(xhr.status)) {
          this._resendPayload(payload);
          this.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.TransmissionFailed, ". " + "Response code " + xhr.status + ". Will retry to send " + payload.length + " items.");
        } else {
          this._onError(payload, this._formatErrorMessageXhr(xhr));
        }
      } else if (Offline.isOffline()) {
        // Note: Don't check for staus == 0, since adblock gives this code
        if (!this._senderConfig.isRetryDisabled()) {
          var offlineBackOffMultiplier = 10; // arbritrary number
          this._resendPayload(payload, offlineBackOffMultiplier);
          this.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.TransmissionFailed, ". Offline - Response Code: " + xhr.status + ". Offline status: " + Offline.isOffline() + ". Will retry to send " + payload.length + " items.");
        }
      } else {
        if (xhr.status === 206) {
          if (!response) {
            response = this._parseResponse(xhr.responseText || xhr.response);
          }
          if (response && !this._senderConfig.isRetryDisabled()) {
            this._onPartialSuccess(payload, response);
          } else {
            this._onError(payload, this._formatErrorMessageXhr(xhr));
          }
        } else {
          this._consecutiveErrors = 0;
          this._onSuccess(payload, countOfItemsInPayload);
        }
      }
    }
  };
  /**
   * Immediately send buffered data
   * @param async {boolean} - Indicates if the events should be sent asynchronously
   * @param forcedSender {SenderFunction} - Indicates the forcedSender, undefined if not passed
   */
  Sender.prototype.triggerSend = function (async, forcedSender) {
    if (async === void 0) {
      async = true;
    }
    try {
      // Send data only if disableTelemetry is false
      if (!this._senderConfig.disableTelemetry()) {
        if (this._buffer.count() > 0) {
          var payload = this._buffer.getItems();
          // invoke send
          if (forcedSender) {
            forcedSender.call(this, payload, async);
          } else {
            this._sender(payload, async);
          }
        }
        // update lastSend time to enable throttling
        this._lastSend = +new Date();
      } else {
        this._buffer.clear();
      }
      clearTimeout(this._timeoutHandle);
      this._timeoutHandle = null;
      this._retryAt = null;
    } catch (e) {
      /* Ignore this error for IE under v10 */
      if (!Util.getIEVersion() || Util.getIEVersion() > 9) {
        this.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.TransmissionFailed, "Telemetry transmission failed, some telemetry will be lost: " + Util.getExceptionName(e), {
          exception: Util.dump(e)
        });
      }
    }
  };
  /**
   * error handler
   */
  Sender.prototype._onError = function (payload, message, event) {
    this.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.OnError, "Failed to send telemetry.", {
      message: message
    });
    this._buffer.clearSent(payload);
  };
  /**
   * partial success handler
   */
  Sender.prototype._onPartialSuccess = function (payload, results) {
    var failed = [];
    var retry = [];
    // Iterate through the reversed array of errors so that splicing doesn't have invalid indexes after the first item.
    var errors = results.errors.reverse();
    for (var _i = 0, errors_1 = errors; _i < errors_1.length; _i++) {
      var error = errors_1[_i];
      var extracted = payload.splice(error.index, 1)[0];
      if (this._isRetriable(error.statusCode)) {
        retry.push(extracted);
      } else {
        // All other errors, including: 402 (Monthly quota exceeded) and 439 (Too many requests and refresh cache).
        failed.push(extracted);
      }
    }
    if (payload.length > 0) {
      this._onSuccess(payload, results.itemsAccepted);
    }
    if (failed.length > 0) {
      this._onError(failed, this._formatErrorMessageXhr(null, ['partial success', results.itemsAccepted, 'of', results.itemsReceived].join(' ')));
    }
    if (retry.length > 0) {
      this._resendPayload(retry);
      this.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.TransmissionFailed, "Partial success. " + "Delivered: " + payload.length + ", Failed: " + failed.length + ". Will retry to send " + retry.length + " our of " + results.itemsReceived + " items");
    }
  };
  /**
   * success handler
   */
  Sender.prototype._onSuccess = function (payload, countOfItemsInPayload) {
    this._buffer.clearSent(payload);
  };
  /**
   * xdr state changes
   */
  Sender.prototype._xdrOnLoad = function (xdr, payload) {
    if (xdr && (xdr.responseText + "" === "200" || xdr.responseText === "")) {
      this._consecutiveErrors = 0;
      this._onSuccess(payload, 0);
    } else {
      var results = this._parseResponse(xdr.responseText);
      if (results && results.itemsReceived && results.itemsReceived > results.itemsAccepted && !this._senderConfig.isRetryDisabled()) {
        this._onPartialSuccess(payload, results);
      } else {
        this._onError(payload, this._formatErrorMessageXdr(xdr));
      }
    }
  };
  Sender.prototype._isSampledIn = function (envelope) {
    return this._sample.isSampledIn(envelope);
  };
  /**
   * Send Beacon API request
   * @param payload {string} - The data payload to be sent.
   * @param isAsync {boolean} - not used
   * Note: Beacon API does not support custom headers and we are not able to get
   * appId from the backend for the correct correlation.
   */
  Sender.prototype._beaconSender = function (payload, isAsync) {
    var url = this._senderConfig.endpointUrl();
    var batch = this._buffer.batchPayloads(payload);
    // Chrome only allows CORS-safelisted values for the sendBeacon data argument
    // see: https://bugs.chromium.org/p/chromium/issues/detail?id=720283
    var plainTextBatch = new Blob([batch], {
      type: 'text/plain;charset=UTF-8'
    });
    // The sendBeacon method returns true if the user agent is able to successfully queue the data for transfer. Otherwise it returns false.
    var queued = getNavigator().sendBeacon(url, plainTextBatch);
    if (queued) {
      this._buffer.markAsSent(payload);
      // no response from beaconSender, clear buffer
      this._onSuccess(payload, payload.length);
    } else {
      this._xhrSender(payload, true);
      this.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.TransmissionFailed, ". " + "Failed to send telemetry with Beacon API, retried with xhrSender.");
    }
  };
  /**
   * Send XMLHttpRequest
   * @param payload {string} - The data payload to be sent.
   * @param isAsync {boolean} - Indicates if the request should be sent asynchronously
   */
  Sender.prototype._xhrSender = function (payload, isAsync) {
    var _this = this;
    var xhr = new XMLHttpRequest();
    xhr[DisabledPropertyName] = true;
    xhr.open("POST", this._senderConfig.endpointUrl(), isAsync);
    xhr.setRequestHeader("Content-type", "application/json");
    // append Sdk-Context request header only in case of breeze endpoint
    if (Util.isInternalApplicationInsightsEndpoint(this._senderConfig.endpointUrl())) {
      xhr.setRequestHeader(RequestHeaders.sdkContextHeader, RequestHeaders.sdkContextHeaderAppIdRequest);
    }
    xhr.onreadystatechange = function () {
      return _this._xhrReadyStateChange(xhr, payload, payload.length);
    };
    xhr.onerror = function (event) {
      return _this._onError(payload, _this._formatErrorMessageXhr(xhr), event);
    };
    // compose an array of payloads
    var batch = this._buffer.batchPayloads(payload);
    xhr.send(batch);
    this._buffer.markAsSent(payload);
  };
  /**
   * Parses the response from the backend.
   * @param response - XMLHttpRequest or XDomainRequest response
   */
  Sender.prototype._parseResponse = function (response) {
    try {
      if (response && response !== "") {
        var result = getJSON().parse(response);
        if (result && result.itemsReceived && result.itemsReceived >= result.itemsAccepted && result.itemsReceived - result.itemsAccepted === result.errors.length) {
          return result;
        }
      }
    } catch (e) {
      this.diagLog().throwInternal(LoggingSeverity.CRITICAL, _InternalMessageId.InvalidBackendResponse, "Cannot parse the response. " + Util.getExceptionName(e), {
        response: response
      });
    }
    return null;
  };
  /**
   * Resend payload. Adds payload back to the send buffer and setup a send timer (with exponential backoff).
   * @param payload
   */
  Sender.prototype._resendPayload = function (payload, linearFactor) {
    if (linearFactor === void 0) {
      linearFactor = 1;
    }
    if (!payload || payload.length === 0) {
      return;
    }
    this._buffer.clearSent(payload);
    this._consecutiveErrors++;
    for (var _i = 0, payload_1 = payload; _i < payload_1.length; _i++) {
      var item = payload_1[_i];
      this._buffer.enqueue(item);
    }
    // setup timer
    this._setRetryTime(linearFactor);
    this._setupTimer();
  };
  /**
   * Calculates the time to wait before retrying in case of an error based on
   * http://en.wikipedia.org/wiki/Exponential_backoff
   */
  Sender.prototype._setRetryTime = function (linearFactor) {
    var SlotDelayInSeconds = 10;
    var delayInSeconds;
    if (this._consecutiveErrors <= 1) {
      delayInSeconds = SlotDelayInSeconds;
    } else {
      var backOffSlot = (Math.pow(2, this._consecutiveErrors) - 1) / 2;
      // tslint:disable-next-line:insecure-random
      var backOffDelay = Math.floor(Math.random() * backOffSlot * SlotDelayInSeconds) + 1;
      backOffDelay = linearFactor * backOffDelay;
      delayInSeconds = Math.max(Math.min(backOffDelay, 3600), SlotDelayInSeconds);
    }
    // TODO: Log the backoff time like the C# version does.
    var retryAfterTimeSpan = Date.now() + delayInSeconds * 1000;
    // TODO: Log the retry at time like the C# version does.
    this._retryAt = retryAfterTimeSpan;
  };
  /**
   * Sets up the timer which triggers actually sending the data.
   */
  Sender.prototype._setupTimer = function () {
    var _this = this;
    if (!this._timeoutHandle) {
      var retryInterval = this._retryAt ? Math.max(0, this._retryAt - Date.now()) : 0;
      var timerValue = Math.max(this._senderConfig.maxBatchInterval(), retryInterval);
      this._timeoutHandle = setTimeout(function () {
        _this.triggerSend();
      }, timerValue);
    }
  };
  /**
   * Checks if the SDK should resend the payload after receiving this status code from the backend.
   * @param statusCode
   */
  Sender.prototype._isRetriable = function (statusCode) {
    return statusCode === 408 // Timeout
    || statusCode === 429 // Too many requests.
    || statusCode === 500 // Internal server error.
    || statusCode === 503; // Service unavailable.
  };
  Sender.prototype._formatErrorMessageXhr = function (xhr, message) {
    if (xhr) {
      return "XMLHttpRequest,Status:" + xhr.status + ",Response:" + xhr.responseText || xhr.response || "";
    }
    return message;
  };
  /**
   * Send XDomainRequest
   * @param payload {string} - The data payload to be sent.
   * @param isAsync {boolean} - Indicates if the request should be sent asynchronously
   *
   * Note: XDomainRequest does not support sync requests. This 'isAsync' parameter is added
   * to maintain consistency with the xhrSender's contract
   * Note: XDomainRequest does not support custom headers and we are not able to get
   * appId from the backend for the correct correlation.
   */
  Sender.prototype._xdrSender = function (payload, isAsync) {
    var _this = this;
    var _window = getWindow();
    var xdr = new XDomainRequest();
    xdr.onload = function () {
      return _this._xdrOnLoad(xdr, payload);
    };
    xdr.onerror = function (event) {
      return _this._onError(payload, _this._formatErrorMessageXdr(xdr), event);
    };
    // XDomainRequest requires the same protocol as the hosting page.
    // If the protocol doesn't match, we can't send the telemetry :(.
    var hostingProtocol = _window && _window.location && _window.location.protocol || "";
    if (this._senderConfig.endpointUrl().lastIndexOf(hostingProtocol, 0) !== 0) {
      this.diagLog().throwInternal(LoggingSeverity.WARNING, _InternalMessageId.TransmissionFailed, ". " + "Cannot send XDomain request. The endpoint URL protocol doesn't match the hosting page protocol.");
      this._buffer.clear();
      return;
    }
    var endpointUrl = this._senderConfig.endpointUrl().replace(/^(https?:)/, "");
    xdr.open('POST', endpointUrl);
    // compose an array of payloads
    var batch = this._buffer.batchPayloads(payload);
    xdr.send(batch);
    this._buffer.markAsSent(payload);
  };
  Sender.prototype._formatErrorMessageXdr = function (xdr, message) {
    if (xdr) {
      return "XDomainRequest,Response:" + xdr.responseText || "";
    }
    return message;
  };
  return Sender;
}(BaseTelemetryPlugin);
export { Sender };
