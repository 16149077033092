import { CommonModule } from '@angular/common';
import { Component, Input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DigitalAccountApplicationService } from '../../../../services/digital-account-application.service';
import { EpiService } from '../../../../services/epi/epi.service';
import { MarketService } from '../../../../services/market.service';
import { SupportedMarket } from '../../../../types/market.types';
import { AppData } from '../../../../types/translations.types';
import { DigitalAccountApplicationForm, InvoiceOptionType } from '../../types/digital-account-application.types';

@Component({
	selector: 'cramo-summary-form',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule],
	templateUrl: './summary-form.component.html',
	styleUrls: ['./summary-form.component.scss'],
})
export class SummaryFormComponent {
	@Input() public form: FormGroup<DigitalAccountApplicationForm>;

	public currentMarket: SupportedMarket;
	public appData: Signal<AppData>;

	constructor(
		private epiService: EpiService,
		private digitalAccountApplicationService: DigitalAccountApplicationService,
		private marketService: MarketService,
	) {
		this.currentMarket = this.marketService.currentMarket;
		this.appData = toSignal(this.epiService.appData$);
	}

	public getInvoicePreferenceText(invoiceOptionType: InvoiceOptionType): string {
		if (invoiceOptionType === null) return;

		switch (invoiceOptionType) {
			case 'Email':
				return this.appData().digitalAccount.chosenInvoiceOptionIsEmail;
			case 'EInvoice':
				return this.appData().digitalAccount.chosenInvoiceOptionIsEInvoice;
			case 'Paper':
				return this.appData().digitalAccount.chosenInvoiceOptionIsPost;
		}
	}

	public getTranslatedMainDirection(mainDirection: string): string {
		const mainDirectionLabelKey =
			this.digitalAccountApplicationService.getMainDirectionTranslatedLabelKey(mainDirection);
		return this.appData().accountApplication[mainDirectionLabelKey];
	}

	public getAddressString() {
		if (this.form.get('type').value === 'Individual') {
			return this.digitalAccountApplicationService.getAddressAsString(this.form.get('individual.address').value);
		}
		return this.digitalAccountApplicationService.getAddressAsString(this.form.get('company.address').value);
	}
}
