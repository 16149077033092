import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, signal, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
	AbstractControl,
	FormArray,
	FormGroup,
	ReactiveFormsModule,
	UntypedFormBuilder,
	Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { map, of, switchMap } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { ButtonComponent } from '../../../../components/button/button.component';
import { IconComponent } from '../../../../components/icon/icon.component';
import { InputComponent } from '../../../../components/input/input.component';
import { MessageBoxComponent } from '../../../../components/message-box/message-box.component';
import { DigitalAccountApplicationService } from '../../../../services/digital-account-application.service';
import { EpiService } from '../../../../services/epi/epi.service';
import { AppData } from '../../../../types/translations.types';
import { MailRegExp, MaxEmailLength } from '../../../../utils/constants';
import {
	DigitalAccountApplicationForm,
	Signatory,
	SignatureCombinations,
} from '../../types/digital-account-application.types';

@Component({
	selector: 'cramo-signatory-form',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatRadioModule,
		MatCheckboxModule,
		IconComponent,
		InputComponent,
		MatDividerModule,
		ButtonComponent,
		MessageBoxComponent,
	],
	templateUrl: './signatory-form.component.html',
	styleUrls: ['./signatory-form.component.scss'],
})
export class SignatoryFormComponent implements OnInit {
	@Input() public form: FormGroup<DigitalAccountApplicationForm>;
	public signatoryCombinations: Signal<SignatureCombinations> = signal([]);
	public showAll = false;
	public appData: Signal<AppData>;
	public emailMaxLengthErrorMessage = `Email must be less than ${MaxEmailLength} characters`;

	public get signatories(): FormArray {
		const companyGroup = this.form.get('company') as FormGroup;
		return companyGroup.get('signatories') as FormArray;
	}

	constructor(
		private digitalAccountApplicationService: DigitalAccountApplicationService,
		private epiService: EpiService,
		private formBuilder: UntypedFormBuilder,
	) {
		this.appData = toSignal(this.epiService.appData$);
	}

	ngOnInit(): void {
		this.form
			.get('company.id')
			.valueChanges.pipe(
				startWith(this.form.get('company.id').value),
				filter((companyId) => Boolean(companyId)),
				switchMap((companyId) =>
					this.form.get('company.id').valid
						? this.digitalAccountApplicationService
								.companyLookup(companyId)
								.pipe(map((options) => options?.SignatureCombinations))
						: of(null),
				),
			)
			.subscribe((signatoryCombinations) => {
				// reset selected signatories due to race conditions caused by reactive forms and dynamically created controls
				this.form.get('company.selectedSignatoryGroup').setValue(null);
				this.signatoryCombinations = signal(signatoryCombinations);
			});
	}

	public onSignatoriesSelect(selectedGroup: Signatory[]): void {
		// clear existing signatories
		while (this.signatories.length) {
			this.signatories.removeAt(0);
		}

		// place new signatory controls
		selectedGroup.forEach((signatory) => {
			this.addSignatory({ name: signatory.Name, role: signatory.Role, email: '', ssn: signatory.PersonalNumber });
		});
	}

	public getSignatoryEmailControl(index: number): AbstractControl | null {
		return this.signatories.at(index)?.get('email') ?? null;
	}

	private addSignatory({ name, role, email, ssn }: { name: string; role: string; email: string; ssn: string }): void {
		const signatory = this.formBuilder.group({
			name: [{ value: name, disabled: false }],
			role: [{ value: role, disabled: false }],
			email: [
				{ value: email, disabled: false },
				{
					validators: [Validators.required, Validators.maxLength(50), Validators.pattern(MailRegExp)],
				},
			],
			ssn: ssn,
		});
		this.signatories.push(signatory);
	}
}
