import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { jwtDecode } from 'jwt-decode';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { GtmService } from '../services/gtm.service';
import { TokenService } from '../services/token.service';
import { B2CClaims, B2CValidationError } from '../types/auth.types';
import { isB2CError } from '../utils/b2c';
import { getB2CClientId, getB2CTenantDomain, getB2CTenantId } from '../utils/b2c/environment';
import { HttpCancelService } from './../services/http-cancel.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private gtmService: GtmService,
		private httpCancelService: HttpCancelService,
		private snackBar: MatSnackBar,
		private tokenService: TokenService,
		private authService: AuthService,
	) {}

	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!this.shouldApplyAuthorizationHeader(req.url)) {
			return next.handle(req);
		}

		return this.applyAuthorizationHeader(req).pipe(
			switchMap((requestWithToken) => next.handle(requestWithToken)),
			takeUntil(this.httpCancelService.onCancelPendingRequests()),
			catchError((err: unknown) => {
				this.sendGAErrorEvent(err as HttpErrorResponse);

				if (err instanceof HttpErrorResponse && err.status === 401) {
					return this.handleUnauthorizedError(err);
				}

				return throwError(() => err);
			}),
		);
	}

	private shouldApplyAuthorizationHeader(url: string): boolean {
		return url.startsWith('/webapi');
	}

	/**
	 * Prepares the provided request by ensuring it has a valid access token, if necessary.
	 * - Decodes the current access token to determine its validity.
	 * - If the token has expired, triggers a refresh and recursively attempts to re-apply the new token.
	 * - Handles potential token-related errors by clearing user data and redirecting to the home page.
	 */
	private applyAuthorizationHeader(req: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
		const { accessToken } = this.tokenService;

		if (!accessToken) {
			return of(req);
		}

		const accessTokenDecoded = jwtDecode<B2CClaims>(accessToken);

		return validateTokenClaims(accessTokenDecoded).pipe(
			map(() =>
				req.clone({
					headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
				}),
			),
			catchError((error: B2CValidationError) => {
				if (error === B2CValidationError.TokenHasExpired) {
					return this.tokenService.requestAccessToken().pipe(
						switchMap(() => this.applyAuthorizationHeader(req)),
						catchError((error) => {
							if (isB2CError(error.error)) {
								this.authService.handleAuthError(error.error);
								return of(null);
							}
							return throwError(() => error);
						}),
					);
				}

				if (this.isValidationError(error)) {
					this.snackBar.open('Unknown authentication error, login again', 'close');
					return of(req);
				}

				return throwError(() => req);
			}),
		);
	}

	private handleUnauthorizedError = (error: HttpErrorResponse) => {
		const errorObj = error as { error?: unknown };

		if (isB2CError(errorObj?.error)) {
			this.authService.handleAuthError(errorObj.error);
			return of(null);
		}

		this.authService.invalidateSession();
		this.snackBar.open('Something went wrong, you have been logged out', 'close', { duration: 3000 });

		return throwError(() => error);
	};

	private sendGAErrorEvent(error: HttpErrorResponse) {
		this.gtmService.sendErrorEvent('apiError', error.url, `${error.status}-${error.message}-${error.name}`);
	}

	private isValidationError(error: unknown): boolean {
		return [B2CValidationError.InvalidAudience, B2CValidationError.InvalidIssuer].some((err) => err === error);
	}
}

// TODO -> Log to application insight.
function validateTokenClaims(token: B2CClaims): Observable<void> {
	const tenantDomain = getB2CTenantDomain();
	const tenantId = getB2CTenantId();
	const expectedIssuer = `https://${tenantDomain}/${tenantId}/v2.0/`;
	const nowEpochSeconds = Math.ceil(Date.now() / 1000);

	if (token.azp !== getB2CClientId()) {
		return throwError(() => B2CValidationError.InvalidAudience);
	}

	const expirationEpochSeconds = token.exp;
	if (expirationEpochSeconds < nowEpochSeconds) {
		return throwError(() => B2CValidationError.TokenHasExpired);
	}

	if (token.iss !== expectedIssuer) {
		return throwError(() => B2CValidationError.InvalidIssuer);
	}

	return of(null);
}
