import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function noWhitespaceValidator(): ValidatorFn {
	return (control: AbstractControl<string>): ValidationErrors | null => {
		const isWhitespace = (control.value || '').trim().length === 0;

		const isValid = !isWhitespace;

		return isValid ? null : { whitespace: true };
	};
}
