import { Injectable } from '@angular/core';
import { MatStepperIntl } from '@angular/material/stepper';
import { take } from 'rxjs';
import { EpiService } from './services/epi/epi.service';

@Injectable({
	providedIn: 'root',
})
export class MaterialDesignService {
	constructor(
		private matStepperIntl: MatStepperIntl,
		private epiService: EpiService,
	) {}

	public init() {
		this.epiService.appData$?.pipe(take(1)).subscribe((appData) => {
			this.matStepperIntl.optionalLabel = appData.global.optionalLabel;
		});
	}
}
