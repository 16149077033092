/**
 * PropertiesPlugin.ts
 * @copyright Microsoft 2018
 */
import * as tslib_1 from "tslib";
import { BaseTelemetryPlugin, CoreUtils, _InternalLogMessage, LoggingSeverity, _InternalMessageId, getNavigator } from '@microsoft/applicationinsights-core-js';
import { TelemetryContext } from './TelemetryContext';
import { PageView, BreezeChannelIdentifier, PropertiesPluginIdentifier, Extensions, Util } from '@microsoft/applicationinsights-common';
var PropertiesPlugin = /** @class */function (_super) {
  tslib_1.__extends(PropertiesPlugin, _super);
  function PropertiesPlugin() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.priority = 110;
    _this.identifier = PropertiesPluginIdentifier;
    return _this;
  }
  PropertiesPlugin.getDefaultConfig = function () {
    var defaultConfig = {
      instrumentationKey: function () {
        return undefined;
      },
      accountId: function () {
        return null;
      },
      sessionRenewalMs: function () {
        return 30 * 60 * 1000;
      },
      samplingPercentage: function () {
        return 100;
      },
      sessionExpirationMs: function () {
        return 24 * 60 * 60 * 1000;
      },
      cookieDomain: function () {
        return null;
      },
      sdkExtension: function () {
        return null;
      },
      isBrowserLinkTrackingEnabled: function () {
        return false;
      },
      appId: function () {
        return null;
      },
      namePrefix: function () {
        return undefined;
      }
    };
    return defaultConfig;
  };
  PropertiesPlugin.prototype.initialize = function (config, core, extensions, pluginChain) {
    var _this = this;
    _super.prototype.initialize.call(this, config, core, extensions, pluginChain);
    var ctx = this._getTelCtx();
    var identifier = this.identifier;
    var defaultConfig = PropertiesPlugin.getDefaultConfig();
    this._extensionConfig = this._extensionConfig || PropertiesPlugin.getDefaultConfig();
    var _loop_1 = function (field) {
      this_1._extensionConfig[field] = function () {
        return ctx.getConfig(identifier, field, defaultConfig[field]());
      };
    };
    var this_1 = this;
    for (var field in defaultConfig) {
      _loop_1(field);
    }
    this.context = new TelemetryContext(core.logger, this._extensionConfig);
    this._breezeChannel = Util.getExtension(extensions, BreezeChannelIdentifier);
    this.context.appId = function () {
      return _this._breezeChannel ? _this._breezeChannel["_appId"] : null;
    };
  };
  /**
   * Add Part A fields to the event
   * @param event The event that needs to be processed
   */
  PropertiesPlugin.prototype.processTelemetry = function (event, itemCtx) {
    if (CoreUtils.isNullOrUndefined(event)) {
      // TODO(barustum): throw an internal event once we have support for internal logging
    } else {
      itemCtx = this._getTelCtx(itemCtx);
      // If the envelope is PageView, reset the internal message count so that we can send internal telemetry for the new page.
      if (event.name === PageView.envelopeType) {
        itemCtx.diagLog().resetInternalMessageCount();
      }
      if (this.context.session) {
        // If customer did not provide custom session id update the session manager
        if (typeof this.context.session.id !== "string") {
          this.context.sessionManager.update();
        }
      }
      this._processTelemetryInternal(event, itemCtx);
      if (this.context && this.context.user && this.context.user.isNewUser) {
        this.context.user.isNewUser = false;
        var message = new _InternalLogMessage(_InternalMessageId.SendBrowserInfoOnUserInit, (getNavigator() || {}).userAgent || "");
        itemCtx.diagLog().logInternalMessage(LoggingSeverity.CRITICAL, message);
      }
      this.processNext(event, itemCtx);
    }
  };
  PropertiesPlugin.prototype._processTelemetryInternal = function (event, itemCtx) {
    // set part A  fields
    if (!event.tags) {
      event.tags = [];
    }
    if (!event.ext) {
      event.ext = {};
    }
    var ext = event.ext;
    ext[Extensions.DeviceExt] = ext[Extensions.DeviceExt] || {};
    ext[Extensions.WebExt] = ext[Extensions.WebExt] || {};
    ext[Extensions.UserExt] = ext[Extensions.UserExt] || {};
    ext[Extensions.OSExt] = ext[Extensions.OSExt] || {};
    ext[Extensions.AppExt] = ext[Extensions.AppExt] || {};
    ext[Extensions.TraceExt] = ext[Extensions.TraceExt] || {};
    var context = this.context;
    context.applySessionContext(event, itemCtx);
    context.applyApplicationContext(event, itemCtx);
    context.applyDeviceContext(event, itemCtx);
    context.applyOperationContext(event, itemCtx);
    context.applyUserContext(event, itemCtx);
    context.applyOperatingSystemContxt(event, itemCtx);
    context.applyWebContext(event, itemCtx);
    context.applyLocationContext(event, itemCtx); // legacy tags
    context.applyInternalContext(event, itemCtx); // legacy tags
    context.cleanUp(event, itemCtx);
  };
  return PropertiesPlugin;
}(BaseTelemetryPlugin);
export default PropertiesPlugin;
