export const isValidNorwegianCompanyId = (id: string): boolean => {
	if (!/^\d{9}$/.test(id)) {
		// Must be exactly 9 digits
		return false;
	}

	const weights = [3, 2, 7, 6, 5, 4, 3, 2];
	const digits = id.split('').map(Number);
	const sum = weights.reduce((acc, weight, index) => acc + weight * digits[index], 0);

	const calculatedCheckDigit = 11 - (sum % 11);
	const checkDigit = calculatedCheckDigit === 11 ? 0 : calculatedCheckDigit;

	// The check digit is valid if it's not 10 and matches the last digit of the ID
	return checkDigit !== 10 && digits[8] === checkDigit;
};

export const isValidSwedishCompanyId = (id: string): boolean => {
	// Remove hyphen and check if the remaining string consists of exactly 10 digits
	const cleanedId = id.replace(/-/g, '');
	if (!/^\d{10}$/.test(cleanedId)) {
		return false;
	}

	let sum = 0;
	for (let i = 0; i < cleanedId.length; i++) {
		let digit = parseInt(cleanedId.charAt(i), 10);
		if (i % 2 === 0) {
			// Every other digit starting from the first
			digit *= 2;
			if (digit > 9) {
				digit -= 9; // Equivalent to summing the digits of the product (e.g., 16 -> 1+6 -> 7)
			}
		}
		sum += digit;
	}

	// Valid if sum is a multiple of 10
	return sum % 10 === 0;
};

export const isValidFinnishCompanyId = (id: string): boolean => {
	// The Business ID should be exactly 8 digits long after sanitizing
	const sanitizedId = id.replace(/[^0-9]/g, '');
	if (sanitizedId.length !== 8) {
		return false;
	}

	// Split the ID into the actual ID part and the control mark
	const idNumbers = sanitizedId.substring(0, 7);
	const controlMark = parseInt(sanitizedId.substring(7), 10);

	// Calculate the checksum
	let sum = 0;
	const multipliers = [7, 9, 10, 5, 8, 4, 2];
	for (let i = 0; i < idNumbers.length; i++) {
		sum += parseInt(idNumbers.charAt(i), 10) * multipliers[i];
	}

	// Calculate the remainder (the control mark)
	const remainder = sum % 11;
	let calculatedControlMark: number;
	if (remainder === 0) {
		calculatedControlMark = 0;
	} else if (remainder === 1) {
		// Number 1 is not used as a control mark
		return false;
	} else {
		calculatedControlMark = 11 - remainder;
	}

	// Compare the control mark with the last digit of the Business ID
	return controlMark === calculatedControlMark;
};
