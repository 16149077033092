import { CommonModule } from '@angular/common';
import { Component, Input, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputTelComponent } from '../../../../components/input-tel/input-tel.component';
import { InputComponent } from '../../../../components/input/input.component';
import { EpiService } from '../../../../services/epi/epi.service';
import { AppData } from '../../../../types/translations.types';
import { MaxEmailLength } from '../../../../utils/constants';
import { DigitalAccountApplicationForm } from '../../types/digital-account-application.types';

@Component({
	selector: 'cramo-contact-form',
	standalone: true,
	imports: [CommonModule, InputComponent, ReactiveFormsModule, InputTelComponent],
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
	@Input() public form: FormGroup<DigitalAccountApplicationForm>;

	public emailMaxLengthErrorMessage = `Email must be less than ${MaxEmailLength} characters`;
	public appData: Signal<AppData>;
	constructor(private epiService: EpiService) {
		this.appData = toSignal(this.epiService.appData$);
	}
}
